<template>
  <div class="right-content-box">
    <!-- 添加行政人员 -->
    <div class="add-box">
      <el-button @click="manageAdd()" type="primary" class="btn-add"
        >添加</el-button
      >
    </div>
    <!-- 表格 -->
    <el-table
      :data="state.paging.data"
      fixed
      style="width: 100%"
      class="manage-box"
      destroy-on-close
    >
      <!-- <el-table-column label="序号" prop="id" width="120"> </el-table-column> -->
      <el-table-column label="序号" type="index" width="90" />
      <el-table-column label="真实姓名" prop="name"> </el-table-column>
      <el-table-column label="电话号码" prop="phone"> </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            type="primary"
            size="mini"
            @click="manageEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除数据吗？"
            @confirm="manageDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="state.paging.total"
      class="paging"
    >
    </el-pagination>

    <!-- 添加用户、编辑抽屉 -->
    <el-drawer :title="title" v-model="drawer" :before-close="handleClose">
      <div class="form-box">
        <el-form
          :model="state.user"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
          :label-position="labelPosition"
        >
          <el-form-item label="真实姓名" prop="name">
            <el-input
              v-model="state.user.name"
              placeholder="请输入真实姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话号码">
            <el-input
              v-model="state.user.phone"
              placeholder="电话号码"
              type="number"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          class="btn-edit"
          @click="submitForm(btnTitle)"
        >
          {{ btnTitle }}
        </el-button>
      </div>
    </el-drawer>
    <!-- <div v-if="isMask" class="mask"></div> -->
  </div>
</template>

<script lang="ts">
import {
  getListsStaffRequest,
  postAddEditStaffRequest,
  postDelStaffRequest,
} from "../../apis/requests/api";
import { IPaging, IStaffUser } from "../../typing";
import { reactive } from "@vue/reactivity";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";
import { ElMessage } from "element-plus";
import { checkPhoneNumber, cloneDeep, existValidation } from "@/share/util";

@Options({
  mixins: [titleMixin],
})
export default class Staff extends Vue {
  isMask = false;

  state = reactive<{
    paging: IPaging<IStaffUser>;
    user: IStaffUser;
    userNameList: { name: string; id: number }[];
  }>({
    paging: { data: [] },
    user: {},
    userNameList: [],
  });

  created() {
    this.getListsStaff();
  }

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  rules = {
    username: [
      { required: true, message: "登录账户名", trigger: "blur" },
      { min: 3, max: 10, message: "长度在 3 到 5 个字符", trigger: "blur" },
    ],
  };

  // 新增行政人员
  manageAdd(): void {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加行政人员";
    this.btnTitle = "添加";
    this.state.user = {};
  }

  // 编辑行政人员
  manageEdit(index: number, user: IStaffUser): void {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑行政人员";
    this.btnTitle = "确认修改";
    this.state.user = cloneDeep(user);
  }

  handleClose(done: any): void {
    this.isMask = false;
    done();
  }

  // 删除数据
  manageDelete(index: number, user: IStaffUser): void {
    postDelStaffRequest(user.id as number).then(() => {
      ElMessage.success({
        message: "删除成功！",
        type: "success",
      });
      this.drawer = false;
      this.getListsStaff();
    });
  }

  submitForm(btnTitle: string): void {
    this.isMask = false;
    if (!existValidation([this.state.user.name, this.state.user.phone])) {
      ElMessage.error("字段未填写完整");
      return;
    }

    if (!checkPhoneNumber(this.state.user.phone)) {
      ElMessage.error("电话号码格式错误");
      return;
    }

    if (btnTitle === "添加") {
      postAddEditStaffRequest(this.state.user).then((res) => {
        if (res.code === 200) {
          ElMessage.success({
            message: "创建成功！",
            type: "success",
          });
          this.drawer = false;
          this.state.user = {};
          this.state.paging.current_page = 1
          this.getListsStaff();
        } else {
          ElMessage.error({
            message: res.msg,
            type: "error",
          });
        }
      });
    } else {
      postAddEditStaffRequest(this.state.user).then((res) => {
        if (res.code === 200) {
          ElMessage.success({
            message: "修改成功！",
            type: "success",
          });
          this.drawer = false;
          this.state.user = {};
          this.getListsStaff();
        } else {
          ElMessage.error({
            message: res.msg,
            type: "error",
          });
        }
      });
    }
  }

  getListsStaff(): void {
    getListsStaffRequest(
      this.state.paging.per_page,
      this.state.paging.current_page
    ).then((result) => {
      this.state.paging = result.data;
    });

    // getListsStaffRequest(999, 1).then((result) => {
    //   this.state.userNameList = result.data.data.map((item) => {
    //     return { name: item.name, id: item.id };
    //   }) as { name: string; id: number }[];
    // });
  }

  handleSizeChange(val: number): void {
    this.state.paging.per_page = val;
    this.getListsStaff();
  }

  handleCurrentChange(val: number): void {
    this.state.paging.current_page = val;
    this.getListsStaff();
  }
}
</script>

<style scoped>
::v-deep .el-table th:first-of-type,
::v-deep .el-table th:last-of-type,
::v-deep .el-table td:first-of-type,
::v-deep .el-table td:last-of-type {
  text-align: center !important;
}
.add-box {
  text-align: right;
}
.btn-add {
  width: calc(100 / 14 * 1rem);
  min-height: calc(28 / 14 * 1rem);
  height: calc(36 / 14 * 1rem);
}
.btn-edit {
  width: 100%;
  margin-top: calc(40 / 14 * 1rem) !important;
}

::v-deep .el-checkbox {
  width: 32% !important;
}

::v-deep .el-checkbox__label {
  font-size: 12px !important;
}
</style>