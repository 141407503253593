<template>
  <div class="right-content-box">
    <div class="add-box">
      <el-button @click="postAdd()" type="primary" class="btn-add">
        添加
      </el-button>
    </div>

    <!-- 表格 -->
    <el-table
      :data="state.paging.data"
      fixed
      style="width: 100%"
      class="manage-box"
    >
      <el-table-column
        v-for="item in manage"
        :key="item.manage"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
      ></el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            type="primary"
            size="mini"
            @click="postEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除数据吗？"
            @confirm="postDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="state.paging.total"
      class="paging"
    >
    </el-pagination>

    <!-- 添加、编辑抽屉 -->
    <el-drawer
      :title="title"
      v-model="drawer"
      :modal="false"
      :before-close="handleClose"
    >
      <div class="form-box">
        <el-form
          ref="form"
          :model="state.category"
          label-width="80px"
          :label-position="labelPosition"
        >
          <el-form-item label="分类名称">
            <el-input
              v-model="state.category.cat"
              placeholder="请输入分类名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input
              type="number"
              v-model="state.category.sort"
              placeholder="数值越大越靠后"
            ></el-input>
          </el-form-item>
        </el-form>

        <el-button
          type="primary"
          class="btn-edit"
          @click="submitForm(btnTitle)"
        >
          {{ btnTitle }}</el-button
        >
      </div>
    </el-drawer>
    <div v-if="isMask" class="mask"></div>
  </div>
</template>

<script lang="ts">
import { titleMixin } from "../../mixins/titleMixin";
import { Options, Vue } from "vue-class-component";
import {
  postAddCategoryRequest,
  postDelCategoryRequest,
  postEditCategoryRequest,
  postListsCategoryRequest,
} from "../../apis/requests/api";
import { reactive } from "@vue/reactivity";
import { ICategory, IPaging } from "../../typing";
import { cloneDeep, existValidation } from "../../share/util";
import { ElMessage } from "element-plus";

@Options({
  mixins: [titleMixin],
})
export default class JobClassification extends Vue {
  isMask = false;

  state = reactive<{
    paging: IPaging<ICategory>;
    category: ICategory;
    nameList: { cat: string; id: number }[];
  }>({
    paging: { data: [] },
    category: {},
    nameList: [],
  });

  // 表头
  manage = [
    {
      prop: "id",
      label: "序号",
      width: "200",
    },
    {
      prop: "cat",
      label: "分类名称",
    },
    {
      prop: "sort",
      label: "排序",
    },
  ];

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  created() {
    this.getListsCategory();
  }

  getListsCategory() {
    postListsCategoryRequest(
      this.state.paging.per_page,
      this.state.paging.current_page
    ).then((result) => {
      this.state.paging = result.data;
    });

    postListsCategoryRequest(999, 1).then((result) => {
      this.state.nameList = result.data.data.map((item) => {
        return { cat: item.cat, id: item.id };
      }) as { cat: string; id: number }[];
    });
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsCategory();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsCategory();
  }

  postAdd() {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加岗位分类";
    this.btnTitle = "添加";

    this.state.category = {};
  }

  postEdit(index: number, category: ICategory) {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑岗位分类";
    this.btnTitle = "确认修改";

    this.state.category = cloneDeep(category);
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  submitForm(btnTitle: string) {
    this.isMask = false;
    if (!existValidation([this.state.category.cat, this.state.category.sort])) {
      ElMessage.error("字段未填写完整");
      return;
    }

    if ((this.state.category.sort as number) <= 0) {
      ElMessage.error("请输入大于0的正整数");
      return;
    }

    for (const item of this.state.nameList) {
      if (
        item.id != this.state.category.id &&
        item.cat === this.state.category.cat
      ) {
        ElMessage.error("分类名称已存在");
        return;
      }
    }

    if (btnTitle === "添加") {
      postAddCategoryRequest(this.state.category).then((_) => {
        ElMessage.success({
          message: "创建成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.category = {};
        this.getListsCategory();
      });
    } else {
      postEditCategoryRequest(this.state.category).then((_) => {
        ElMessage.success({
          message: "修改成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.category = {};
        this.getListsCategory();
      });
    }
  }

  // 删除数据
  postDelete(index: number, category: ICategory) {
    postDelCategoryRequest(category.id as number).then((result) => {
      if (result.code === 403) {
        ElMessage.error(result.msg);
      } else {
        ElMessage.success({
          message: "删除成功！",
          type: "success",
        });
      }
      this.drawer = false;
      this.getListsCategory();
    });
  }
}
</script>

<style scoped>
::v-deep .el-table th:first-of-type,
::v-deep .el-table th:last-of-type,
::v-deep .el-table td:first-of-type,
::v-deep .el-table td:last-of-type {
  text-align: center !important;
}
.add-box {
  text-align: right;
}
.btn-add {
  width: calc(100 / 14 * 1rem);
  min-height: calc(34 / 14 * 1rem);
}
.btn-edit {
  width: 100%;
  margin-top: 10px;
}
.jurisdiction-title {
  font-size: 14px;
  margin-bottom: calc(20 / 14 * 1rem);
  margin-top: calc(10 / 14 * 1rem);
}
::v-deep().el-select {
  width: 100%;
}
::v-deep().input-textarea textarea {
  height: 80px !important;
}
.question-box {
  border-radius: 6px;
  padding: 0 calc(20 / 14 * 1rem) calc(20 / 14 * 1rem);
  background: #f8f8f8;
}

::v-deep() .el-upload {
  width: 100%;
}

::v-deep() .el-upload-dragger {
  width: 100%;
}

.mask {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
}

::v-deep() input[type="number"]::-webkit-inner-spin-button,
::v-deep()input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>