
import { titleMixin } from "../../mixins/titleMixin";
import { Options, Vue } from "vue-class-component";
import { reactive } from "@vue/reactivity";
import { IPaging, ISpecial, ISpecialContent, IexpandQuestion } from "@/typing";
import {
  getListsExpandRequest,
  postAddExpandRequest,
  postDeleteExpandRequest,
} from "@/apis/requests/api";
import { ElMessage } from "element-plus";
import { cloneDeep, existValidation } from "@/share/util";
import { environment } from "@/environments/environments";

interface QueryParams {
  cat?: string;
  question?: string;
}

@Options({
  mixins: [titleMixin],
})
export default class ExpandQuestionBank extends Vue {
  isMask = false;
  apiUrl = environment.apiUrl;

  letterSequences = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

  state = reactive<{
    queryParams: QueryParams;
    paging: IPaging<any>;
    special: ISpecial;
    expand: IexpandQuestion;
    questionList: { question: string; id: number }[];
  }>({
    paging: { data: [] },
    special: {
      content: [
        { optionName: "A", optionContent: "", analysis: "", score: undefined },
      ],
      fileList: [],
    },
    expand: {
      expandQuestion: "",
      expandAnswers: "",
    },
    questionList: [],
    queryParams: {},
  });

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  postAdd() {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加题库";
    this.btnTitle = "添加";
    this.state.expand.id = null;
    this.state.expand.expandQuestion = "";
    this.state.expand.expandAnswers = "";
  }

  postEdit(index: number, expand: IexpandQuestion) {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑题库";
    this.btnTitle = "确认修改";

    let cloneItem = cloneDeep(expand);
    this.state.expand.id = cloneItem.id;
    this.state.expand.expandQuestion = cloneItem.title;
    this.state.expand.expandAnswers = cloneItem.reference_answer;
  }

  created() {
    this.getListsExpand();
  }

  getListsExpand() {
    getListsExpandRequest(
      this.state.paging.per_page,
      this.state.paging.current_page
    ).then((result) => {
      this.state.paging = result.data;
      console.log("result", result);
    });
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsExpand();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsExpand();
  }

  // 删除数据
  postDelete(index: number, expand: ISpecial) {
    postDeleteExpandRequest(expand.id as number).then((result) => {
      if (result.code === 403) {
        ElMessage.error(result.msg);
      } else {
        ElMessage.success({
          message: "删除成功！",
          type: "success",
        });
      }
      this.drawer = false;
      this.getListsExpand();
    });
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  submitForm(btnTitle: string) {
    this.isMask = false;

    if (
      !existValidation([
        this.state.expand.expandQuestion,
        this.state.expand.expandAnswers,
      ])
    ) {
      ElMessage.error("字段未填写完整");
      return;
    }

    if (btnTitle === "添加") {
      postAddExpandRequest(this.state.expand).then((_) => {
        ElMessage.success({
          message: "创建成功！",
          type: "success",
        });
        this.drawer = false;
        this.getListsExpand();
      });
    } else {
      postAddExpandRequest(this.state.expand).then((_) => {
        ElMessage.success({
          message: "修改成功！",
          type: "success",
        });
        this.drawer = false;
        this.getListsExpand();
      });
    }
  }

  search() {
    this.state.paging.current_page = 1;
    this.getListsExpand();
  }

  reset() {
    this.state.queryParams.cat = undefined;
    this.state.queryParams.question = undefined;
    this.state.paging.current_page = 1;
    this.getListsExpand();
  }
}
