
import { IQuestionAnswer } from "@/typing";
import { Options, Vue } from "vue-class-component";

const numberToZhMap = {
  "1": "一",
  "2": "二",
  "3": "三",
  "4": "四",
  "5": "五",
  "6": "六",
};

@Options({
  props: {
    questionAnswerList: Object,
  },
  emits: ["DeleteClick", "EditClick"],
  computed: {
    numberToZh() {
      return (num: number) => {
        let zh = numberToZhMap[num.toString()] || "未知";
        return zh;
      };
    },
  },
})
export default class QuestionRecord extends Vue {
  questionAnswerList: IQuestionAnswer[];
}
