<template>
  <div class="right-content-box">
    <!-- 表格 -->
    <el-table
      :data="state.paging.data"
      fixed
      style="width: 100%"
      class="manage-box"
      destroy-on-close
    >
      <!-- <el-table-column label="序号" prop="id"> </el-table-column> -->
      <el-table-column label="序号" type="index" width="90" />
      <el-table-column label="姓名" prop="resume.name" width="150"> </el-table-column>
      <el-table-column label="时间" prop="created_at" width="220"> </el-table-column>
      <el-table-column label="星级评价" width="180"> 
        <template #default="scope">
          <template v-if="scope.row.star">
            <span class="love-box" v-for="num of scope.row.star" :key="num.id">
            <img class="love-img" src="../../assets/img/love.svg" alt="">
          </span>
          </template>
          <span v-else>暂无</span>
        </template>

      </el-table-column>


      <el-table-column label="具体原因" prop="reason">
        <template #default="scope">
          <div v-if="scope.row.reason">{{scope.row.reason}}</div>
          <div v-else>暂无</div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="state.paging.total"
      class="paging"
    >
    </el-pagination>

  </div>
</template>

<script lang="ts">
import {
  getListsEvaluationRequest
} from "../../apis/requests/api";
import { IPaging } from "../../typing";
import { reactive } from "@vue/reactivity";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";

@Options({
  mixins: [titleMixin],
})
export default class InterviewEvaluation extends Vue {

  state = reactive<{
    paging: IPaging<any>;
    user: any;
  }>({
    paging: { data: [] },
    user: {},
  });

  created() {
    this.getListseEvaluation();
  }

  rules = {
    username: [
      { required: true, message: "登录账户名", trigger: "blur" },
      { min: 3, max: 10, message: "长度在 3 到 5 个字符", trigger: "blur" },
    ],
  };

  getListseEvaluation(): void {
    getListsEvaluationRequest(
      this.state.paging.per_page,
      this.state.paging.current_page
    ).then((result) => {
      this.state.paging = result.data;
    });
  }

  handleSizeChange(val: number): void {
    this.state.paging.per_page = val;
    this.getListseEvaluation();
  }

  handleCurrentChange(val: number): void {
    this.state.paging.current_page = val;
    this.getListseEvaluation();
  }
}
</script>

<style scoped>
::v-deep .el-table th:first-of-type,
::v-deep .el-table th:last-of-type,
::v-deep .el-table td:first-of-type,
::v-deep .el-table td:last-of-type {
  text-align: center !important;
}

.love-box {
  margin-right: 5px;
}

.love-img {
  vertical-align: middle;
}
</style>