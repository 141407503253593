<template>
  <div class="right-content-box">
    <el-form
      ref="form"
      :model="state.queryParams"
      label-width="60px"
      :label-position="labelPosition"
      class="conditional-query"
    >
      <el-form-item label="姓名" class="conditional-item">
        <el-input
          size="mini"
          v-model="state.queryParams.name"
          placeholder="请输入关键字"
        ></el-input>
      </el-form-item>
      <el-form-item label="岗位" class="conditional-item">
        <el-select
          size="mini"
          v-model="state.queryParams.jid"
          placeholder="请选择"
        >
          <el-option :value="undefined" :label="'全部'"></el-option>
          <el-option
            v-for="job in state.jobs"
            :key="job.id"
            :label="job.job_name"
            :value="job.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="来源" class="conditional-item">
        <el-select
          size="mini"
          v-model="state.queryParams.source"
          placeholder="请选择"
        >
          <el-option :value="undefined" :label="'全部'"></el-option>
          <el-option label="前程无忧" value="前程无忧"></el-option>
          <el-option label="BOSS" value="BOSS"></el-option>
          <el-option label="猎头" value="猎头"></el-option>
          <el-option label="校园招聘" value="校园招聘"></el-option>
          <el-option label="现场招聘" value="现场招聘"></el-option>
          <el-option label="亲友介绍" value="亲友介绍"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" class="conditional-item">
        <el-select
          size="mini"
          v-model="state.queryParams.status"
          placeholder="请选择"
        >
          <el-option :value="undefined" :label="'全部'"></el-option>
          <el-option
            v-for="item in state.resumeStatusList"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间" class="conditional-item">
        <el-date-picker
          size="mini"
          v-model="state.queryParams.createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="datePickerChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-button type="primary" class="btn-add" @click="search()">
        搜索
      </el-button>
      <el-button type="primary" class="btn-add" @click="reset()">
        重置
      </el-button>
    </el-form>

    <!-- 表格 -->
    <el-table
      :data="state.paging.data"
      fixed
      style="width: 100%"
      class="manage-box"
    >
      <template v-for="item in manage" :key="item.prop">
        <el-table-column
          v-if="
            item.prop === 'create_time' ||
            item.prop === 'background_check.whether' ||
            item.prop === 'job_experience[0].time'
          "
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :formatter="formatter"
        ></el-table-column>

        <el-table-column
          v-else-if="item.prop === 'get_status.manage_end'"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
          <template #default="scope">
            <div>
              <div
                v-if="
                  scope.row.get_status.manage_end === '拒绝入职' ||
                  scope.row.get_status.manage_end === '未到岗' ||
                  scope.row.get_status.manage_end === '已淘汰'
                "
              >
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="scope.row.pass_job_reason"
                  placement="top"
                >
                  <span>{{ scope.row.get_status?.manage_end }}</span>
                </el-tooltip>
              </div>
              <span v-else>{{ scope.row.get_status.manage_end }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          v-else
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        ></el-table-column>
      </template>
      <el-table-column label="操作" width="250">
        <template #default="scope">
          <!-- <el-button
            class="array-button"
            size="mini"
            @click="interviewFeedback(scope.row.id)"
            >面试反馈</el-button
          > -->
          <el-button
            class="array-button"
            size="mini"
            @click="resumeDetails(BtnStatus.details, scope.row.id)"
            >简历详情</el-button
          >
          <el-button
            class="array-button"
            size="mini"
            @click="resumeDetails(BtnStatus.record, scope.row.id)"
            >面试记录</el-button
          >
          <!-- <el-button
            class="array-button"
            size="mini"
            @click="corporateCulture(scope.row.id)"
            >企业文化</el-button
          > -->
          <el-button
            size="mini"
            class="array-button"
            @click="testDetails(scope.row.id, scope.row.name, scope.row)"
            >测试详情</el-button
          >
          <!-- <el-popconfirm
            v-if="
              buttonScreenMap[scope.row.status] &&
              ((buttonScreenMap[scope.row.status] === '通过筛选' &&
                state.authorityList.includes('简历筛选')) ||
                (buttonScreenMap[scope.row.status] === '通过录用' &&
                  state.authorityList.includes('面试录用')) ||
                (buttonScreenMap[scope.row.status] === '确认入职' &&
                  state.authorityList.includes('入职到岗')))
            "
            @confirm="operate(scope.row.id, scope.row.status)"
            title="确认此操作吗？"
          >
            <template #reference>
              <el-button type="primary" class="array-button" size="mini">{{
                buttonScreen(scope.row.status)
              }}</el-button>
            </template>
          </el-popconfirm> -->

          <!-- <el-popconfirm
            v-if="scope.row.get_status.manage_end === '录用'"
            title="确定此操作吗？"
            @confirm="induction(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button class="array-button" size="mini" type="primary"
                >入职</el-button
              >
            </template>
          </el-popconfirm> -->

          <template v-if="scope.row.get_status.manage_end === '待入职'">
            <el-button
              class="array-button"
              size="mini"
              type="primary"
              @click="
                state.currentInductionRow = scope.row;
                inductionDialogTableVisible = true;
              "
              >入职</el-button
            >

            <el-button
              class="array-button"
              size="mini"
              type="danger"
              @click="
                state.currentInductionRow = scope.row;
                isReasonForNotOnDuty = true;
                showYesOrNo = '拒绝入职';
              "
              >拒绝入职</el-button
            >

            <!-- <el-popconfirm
              title="确定此操作吗？"
              @confirm="noInduction(scope.$index, scope.row)"
            >
              <template #reference>
                <el-button class="array-button" size="mini" type="danger"
                  >拒绝入职</el-button
                >
              </template>
            </el-popconfirm> -->
          </template>

          <template v-if="scope.row.get_status.manage_end === '未到岗'">
            <el-button
              class="array-button"
              size="mini"
              type="primary"
              @click="induction(scope.$index, scope.row)"
              >重新到岗</el-button
            >
          </template>

          <template v-if="scope.row.get_status.manage_end === '待到岗'">
            <div>
              <el-button
                class="array-button"
                size="mini"
                type="primary"
                @click="induction(scope.$index, scope.row)"
                >到岗</el-button
              >

              <!-- <el-popconfirm
                title="确定此操作吗？"
                @confirm="refuseInduction(scope.row)"
              >
                <template #reference>
                  <el-button class="array-button" size="mini" type="danger"
                    >未到岗</el-button
                  >
                </template>
              </el-popconfirm> -->

              <el-button
                class="array-button"
                size="mini"
                type="danger"
                @click="
                  state.currentInductionRow = scope.row;
                  isReasonForNotOnDuty = true;
                "
                >未到岗</el-button
              >

              <el-button
                class="array-button"
                size="mini"
                type="primary"
                @click="
                  state.currentInductionRow = scope.row;
                  inductionDialogTableVisible = true;
                "
                >修改入职时间</el-button
              >
            </div>
          </template>

          <el-button
            v-if="scope.row.get_status.manage_end === '拒绝入职'"
            class="array-button"
            size="mini"
            type="primary"
            @click="
              state.currentInductionRow = scope.row;
              inductionDialogTableVisible = true;
            "
            >重新入职</el-button
          >
          <!-- <el-popconfirm
            v-if="scope.row.get_status.manage_end === '未入职'"
            title="确定此操作吗？"
            @confirm="induction(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button class="array-button" size="mini" type="primary"
                >重新入职</el-button
              >
            </template>
          </el-popconfirm> -->

          <el-popconfirm
            title="确定删除数据吗？"
            @confirm="resumeDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button class="array-button" size="mini" type="danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>

          <!-- <el-popconfirm
            v-if="scope.row.status === '待筛选'"
            @confirm="failed(scope.row.id, '未通过')"
            title="确认此操作吗？"
          >
            <template #reference>
              <el-button type="danger" class="array-button" size="mini"
                >未通过筛选</el-button
              >
            </template>
          </el-popconfirm> -->
          <!-- <el-button
            v-if="
              state.selectedIdList.includes(scope.row.id) &&
              buttonScreenMap[scope.row.status]
            "
            class="array-button"
            size="mini"
            type="primary"
            @click="operate(scope.row.id, scope.row.status)"
            >{{ buttonScreen(scope.row.status) }}</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="state.paging.total"
      class="paging"
    >
    </el-pagination>

    <!-- 简历详情-->
    <div :class="{ 'no-overflow': !isShowPdfButton }">
      <el-drawer
        title="简历详情"
        :destroy-on-close="true"
        v-model="resumeDrawer"
        :modal="true"
        :with-header="false"
        :before-close="handleClose"
        size="590px"
      >
        <div
          id="jlxq"
          style="
            height: calc(100% - calc(60 / 14 * 1rem));
            overflow-y: auto;
            overflow-x: hidden;
            position: relative;
          "
          :class="{ 'no-overflow': !isShowPdfButton }"
        >
          <div
            v-for="(feedbackItem, index) in state.listsFeedback"
            :key="feedbackItem.id"
            class="comment-stamp"
          >
            <div v-if="index < 3" style="position: ralative">
              <img src="@/assets/img/img-bg.png" alt="" class="comment-img" />
              <div class="comment-advantage">{{ feedbackItem.merit }}</div>
              <div class="comment-insufficient">
                {{ feedbackItem.shortcoming }}
              </div>
              <div class="reviewer">
                {{ feedbackItem.name }} {{ feedbackItem.create_time }}
              </div>
            </div>
          </div>

          <!-- 简历详情 -->
          <div class="resume-box">
            <!-- <div
              v-if="
                state.details?.interview_status === 5 ||
                state.details?.interview_status === 51
              "
            >
              <div class="form-title">
                人员面试{{
                  state.details?.interview_status === 5 ? "录用" : "淘汰"
                }}反馈表
              </div>
              <table class="table">
                <tbody>
                  <tr>
                    <td
                      width="4%"
                      :rowspan="feedbackListName.length + 2"
                      style="writing-mode: vertical-lr"
                    >
                      面试人员情况
                    </td>
                    <td width="13%">姓名</td>
                    <td width="19%">{{ state.details?.name }}</td>
                    <td width="13%">性别</td>
                    <td width="19%">{{ state.details?.sex }}</td>
                    <td width="13%">面试岗位</td>
                    <td width="19%">{{ state.details?.get_job?.job_name }}</td>
                  </tr>
                  <tr v-if="state.details && state.details.edu_experience">
                    <td>毕业学校</td>
                    <td>
                      {{
                        state.details?.edu_experience[
                          state.details?.edu_experience?.length - 1
                        ].school
                      }}
                    </td>
                    <td>学历</td>
                    <td>
                      {{
                        state.details?.edu_experience[
                          state.details?.edu_experience?.length - 1
                        ].education
                      }}
                    </td>
                    <td>专业</td>
                    <td>
                      {{
                        state.details?.edu_experience[
                          state.details?.edu_experience?.length - 1
                        ].major
                      }}
                    </td>
                  </tr>
                  <tr v-for="name in feedbackListName" :key="name">
                    <td>{{ name }}面试反馈</td>
                    <td colspan="6" style="text-align: left; padding: 0">
                      <div
                        v-for="item in state.feedbackList[name][0]"
                        :key="item.id"
                        class="interview-box"
                      >
                        <div class="interview-title">
                          {{ numberToZh(item.gradation_status) }}面反馈
                        </div>
                        <div class="interview-details">
                          <div class="details-left">
                            亮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点：
                          </div>
                          <div class="details-right">
                            {{ item.highlights }}
                          </div>
                        </div>
                        <div class="interview-details">
                          <div class="details-left">有待提升：</div>
                          <div class="details-right">
                            {{ item.promote_space }}
                          </div>
                        </div>
                        <div class="interview-details">
                          <div class="details-left">试用期工资：</div>
                          <div class="details-right">
                            {{ item.trial_salary }}元
                          </div>
                        </div>
                        <div class="interview-details">
                          <div class="details-left">转正工资：</div>
                          <div class="details-right">
                            {{ item.recommend_salary }}元
                          </div>
                        </div>
                        <div class="interview-details">
                          <div class="details-left">试用期：</div>
                          <div class="details-right">
                            {{ item.trial_time }}个月
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">初试人员</td>
                    <td colspan="2">{{ state.preliminaryTest?.manager }}</td>
                    <td>初试时间</td>
                    <td colspan="2">
                      {{ state.preliminaryTest?.interview_time }}
                    </td>
                  </tr>
                  <tr v-if="state.retest">
                    <td colspan="2">复试人员</td>
                    <td colspan="2">{{ state.retest?.manager }}</td>
                    <td>复试时间</td>
                    <td colspan="2">{{ state.retest?.interview_time }}</td>
                  </tr>

                  <tr v-if="state.details?.interview_status === 5">
                    <td colspan="7">建议薪资</td>
                  </tr>
                  <tr v-if="state.details?.interview_status === 5">
                    <td colspan="3">试用期工资</td>
                    <td colspan="2">转正工资</td>
                    <td colspan="2">试用期时长</td>
                  </tr>
                  <tr v-if="state.details?.interview_status === 5">
                    <td colspan="3">
                      <span class="color999">（</span>
                      <span class="table-text">{{
                        state.probationSalary
                      }}</span>
                      <span class="color999">）</span>元
                    </td>
                    <td colspan="2">
                      <span class="color999">（</span>
                      <span class="table-text">{{ state.normalSalary }}</span>
                      <span class="color999">）</span>元
                    </td>
                    <td colspan="2">
                      <span class="color999">（</span>
                      <span class="table-text">{{
                        state.probationDuration
                      }}</span>
                      <span class="color999">）</span>月
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->

            <!-- <div
              v-if="
                state.details?.interview_status === 5 ||
                state.details?.interview_status === 51
              "
              style="margin-top: 10px; border-bottom: 1px solid blue"
            > -->
            <!-- 问题记录 -->
            <!-- <QuestionRecord
                v-for="(item, index) in state.answersTwoDimensionList"
                :key="index"
                :questionAnswerList="item"
                @EditClick="editRecord"
                @DeleteClick="deleteRecord"
              />
            </div> -->

            <div class="resume-title1">
              <div class="resume-text">{{ state.details.name }}</div>
              <div class="job-intention">
                <span>求职意向： </span>
                {{ state.details?.get_job?.job_name }}
              </div>
            </div>

            <div class="resume-inf">
              <div>
                性别：<span>{{ state.details.sex }}</span>
              </div>
              <div class="inf-item-center">
                出生日期：<span>{{ state.details.birthday }}</span>
              </div>
              <div class="inf-item-right">
                籍贯：<span>{{ state.details.address }}</span>
              </div>
              <div>
                电话：<span>{{ state.details.phone }}</span>
              </div>
              <div class="inf-item-center">
                微信：<span>{{ state.details.wechat }}</span>
              </div>
              <div class="inf-item-right">
                考试分数：<span>{{ state.details.total_score }}分</span>
              </div>
              <div class="job-understanding">
                对岗位的了解：{{ state.details.understand }}
              </div>
            </div>
            <!-- 基本信息 -->
            <div class="resume-title">
              <span class="resume-text">基本信息</span>
              /
              <span class="resume-subtitle">Information</span>
            </div>
            <div class="inf-box">
              <div class="inf-item">
                血型： <span> {{ state.details.blood_type }}</span>
              </div>
              <div class="inf-item">
                户籍类型：<span>{{ state.details.household_register }}</span>
              </div>
              <div class="inf-item">
                婚姻状况：<span>{{
                  state.details.marital_desc
                    ? state.details.marital_desc
                    : state.details.marital_status
                }}</span>
              </div>
              <div class="inf-item">
                政治面貌：<span>{{ state.details.politics }}</span>
              </div>
              <div class="inf-item">
                计算机等级：<span>{{ state.details.computer_level }}</span>
              </div>
              <div class="inf-item">
                英语等级：<span>{{ state.details.cet_level }}</span
                ><span>{{
                  state.details.english_score
                    ? " (" + state.details.english_score + "分)"
                    : ""
                }}</span>
              </div>
              <div class="inf-item">
                其他证书：<span>{{ state.details.certificate || "无" }}</span>
              </div>
              <div class="inf-item">
                体检日期：<span>{{ state.details.health_check || "无" }}</span>
              </div>
              <div class="inf-item">
                健康状况：<span> {{ state.details.health_condition }}</span>
              </div>
              <div class="inf-item">
                健康备注：<span>{{ state.details.health_notes || "无" }}</span>
              </div>
              <div class="inf-item">
                来宁波多久了：<span> {{ state.details.how_long }}</span>
              </div>
            </div>
            <div class="select-reason">
              现居住地址：<span>{{ state.details.live_address }}</span>
            </div>
            <div class="select-reason">
              预计到岗时间：<span>{{
                state.details.expected_induction_time
              }}</span>
            </div>
            <div class="select-reason">
              选择宁波工作的原因：<span>{{
                state.details.what_reason || "无"
              }}</span>
            </div>
            <div class="emergency-name">
              紧急联系人姓名：<span>{{
                state.details.emergency_contact_person
              }}</span>
              &nbsp;&nbsp;&nbsp;&nbsp; /&nbsp;&nbsp;&nbsp;&nbsp; 联系电话：
              <span>{{ state.details.emergency_phone }}</span>
            </div>

            <!-- 爱好特长 -->
            <div class="resume-title">
              <span class="resume-text">爱好特长</span>
              /
              <span class="resume-subtitle">Hobby</span>
            </div>
            <div class="hobby-box">
              <div class="hobby-name">
                爱好：<span>{{ state.details.hobby }}</span>
              </div>
              <div class="hobby-name">
                描述：<span>{{ state.details.hobby_desc }}</span>
              </div>
            </div>

            <!-- 教育经历 -->
            <div class="resume-title">
              <span class="resume-text">教育经历</span>
              /
              <span class="resume-subtitle">Educational Experience</span>
            </div>
            <div
              class="education-box"
              v-for="eduExperience in state.details.edu_experience"
              :key="eduExperience.edu_id"
            >
              <div class="education-list">
                <span class="education-list-left"
                  >{{ eduExperience.edu_time[0] }}&nbsp;&nbsp;-&nbsp;&nbsp;{{
                    eduExperience.edu_time[1]
                  }}</span
                >
                <span class="education-list-center">{{
                  eduExperience.school
                }}</span>
                <span class="education-list-right"
                  >{{ eduExperience.major }}&nbsp;&nbsp;/&nbsp;&nbsp;{{
                    eduExperience.education
                  }}
                </span>
              </div>
              <div class="education-list">
                <span class="education-list-left"
                  >院校等级：{{ eduExperience.school_level_inf }}</span
                >
                <span class="education-list-center"
                  >专业排名：{{ eduExperience.professional_rank }}</span
                >
                <span
                  class="education-list-right"
                  @click="openSchoolWebsite(eduExperience.school)"
                >
                  <span class="website-btn">学校官网</span>
                </span>
              </div>
            </div>

            <!-- 工作经历 -->
            <div class="resume-title">
              <span class="resume-text">工作经历</span>
              /
              <span class="resume-subtitle">Work Experience</span>
            </div>
            <div
              class="job-box"
              v-for="job in state.details.job_experience"
              :key="job.job_id"
            >
              <div v-if="!job.job">
                <div>实习生</div>
                <div class="job-reason">
                  <div class="job-reason-left">亮点及成绩：</div>
                  <div class="jov-reason-right">
                    {{ job.achive }}
                  </div>
                </div>
              </div>
              <div v-if="job.job">
                <div class="education-list">
                  <span v-if="job.company" class="education-list-left"
                    >{{ job.time[0] }}&nbsp;&nbsp;-&nbsp;&nbsp;{{
                      job.time[1]
                    }}</span
                  >
                  <span v-if="job.company" class="education-list-center">{{
                    job.company
                  }}</span>
                  <span v-if="job.job" class="education-list-right">{{
                    job.job
                  }}</span>
                </div>
                <div class="job-inf">
                  <div v-if="job.salary">
                    薪资：<span>{{ job.salary }}</span>
                  </div>
                  <div v-if="job.voucher">
                    证明人：<span>{{ job.voucher }}</span>
                  </div>
                  <div v-if="job.voucher_phone">
                    联系方式：<span>{{ job.voucher_phone }}</span>
                  </div>
                </div>
                <div v-if="job.leave_reason" class="job-reason">
                  <div class="job-reason-left">离职原因：</div>
                  <div class="jov-reason-right">
                    {{ job.leave_reason }}
                  </div>
                </div>
                <div class="job-reason">
                  <div class="job-reason-left">亮点及成绩：</div>
                  <div class="jov-reason-right">
                    {{ job.achive }}
                  </div>
                </div>
              </div>
            </div>

            <!-- 家庭成员 -->
            <div class="resume-title">
              <span class="resume-text">家庭成员</span>
              /
              <span class="resume-subtitle">Family Members</span>
            </div>
            <div>
              <div
                v-for="familyMember in state.details.family_member"
                :key="familyMember.member_id"
                class="family-member"
              >
                <div class="education-list">
                  <span class="education-list-left"
                    >{{ familyMember.member_name }}（{{
                      familyMember.member_relation
                    }}）</span
                  >
                  <span class="education-list-center">
                    {{ familyMember.Memerb_phone }}
                  </span>
                  <span class="education-list-right"
                    >{{ familyMember.member_company }}/{{
                      familyMember.member_job
                    }}</span
                  >
                </div>

                <div class="member-address">
                  <img src="@/assets/img/icon-address.png" alt="" />
                  {{ familyMember.live_address }}
                </div>
              </div>
            </div>

            <!-- 简历来源 -->
            <div class="resume-title">
              <span class="resume-text">简历来源</span>
              /
              <span class="resume-subtitle">Resume Source</span>
            </div>
            <div class="resume-soure">
              <div>
                投递原因：<span>{{
                  state.details.other_reason
                    ? state.details.other_reason
                    : state.details.reason
                }}</span>
              </div>
              <div>
                投递渠道：<span
                  >{{ state.details.offline }}{{ state.details.source }}</span
                >
              </div>
              <div>
                创建时间：<span>{{ state.details.create_time }}</span>
              </div>
            </div>
            <div
              v-if="
                state.details.background_check &&
                state.details.background_check.reason
              "
            >
              <div class="not-accepting">
                不接受原因：
                <div>{{ state.details.background_check.reason }}</div>
              </div>
            </div>

            <!-- 期望与诉求 -->
            <div class="resume-title">
              <span class="resume-text">期望与诉求</span>
              /
              <span class="resume-subtitle">Expect</span>
            </div>
            <div class="expect-inf">
              <div>
                期望薪资：<span class="promotion-position"
                  >{{ state.details.salary }} 元</span
                >
              </div>
              <div>
                晋升期望（职位）：<span class="promotion-position">{{
                  state.details.hope_job || "无"
                }}</span>
              </div>
            </div>
            <div class="expect-box" v-if="state.details.hope_help">
              <div class="expect-title">
                如果您入职后，您希望得到公司怎么样的帮助？
              </div>
              <div class="expect-content">
                {{ state.details.hope_help }}
              </div>
            </div>
            <div class="expect-box" v-if="state.details.hope_work">
              <div class="expect-title">您入职后，准备如何开展后期工作？</div>
              <div class="expect-content">
                {{ state.details.hope_work }}
              </div>
            </div>

            <!-- 测试 -->
            <div class="resume-title">
              <span class="resume-text">测试</span>
              /
              <span class="resume-subtitle">Test</span>
            </div>
            <div class="expect-inf">
              <div>
                总分数：<span class="promotion-position"
                  >{{ state.details.total_score }}分</span
                >
              </div>
            </div>
            <div class="expect-box">
              <div class="expect-title">逻辑评语</div>
              <div class="expect-content">
                <div
                  v-for="logicItem in state.testDetailsListLogic"
                  :key="logicItem.id"
                >
                  <div
                    v-for="optionItem in logicItem.get_special.content"
                    :key="optionItem.optionName"
                  >
                    <div v-if="optionItem.optionName === logicItem.answer">
                      <span class="test-title">{{ optionItem.analysis }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="expect-box">
              <div class="expect-title">性格评语</div>
              <div class="expect-content">
                <div
                  v-for="characterItem in state.testDetailsListCharacter"
                  :key="characterItem.id"
                >
                  <div
                    v-for="optionItem in characterItem.get_special.content"
                    :key="optionItem.optionName"
                  >
                    <div v-if="optionItem.optionName === characterItem.answer">
                      <span class="test-title">{{ optionItem.analysis }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="expect-box" style="margin-bottom: 45px">
              <div class="expect-title">题型评语</div>
              <div class="expect-content">
                <span class="test-title">
                  {{ state.questionType }}
                  {{ state.score / 10 + "/" + state.questionCount }}
                  {{
                    20 > state.score
                      ? "不擅长"
                      : state.score >= 20 && 40 > state.score
                      ? "一般"
                      : "了解"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- <div style="height: 100px; width: 10px"></div> -->
        </div>
        <div class="resume-btn">
          <el-button
            v-if="isShowPdfButton"
            type="primary"
            class="btn-edit"
            @click="savePdf()"
          >
            导出PDF
          </el-button>
        </div>
      </el-drawer>
    </div>

    <!-- 面试记录 -->
    <div>
      <el-drawer
        title="面试记录"
        :destroy-on-close="true"
        v-model="detailDrawer"
        :modal="true"
        :with-header="false"
        :before-close="handleClose"
        size="590px"
        @close="closeDrawer"
      >
        <div
          id="jlxq"
          style="
            height: calc(100% - calc(60 / 14 * 1rem));
            overflow-y: auto;
            overflow-x: hidden;
            position: relative;
          "
        >
          <div
            v-for="(feedbackItem, index) in state.listsFeedback"
            :key="feedbackItem.id"
            class="comment-stamp"
          >
            <div v-if="index < 3" style="position: ralative">
              <img src="@/assets/img/img-bg.png" alt="" class="comment-img" />
              <div class="comment-advantage">{{ feedbackItem.merit }}</div>
              <div class="comment-insufficient">
                {{ feedbackItem.shortcoming }}
              </div>
              <div class="reviewer">
                {{ feedbackItem.name }} {{ feedbackItem.create_time }}
              </div>
            </div>
          </div>

          <!-- 反馈详情 -->
          <div class="resume-box">
            <div>
              <div class="form-title">
                <!-- 人员面试{{
                  state.details?.interview_status === 5 ? "录用" : "淘汰"
                }}反馈表 -->
                人员面试详情表
              </div>
              <table class="table">
                <tbody>
                  <tr>
                    <td
                      width="4%"
                      :rowspan="feedbackListName.length + 3"
                      style="writing-mode: vertical-lr"
                    >
                      面试人员情况
                    </td>
                    <td width="19%">姓名</td>
                    <td width="19%">{{ state.details?.name }}</td>
                    <td width="14%">性别</td>
                    <td width="15%">{{ state.details?.sex }}</td>
                    <td width="13%">面试岗位</td>
                    <td width="19%">{{ state.details?.get_job?.job_name }}</td>
                  </tr>
                  <tr v-if="state.details && state.details.edu_experience">
                    <td>毕业学校</td>
                    <td>
                      {{
                        state.details?.edu_experience[
                          state.details?.edu_experience?.length - 1
                        ].school
                      }}
                    </td>
                    <td>学历</td>
                    <td>
                      {{
                        state.details?.edu_experience[
                          state.details?.edu_experience?.length - 1
                        ].education
                      }}
                    </td>
                    <td>专业</td>
                    <td>
                      {{
                        state.details?.edu_experience[
                          state.details?.edu_experience?.length - 1
                        ].major
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>院校等级</td>
                    <td colspan="2">
                      <span
                        v-for="item in state.resume?.edu_experience"
                        :key="item.edu_id"
                      >
                        {{ item.school_level_inf + " " }}
                      </span>
                    </td>
                    <td>专业排名</td>
                    <td colspan="2">
                      <span
                        v-for="item in state.resume?.edu_experience"
                        :key="item.edu_id"
                      >
                        {{ (item.professional_rank || "") + " " }}
                      </span>
                    </td>
                  </tr>
                  <tr v-for="name in feedbackListName" :key="name">
                    <td>{{ name }}面试反馈</td>
                    <td colspan="6" style="text-align: left; padding: 0">
                      <div
                        v-for="(temp, indexTwo) in state.feedbackList[name]"
                        :key="indexTwo"
                        class="interview-box"
                      >
                        <div v-for="item in temp" :key="item.id">
                          <div class="interview-title">
                            {{ numberToZh(item.gradation_status) }}面反馈
                          </div>
                          <template v-if="item.is_pass == '1'">
                            <div class="interview-details">
                              <div class="details-left">
                                亮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点：
                              </div>
                              <div class="details-right">
                                {{ item.highlights }}
                              </div>
                            </div>
                            <div class="interview-details">
                              <div class="details-left">对标人物：</div>
                              <div class="details-right">
                                {{ item.related_person }}
                              </div>
                            </div>
                            <div class="interview-details">
                              <div class="details-left">岗位/职业规划：</div>
                              <div class="details-right">
                                {{ item.career_planning }}
                              </div>
                            </div>
                            <div class="interview-details">
                              <div class="details-left">核心亮点：</div>
                              <div class="details-right">
                                {{ item.kernel_highlights }}
                              </div>
                            </div>
                            <div class="interview-details">
                              <div class="details-left">有待提升：</div>
                              <div class="details-right">
                                {{ item.promote_space }}
                              </div>
                            </div>
                            <!-- <div class="interview-details">
                              <div class="details-left">试用期工资：</div>
                              <div class="details-right">
                                {{ item.trial_salary }}元
                              </div>
                            </div>
                            <div class="interview-details">
                              <div class="details-left">转正工资：</div>
                              <div class="details-right">
                                {{ item.recommend_salary }}元
                              </div>
                            </div>
                            <div class="interview-details">
                              <div class="details-left">试用期：</div>
                              <div class="details-right">
                                {{ item.trial_time }}个月
                              </div>
                            </div> -->
                          </template>
                          <template v-else-if="item.is_pass == '2'">
                            <div class="interview-details">
                              <div class="details-left">待定时间：</div>
                              <div class="details-right">
                                {{ item.pending_time }}
                              </div>
                            </div>
                            <div class="interview-details">
                              <div class="details-left">待定原因：</div>
                              <div class="details-right">
                                {{ item.pending_reason }}
                              </div>
                            </div>
                          </template>
                          <template v-else item.is_pass="0">
                            <div class="interview-details">
                              <div class="details-left">淘汰原因：</div>
                              <div class="details-right">
                                {{ item.failure_reason }}
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" v-if="state.stateType === 'employ'">
                      录用核心原因
                    </td>
                    <td colspan="2" v-else>淘汰理由</td>
                    <td colspan="5">
                      <span>{{ state.coreReason }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">审批官</td>
                    <td colspan="5">{{ state.lastExamine }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">初试人员</td>
                    <td colspan="2">{{ state.preliminaryTest?.manager }}</td>
                    <td>初试时间</td>
                    <td colspan="2">
                      {{ state.preliminaryTest?.interview_time }}
                    </td>
                  </tr>
                  <tr v-if="state.retest">
                    <td colspan="2">复试人员</td>
                    <td colspan="2">{{ state.retest?.manager }}</td>
                    <td>复试时间</td>
                    <td colspan="2">{{ state.retest?.interview_time }}</td>
                  </tr>

                  <tr v-if="state.stateType === 'employ'">
                    <td colspan="7">建议薪资</td>
                  </tr>
                  <tr v-if="state.stateType === 'employ'">
                    <td colspan="2">试用期工资</td>
                    <td colspan="2">转正工资</td>
                    <td colspan="2">综合年薪</td>
                    <td colspan="1">试用期时长</td>
                  </tr>
                  <tr v-if="state.stateType === 'employ'">
                    <td colspan="2">
                      <span class="color999">（</span>
                      <span class="table-text">{{
                        state.probationSalary
                      }}</span>
                      <span class="color999">）</span>元
                    </td>
                    <td colspan="2">
                      <span class="color999">（</span>
                      <span class="table-text">{{ state.normalSalary }}</span>
                      <span class="color999">）</span>元
                    </td>
                    <td colspan="2">
                      <span class="color999">（</span>
                      <span class="table-text">{{ state.yearlySalary }}</span>
                      <span class="color999">）</span>元
                    </td>
                    <td colspan="1">
                      <span class="color999">（</span>
                      <span class="table-text">{{
                        state.probationDuration
                      }}</span>
                      <span class="color999">）</span>月
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <!-- 问题记录 -->
              <QuestionRecord
                v-for="(item, index) in state.answersTwoDimensionList"
                :key="index"
                :questionAnswerList="item"
              />
            </div>

            <!-- 面试速记 -->
            <template v-for="(picList, key) in state.shorthand" :key="key">
              <div class="continer-box">
                <div class="interview-shorthand-box">
                  <div class="interview-shorthand">
                    {{ numberToZh(key) }}面面试速记
                  </div>

                  <!-- <div
                    style="
                      display: block;
                      color: #4f81f9;
                      margin-top: calc(20 / 20 * 1rem);
                    "
                  >
                    {{ key }}面
                  </div> -->
                  <div class="shorthand-box">
                    <div
                      class="el-image-fill"
                      v-for="(pic, index) in picList"
                      :key="pic"
                    >
                      <el-image
                        :src="pic"
                        :preview-src-list="picList"
                        :initial-index="index"
                        lazy
                      ></el-image>
                      <!-- <div class="image-delete" @click="deletePic(pic)">x</div> -->
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </el-drawer>
    </div>

    <!-- 测试详情-->
    <el-drawer title="测试详情" v-model="testDrawer" :destroy-on-close="true">
      <div id="csxq" class="test-box">
        <div class="item-title">
          总分数:
          <span style="color: #0079fe">{{ state.questionType }}</span>
        </div>

        <div class="test-details">{{ state.total_score }}分</div>

        <div class="item-title">
          逻辑评语: {{ logicScoreLevel(state.logicScore) }}
        </div>
        <div class="test-details">
          <div
            v-for="logicItem in state.testDetailsListLogic"
            :key="logicItem.id"
          >
            <div
              v-for="optionItem in logicItem.get_special.content"
              :key="optionItem.optionName"
            >
              <div v-if="optionItem.optionName === logicItem.answer">
                <span class="test-title">{{ optionItem.analysis }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="item-title">性格评语:</div>
        <div class="test-details">
          <div
            v-for="characterItem in state.testDetailsListCharacter"
            :key="characterItem.id"
          >
            <div
              v-for="optionItem in characterItem.get_special.content"
              :key="optionItem.optionName"
            >
              <div v-if="optionItem.optionName === characterItem.answer">
                <span class="test-title">{{ optionItem.analysis }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="item-title">题型评语:</div>
        <div class="test-details">
          <span class="test-title">
            {{ state.questionType }}
            {{ state.score / 10 + "/" + state.questionCount }}
            {{
              20 > state.score
                ? "不擅长"
                : state.score >= 20 && 40 > state.score
                ? "一般"
                : "了解"
            }}
          </span>
        </div>

        <div class="item-title">逻辑题目：</div>
        <div
          v-for="(logicItem, index) in state.testDetailsListLogic"
          :key="logicItem.id"
        >
          <div class="test-details">
            <div class="test-title">
              ({{ index + 1 }}/{{ state.testDetailsListLogic.length }}){{
                logicItem.get_special.question
              }}
            </div>
            <div
              v-for="optionItem in logicItem.get_special.content"
              :key="optionItem.optionName"
            >
              <div class="test-result">
                <div
                  class="result-list"
                  :class="{
                    'result-active': logicItem.answer === optionItem.optionName,
                  }"
                >
                  <span
                    >{{ optionItem.optionName }}：{{
                      optionItem.optionContent
                    }}</span
                  >
                  <div
                    v-if="logicItem.answer === optionItem.optionName"
                    class="selected-item"
                  >
                    <span>{{ durationMin(logicItem.timeStamp) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="item-title">性格题目：</div>
        <div
          v-for="(characterItem, index) in state.testDetailsListCharacter"
          :key="characterItem.id"
        >
          <div class="test-details">
            <div class="test-title">
              ({{ index + 1 }}/{{ state.testDetailsListCharacter.length }}){{
                characterItem.get_special.question
              }}
            </div>
            <div
              v-for="optionItem in characterItem.get_special.content"
              :key="optionItem.optionName"
            >
              <div class="test-result">
                <div
                  class="result-list"
                  :class="{
                    'result-active':
                      characterItem.answer === optionItem.optionName,
                  }"
                >
                  <span
                    >{{ optionItem.optionName }}：{{
                      optionItem.optionContent
                    }}</span
                  >
                  <div
                    v-if="characterItem.answer === optionItem.optionName"
                    class="selected-item"
                  >
                    <span>{{ durationMin(characterItem.timeStamp) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="item-title">普通题目：</div>
        <div
          v-for="(ordinaryItem, index) in state.testDetailsListOrdinary"
          :key="ordinaryItem.id"
        >
          <div class="test-details">
            <div class="test-title">
              ({{ index + 1 }}/{{ state.testDetailsListOrdinary.length }}){{
                ordinaryItem.get_exam.question
              }}
            </div>
            <div class="test-result">
              <div
                class="result-list"
                :class="[
                  ordinaryItem.answer === 'option1'
                    ? ordinaryItem.is_right == '1'
                      ? 'result-active'
                      : 'result-active-error'
                    : '',
                ]"
              >
                <span>A：{{ ordinaryItem.get_exam.option1 }}</span>
                <div
                  v-if="ordinaryItem.answer === 'option1'"
                  class="selected-item"
                >
                  <span>{{
                    ordinaryItem.is_right == "1" ? "正确" : "错误"
                  }}</span>
                  <span>{{ durationMin(ordinaryItem.timeStamp) }}</span>
                </div>
              </div>
              <div
                class="result-list"
                :class="[
                  ordinaryItem.answer === 'option2'
                    ? ordinaryItem.is_right == '1'
                      ? 'result-active'
                      : 'result-active-error'
                    : '',
                ]"
              >
                <span>B：{{ ordinaryItem.get_exam.option2 }}</span>
                <div
                  v-if="ordinaryItem.answer === 'option2'"
                  class="selected-item"
                >
                  <span>{{
                    ordinaryItem.is_right == "1" ? "正确" : "错误"
                  }}</span>
                  <span>{{ durationMin(ordinaryItem.timeStamp) }}</span>
                </div>
              </div>
              <div
                class="result-list"
                :class="[
                  ordinaryItem.answer === 'option3'
                    ? ordinaryItem.is_right == '1'
                      ? 'result-active'
                      : 'result-active-error'
                    : '',
                ]"
              >
                <span>C：{{ ordinaryItem.get_exam.option3 }}</span>
                <div
                  v-if="ordinaryItem.answer === 'option3'"
                  class="selected-item"
                >
                  <span>{{
                    ordinaryItem.is_right == "1" ? "正确" : "错误"
                  }}</span>
                  <span>{{ durationMin(ordinaryItem.timeStamp) }}</span>
                </div>
              </div>
              <div
                class="result-list"
                :class="[
                  ordinaryItem.answer === 'option4'
                    ? ordinaryItem.is_right == '1'
                      ? 'result-active'
                      : 'result-active-error'
                    : '',
                ]"
              >
                <span>D：{{ ordinaryItem.get_exam.option4 }}</span>
                <div
                  v-if="ordinaryItem.answer === 'option4'"
                  class="selected-item"
                >
                  <span>{{
                    ordinaryItem.is_right == "1" ? "正确" : "错误"
                  }}</span>
                  <span>{{ durationMin(ordinaryItem.timeStamp) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="test-details">
            <span class="result-list"
              >正确答案:
              {{
                ordinaryItem.get_exam.answer === "option1"
                  ? "A"
                  : ordinaryItem.get_exam.answer === "option2"
                  ? "B"
                  : ordinaryItem.get_exam.answer === "option3"
                  ? "C"
                  : "D"
              }}</span
            >
            <div>
              <span class="result-list"
                >解析: {{ ordinaryItem.get_exam.analysis }}</span
              >
            </div>
          </div>
        </div>

        <div>
          <div class="item-title" style="color: rgb(0, 121, 254)">拓展题型</div>
          <div
            v-if="isAnswerQuestion && Object.keys(isAnswerQuestion).length > 0"
          >
            <div class="test-details">
              <div class="item-title change-left">了解问题:</div>
              <div
                class="question-title"
                v-for="item in state.currentDetails.expansion"
                :key="item.id"
              >
                {{ item.title }}
              </div>
            </div>

            <div class="test-details">
              <div class="item-title change-left">随机问答</div>
              <div class="item-title item-text change-left">
                问：{{ isAnswerQuestion.title }}
              </div>
              <div class="answer-box item-text">
                <div>答：</div>
                <div>{{ isAnswerQuestion.pivot.answer }}</div>
              </div>
            </div>

            <div class="test-details">
              <div class="item-title change-left">参考答案</div>
              <div class="answer-box item-text">
                {{ isAnswerQuestion.reference_answer }}
              </div>
            </div>
          </div>

          <div v-else>
            <div class="test-details">
              <div class="item-title">了解问题:</div>
              <div class="question-title not-question">都不了解</div>
            </div>
            <div
              class="test-details"
              v-if="state.currentDetails.expansion_summary"
            >
              <div class="item-title">心得或总结:</div>
              <div class="question-title test-harvest">
                {{ state.currentDetails.expansion_summary }}
              </div>
            </div>
          </div>
        </div>

        <el-button
          v-if="isShowPdfButton"
          type="primary"
          class="btn-edit"
          @click="savePdfTest()"
        >
          导出PDF
        </el-button>
      </div>
    </el-drawer>

    <!-- 企业文化 -->
    <el-drawer title="企业文化" v-model="corporateCultureDrawer">
      <div id="qywh" class="test-box">
        <div
          class="corporate-culture-drawer-item"
          v-for="item in state.details.propaganda"
          :key="item.title"
        >
          <span>{{ item.title }}</span>
          <span>{{ durationMin(item.duration) }}</span>
        </div>
      </div>
    </el-drawer>

    <!-- 入职到岗 -->
    <el-drawer title="入职到岗" v-model="comeOnDrawer">
      <div id="rzdg" class="test-box">
        <div class="item-title">办公区域</div>
        <el-select
          v-model="state.comeOnData.oa_address"
          @change="oaAddressChange"
          placeholder="请选择"
        >
          <el-option
            v-for="item in state.boardingResume.address"
            :key="item.id"
            :label="item.keyword"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <div class="item-title">公司</div>
        <el-select
          v-model="state.comeOnData.oa_user_company"
          :disabled="!state.comeOnData.oa_address"
          placeholder="请选择"
        >
          <el-option
            v-for="item in allowSelectCompanyList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <div class="item-title">部门</div>
        <el-select
          v-model="state.comeOnData.oa_department"
          placeholder="请选择"
          @change="departmentSelectChange"
        >
          <el-option
            v-for="item in state.boardingResume.dep"
            :key="item.id"
            :label="item.dep_name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <div class="item-title">部门分组</div>
        <el-select
          v-model="state.comeOnData.oa_dep_group"
          placeholder="请选择"
          :disabled="!state.selectedDep"
        >
          <el-option
            v-for="item in state.selectedDep?.get_group"
            :key="item.id"
            :label="item.group_name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <div class="item-title">职位</div>
        <el-select
          v-model="state.comeOnData.oa_job"
          placeholder="请选择"
          :disabled="!state.selectedDep"
        >
          <el-option
            v-for="item in state.selectedDep?.get_job"
            :key="item.id"
            :label="item.job_name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <div class="item-title">钉钉 userID</div>
        <el-input
          v-model="state.comeOnData.did"
          placeholder="请输入"
        ></el-input>

        <div class="item-title">英文名</div>
        <el-input
          v-model="state.comeOnData.nickname"
          placeholder="请输入"
        ></el-input>

        <div class="item-title">邮箱</div>
        <el-input
          v-model="state.comeOnData.email"
          placeholder="请输入"
        ></el-input>

        <div class="item-title">是否生育</div>
        <el-select v-model="state.comeOnData.birthStatus" placeholder="请选择">
          <el-option
            v-for="item in [
              { label: '已生育', value: '1' },
              { label: '未生育', value: '0' },
            ]"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <div class="item-title">身份证</div>
        <el-input
          v-model="state.comeOnData.idCard"
          placeholder="请输入"
        ></el-input>

        <div class="resume-btn">
          <el-button type="primary" class="btn-edit" @click="comeOnDataOk()">
            确定
          </el-button>
        </div>
      </div>
    </el-drawer>

    <!-- 面试反馈 -->
    <el-drawer title="面试反馈" v-model="interviewFeedbackDrawer">
      <div id="msfk" class="test-box">
        <div v-if="!isAddFeedback">
          <div>
            <div
              class="feedback-item"
              v-for="item in state.listsFeedback"
              :key="item.id"
            >
              <span>{{ item.name }}</span>
              <span>{{ item.create_time }}</span>
              <el-button
                type="primary"
                size="mini"
                class="array-button"
                @click="editFeedback(item)"
              >
                编辑
              </el-button>
              <el-popconfirm
                title="确定删除数据吗？"
                @confirm="listsFeedbackDelete(item.id)"
              >
                <template #reference>
                  <el-button class="array-button" size="mini" type="danger"
                    >删除</el-button
                  >
                </template>
              </el-popconfirm>
            </div>
          </div>
          <el-button type="primary" class="btn-edit" @click="addFeedback()">
            添加反馈
          </el-button>
        </div>
        <div v-else>
          <div>
            <span
              style="float: right; font-size: 20px; cursor: pointer"
              @click="isAddFeedback = false"
              >×</span
            >
          </div>
          <div class="item-title">评价人<span style="color: red">*</span></div>
          <el-input
            v-model="state.feedback.name"
            placeholder="输入面试官名称"
          ></el-input>
          <div class="item-title">
            评价时间<span style="color: red">*</span>
          </div>
          <el-date-picker
            style="width: 100%"
            v-model="state.feedback.create_time"
            type="date"
            placeholder="选择面试日期"
          >
          </el-date-picker>
          <div class="item-title">优点<span style="color: red">*</span></div>
          <el-input
            v-model="state.feedback.merit"
            placeholder="有哪些优点"
          ></el-input>
          <div class="item-title">不足<span style="color: red">*</span></div>
          <el-input
            v-model="state.feedback.shortcoming"
            placeholder="有哪些不足"
          ></el-input>
          <el-button type="primary" class="btn-edit" @click="submitFeedback()">
            提交
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>

  <el-dialog
    v-model="inductionDialogTableVisible"
    title="请选择入职时间"
    width="300px"
  >
    <el-date-picker
      style="width: 100% !important"
      v-model="state.inductionDate"
      type="date"
      placeholder="请选择"
    >
    </el-date-picker>
    <el-button type="primary" class="btn-edit" @click="confirmInductionDate()">
      确定
    </el-button>
  </el-dialog>

  <el-dialog
    v-model="isReasonForNotOnDuty"
    :title="showYesOrNo === '拒绝入职' ? '拒绝入职原因' : '未到岗原因'"
    width="300px"
  >
    <el-input
      v-model="state.notOnDutyReason"
      :placeholder="
        showYesOrNo === '拒绝入职' ? '请输入拒绝入职原因' : '请输入未到岗原因'
      "
      type="textarea"
    />
    <el-button
      type="primary"
      class="btn-edit"
      @click="showYesOrNo === '拒绝入职' ? noInduction() : confirmReasonText()"
    >
      确定
    </el-button>
  </el-dialog>
</template>

<script lang="ts">
import {
  getStatusResume,
  postAddFeedbackRequest,
  postBoardingResumeRequest,
  postConfirmResumeRequest,
  postDelFeedbackRequest,
  postDelResumeRequest,
  postEditFeedbackRequest,
  postInduction,
  postListsFeedbackRequest,
  postListsResumeRequest,
  postNoInduction,
  postRefuseInduction,
  postShowResumeRequest,
  postStatusResumeRequest,
  postTestResumeRequest,
  getDepartShowPhoto,
} from "../../apis/requests/api";
import {
  IBoardingResume,
  IComeOnData,
  ICompany,
  IDep,
  IFeedback,
  IJob,
  IPaging,
  IQuestionAnswer,
  IResume,
  ITestDetails,
} from "../..//typing";
import { reactive } from "@vue/reactivity";
import { ElMessage } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";
import { html2pdf } from "../../share/html2pdf";
import {
  dateFormat,
  existValidation,
  isCardNo,
  isEmail,
} from "../../share/util";
import { getAuthority } from "../../apis/ajax";
import QuestionRecord from "../../components/question-record.component.vue";
import { environment } from "@/environments/environments";

interface QueryParams {
  name?: string;
  jid?: number;
  source?: string;
  status?: string;
  createTime?: Date[];
}

interface IFeedbackList {
  [key: string]: IFeedback[];
}

enum BtnStatus {
  details = "简历详情",
  record = "面试记录",
}

const numberToZhMap = {
  "1": "一",
  "2": "二",
  "3": "三",
  "4": "四",
  "5": "五",
  "6": "六",
};

@Options({
  components: {
    QuestionRecord,
  },
  mixins: [titleMixin],
  computed: {
    buttonScreen() {
      return (status: string) => {
        return this.buttonScreenMap[status];
      };
    },
    durationMin() {
      return (sec: number) => {
        let min: any = Math.floor(sec / 60);
        if (min < 10) {
          min = "0" + min;
        }
        let temp: any = sec % 60;
        if (temp < 10) {
          temp = "0" + temp;
        }
        return `${min}:${temp}`;
      };
    },
    allowSelectCompanyList() {
      return (this.state.boardingResume.company as ICompany[]).filter(
        (item) => item.location === this.state.comeOnData.address
      );
    },
    logicScoreLevel() {
      return (logicScore: number) => {
        let text;
        if (logicScore >= 0 && logicScore <= 4) {
          text = "逻辑不清晰";
        } else if (logicScore >= 5 && logicScore <= 15) {
          text = "逻辑正常";
        } else {
          text = "逻辑优秀";
        }
        return text;
      };
    },
    feedbackListName() {
      const result = [];
      for (const name in this.state.feedbackList) {
        result.push(name);
      }
      return result;
    },
    numberToZh() {
      return (num: number) => {
        let zh = numberToZhMap[num.toString()] || "未知";
        return zh;
      };
    },
  },
})
export default class ResumeList extends Vue {
  BtnStatus = BtnStatus;
  apiUrl = environment.apiUrl;
  isAnswerQuestion = {};
  buttonScreenMap = {
    待筛选: "通过筛选",
    待录用: "通过录用",
    待入职: "确认入职",
  };

  isMask = false;
  isShowPdfButton = true;

  deliveryPeriod!: string | undefined;

  isAddFeedback = false;

  inductionDialogTableVisible = false;
  isReasonForNotOnDuty = false;
  // showYesOrNo!: string;
  showYesOrNo = "";
  state = reactive<{
    queryParams: QueryParams;
    paging: IPaging<IResume>;
    jobs: IJob[];
    details: IResume;
    testDetailsList: ITestDetails[];
    authorityList: string[];
    testDetailsListOrdinary: ITestDetails[];
    testDetailsListLogic: ITestDetails[];
    testDetailsListCharacter: ITestDetails[];
    questionType?: string;
    score?: number;
    total_score?: number;
    boardingResume: IBoardingResume;
    comeOnData: IComeOnData;
    feedback: IFeedback;
    rid?: number; // 面试反馈使用
    listsFeedback: IFeedback[];
    selectedDep?: IDep;
    logicScore?: number;
    resumeStatusList: string[];
    resume?: IResume;
    feedbackList: IFeedbackList;
    preliminaryTest?: IFeedback;
    retest?: IFeedback;
    probationSalary?: number;
    probationDuration?: number;
    normalSalary?: number;
    yearlySalary?: number;
    answersTwoDimensionList: IQuestionAnswer[][];
    inductionDate?: Date;
    currentInductionRow?: any;
    notOnDutyReason?: string;
    questionCount?: number;
    shorthand: { [key: number]: string[] };
    coreReason?: string;
    stateType?: string;
    lastExamine?: string;
    currentDetails?: IResume;
  }>({
    queryParams: {},
    paging: { data: [] },
    jobs: [],
    details: {},
    testDetailsList: [],
    authorityList: [],
    testDetailsListOrdinary: [],
    testDetailsListLogic: [],
    testDetailsListCharacter: [],
    boardingResume: {
      address: [],
      company: [],
      dep: [],
    },
    comeOnData: {
      id: undefined,
      oa_department: undefined,
      oa_job: undefined,
      oa_user_company: undefined,
      oa_address: undefined,
      did: undefined,
      address: undefined,
      idCard: undefined,
      birthStatus: undefined,
      email: undefined,
      nickname: undefined,
    },
    feedback: {},
    listsFeedback: [],
    resumeStatusList: [],
    feedbackList: {},
    answersTwoDimensionList: [],
    shorthand: {},
  });

  created() {
    this.getListsResume();
    this.getStatusResume();
    this.state.authorityList = (getAuthority() as string).split("/");
  }

  initFkb(resume: any) {
    this.state.resume = resume;
    this.state.probationSalary = resume["probation_salary"];
    this.state.probationDuration = resume["probation_period"];
    this.state.normalSalary = resume["official_salary"];
    this.state.yearlySalary = resume["yearly_salary"];
    this.state.coreReason = resume["core_reason"];
    this.state.stateType = resume["state_type"];
    this.state.lastExamine = resume["last_examine"];
    // 部门类别（包含 hr 总经理）
    const depTypes: string[] = Array.from(
      new Set(
        this.state.resume["get_evaluate"].map((item) => item.manager_type)
      )
    );

    for (const name of depTypes) {
      this.state.feedbackList[name] = [];
      this.loadFeedbackClassify(
        name,
        this.state.feedbackList[name],
        this.state.resume["get_evaluate"]
      );
    }

    let zjl = this.state.feedbackList["总经理"];
    for (const key in this.state.feedbackList) {
      if (!["人力资源", "总经理"].includes(key)) {
        const temp = this.state.feedbackList[key].sort(
          (a, b) => a["gradation_status"] - b["gradation_status"]
        );

        this.state.preliminaryTest = temp[0][0]; // 不知为何 proxy 包裹两层
        if (temp.length >= 2) {
          this.state.retest = temp[1][0]; // 不知为何 proxy 包裹两层
        }

        break;
      }
    }

    if (!this.state.retest) {
      if (zjl && zjl.length > 0) {
        this.state.retest = zjl[0][0]; // 不知为何 proxy 包裹两层
      }
    }
  }

  loadFeedbackClassify(
    name: string,
    feedbackList: any[],
    list: any[],
    howManyTimesInterview?: number
  ) {
    howManyTimesInterview = howManyTimesInterview || 1;

    const tempList = list.filter(
      (item) =>
        item.gradation_status === howManyTimesInterview &&
        item.manager_type === name
    );
    if (tempList.length > 0) {
      feedbackList.push(tempList);
    }

    const leftover = list.filter(
      (item) =>
        item.gradation_status !== howManyTimesInterview &&
        item.manager_type === name
    );
    if (leftover.length > 0) {
      this.loadFeedbackClassify(
        name,
        feedbackList,
        leftover,
        ++howManyTimesInterview
      );
    }
  }

  getStatusResume() {
    getStatusResume().then((result) => {
      this.state.resumeStatusList = Object.keys(result.data).reverse();
    });
  }

  getListsResume() {
    postListsResumeRequest(
      this.state.paging.per_page,
      this.state.paging.current_page,
      this.state.queryParams.name,
      this.state.queryParams.jid,
      this.state.queryParams.source,
      this.state.queryParams.status,
      this.deliveryPeriod
    ).then((result) => {
      this.state.jobs = result.jobs;
      this.state.paging = result.data;
      this.state.paging.data.forEach((item) => {
        item.background_check = JSON.parse(item.background_check as string);
        item.job_experience = JSON.parse(item.job_experience as string);
      });

      this.state.paging.data.forEach((item) => {
        if ("亲友介绍" === item.offline) {
          item.source = "亲友介绍";
        }
      });
    });
  }

  manage = [
    {
      prop: "id",
      label: "序号",
    },
    {
      prop: "name",
      label: "姓名",
    },
    {
      prop: "get_job.job_name",
      label: "岗位",
    },
    {
      prop: "create_time",
      label: "时间",
    },
    // {
    //   prop: "reason",
    //   label: "投递原因",
    // },
    // {
    //   prop: "background_check.whether",
    //   label: "背调",
    // },
    {
      prop: "source",
      label: "来源",
    },
    {
      prop: "job_experience[0].time",
      label: "工作经历",
    },
    {
      prop: "phone",
      label: "联系方式",
    },
    {
      prop: "birthday",
      label: "出生日期",
    },
    {
      prop: "get_status.manage_end",
      label: "状态",
    },
    {
      prop: "induction_time",
      label: "入职时间",
    },
  ];

  tableData = [
    {
      serialNumber: "1",
      name: "张三",
      post: "销售",
      time: "2021-03-25",
      deliveryReason: "职位匹配",
      backTone: "接受",
      source: "BOSS",
      workExperience: "有工作经历",
      contactInf: "18798765436",
      birthday: "1998-12-31",
      testScore: "65",
    },
  ];

  labelPosition = "left";

  resumeDrawer = false;
  detailDrawer = false;
  testDrawer = false;

  corporateCultureDrawer = false;

  comeOnDrawer = false;

  interviewFeedbackDrawer = false;

  resumeDetails(type: string, id: number) {
    if (type === "简历详情") {
      this.resumeDrawer = true;
    } else {
      this.detailDrawer = true;
      getDepartShowPhoto(id).then((result) => {
        this.state.shorthand = result.data.reduce((pre, cur) => {
          if (!pre[cur.record_id]) {
            pre[cur.record_id] = [];
          }
          pre[cur.record_id].push(this.apiUrl + "/" + cur.photo);
          return pre;
        }, {});
      });
    }
    this.isMask = true;
    postShowResumeRequest(id).then((result) => {
      this.state.details = result.data.data;
      this.state.details.background_check = JSON.parse(
        this.state.details.background_check as string
      );
      this.state.details.job_experience = JSON.parse(
        this.state.details.job_experience as string
      );
      this.state.details.edu_experience = JSON.parse(
        this.state.details.edu_experience as string
      );
      this.state.details.family_member = JSON.parse(
        this.state.details.family_member as string
      );
      this.state.details.create_time = dateFormat(
        this.state.details.create_time as string
      );
      this.state.details.propaganda = JSON.parse(
        this.state.details.propaganda as any
      );

      this.initFkb(this.state.details);

      this.state.answersTwoDimensionList = [];
      this.loadAnswersTwoDimensionList(result.data.answers_list);
    });

    postListsFeedbackRequest(id).then((result) => {
      this.state.listsFeedback = result.data;
    });

    this.getTestResumeRequest(id);
  }

  /**
   * 装载问答记录二位数组（以第几次面试区分）
   */
  loadAnswersTwoDimensionList(list: any[], howManyTimesInterview?: number) {
    howManyTimesInterview = howManyTimesInterview || 1;

    const tempList = list.filter(
      (item) => item.record_id === howManyTimesInterview
    );
    if (tempList.length > 0) {
      this.state.answersTwoDimensionList.push(tempList);
    }

    const leftover = list.filter(
      (item) => item.record_id !== howManyTimesInterview
    );
    if (leftover.length > 0) {
      this.loadAnswersTwoDimensionList(leftover, ++howManyTimesInterview);
    }
  }

  testDetailsName = "";
  testDetails(id: number, name: string, item: any) {
    this.state.currentDetails = item;
    this.isAnswerQuestion = item.expansion.find((item) => {
      if (item.pivot.answer) return item;
    });

    this.testDrawer = true;
    this.testDetailsName = name;

    this.getTestResumeRequest(id);
  }

  getTestResumeRequest(id: number) {
    postTestResumeRequest(id).then((result) => {
      this.state.questionCount = result.data.data.filter(
        (item) => item.cid
      ).length;
      this.state.questionType = result.data.question_type;
      this.state.score = result.data.score;
      this.state.total_score = result.data.total_score;
      this.state.testDetailsList = result.data.data;

      const tempTestDetailsListOrdinary = [];
      const tempTestDetailsListLogic = [];
      const tempTestDetailsListCharacter = [];
      for (const item of this.state.testDetailsList) {
        if (item.eid) {
          tempTestDetailsListOrdinary.push(item);
        } else if (item.sid && item.get_special) {
          if (item.get_special.cat === "性格题") {
            tempTestDetailsListCharacter.push(item);
          } else if (item.get_special.cat === "逻辑题") {
            tempTestDetailsListLogic.push(item);
          }
        }
      }
      this.state.testDetailsListOrdinary = tempTestDetailsListOrdinary;
      this.state.testDetailsListLogic = tempTestDetailsListLogic;
      this.state.testDetailsListLogic.forEach((item) => {
        if (item.get_special) {
          item.get_special.content = JSON.parse(
            item.get_special.content as any
          );
        }
      });
      this.state.testDetailsListCharacter = tempTestDetailsListCharacter;
      this.state.testDetailsListCharacter.forEach((item) => {
        if (item.get_special) {
          item.get_special.content = JSON.parse(
            item.get_special.content as any
          );
        }
      });

      let logicScore = 0;
      this.state.testDetailsListLogic.forEach((item) => {
        logicScore += +(item.get_special?.content?.filter(
          (i) => i.optionName === item.answer
        )[0].score as number);
      });
      this.state.logicScore = logicScore;
    });
  }

  corporateCulture(id: number) {
    this.isMask = true;
    this.corporateCultureDrawer = true;
    postShowResumeRequest(id).then((result) => {
      this.state.details = result.data.data;
      this.state.details.background_check = JSON.parse(
        this.state.details.background_check as string
      );
      this.state.details.job_experience = JSON.parse(
        this.state.details.job_experience as string
      );
      this.state.details.edu_experience = JSON.parse(
        this.state.details.edu_experience as string
      );
      this.state.details.family_member = JSON.parse(
        this.state.details.family_member as string
      );
      this.state.details.create_time = dateFormat(
        this.state.details.create_time as string
      );
      this.state.details.propaganda = JSON.parse(
        this.state.details.propaganda as any
      );
    });
  }

  savePdf() {
    this.isShowPdfButton = false;
    html2pdf("jlxq", this.state.details.name + "-简历").then((_) => {
      this.isShowPdfButton = true;
    });
  }

  savePdfTest() {
    this.isShowPdfButton = false;
    html2pdf("csxq", this.testDetailsName + "-测试详情").then((_) => {
      this.isShowPdfButton = true;
    });
  }

  resumeDelete(index: number, row: any) {
    postDelResumeRequest(row.id).then((_) => {
      ElMessage.success({
        message: "删除成功！",
        type: "success",
      });
      this.getListsResume();
    });
  }

  induction(index: number, row: any) {
    this.comeOn(row.id);
  }

  noInduction() {
    postNoInduction(
      this.state.currentInductionRow.id,
      this.state.notOnDutyReason
    ).then((result) => {
      if (result.code === 200) {
        ElMessage.success({
          message: result.msg,
          type: "success",
        });
        this.isReasonForNotOnDuty = false;
        this.state.notOnDutyReason = "";
        this.showYesOrNo = "";
        this.getListsResume();
      } else {
        ElMessage.error(result.msg);
      }
    });
  }

  // noInduction(index: number, row: any) {
  //   postNoInduction(row.id).then((result) => {
  //     if (result.code == 200) {
  //       ElMessage.success({
  //         message: "操作成功！",
  //         type: "success",
  //       });
  //       this.getListsResume();
  //     }
  //   });
  // }

  formatter(row: IResume, column: any) {
    switch (column.property) {
      case "create_time":
        return dateFormat(row.create_time as string);

      case "background_check.whether":
        return (row.background_check as any).whether == 1 ? "接受" : "不接受";

      case "job_experience[0].time":
        return row.job_experience
          ? (row.job_experience[0] as any).time.length > 0
            ? "有工作经历"
            : "实习生"
          : "实习生";

      default:
        return (row as any)[column.property];
    }
  }

  search() {
    if (
      this.state.queryParams.createTime &&
      (this.state.queryParams.createTime as any).length > 0
    ) {
      this.deliveryPeriod =
        dateFormat((this.state.queryParams.createTime as any)[0]) +
        " " +
        dateFormat((this.state.queryParams.createTime as any)[1]);
    }

    this.state.paging.current_page = 1;
    this.getListsResume();
  }

  reset() {
    this.state.queryParams.name = undefined;
    this.state.queryParams.jid = undefined;
    this.state.queryParams.source = undefined;
    this.state.queryParams.createTime = undefined;
    this.deliveryPeriod = undefined;
    this.state.queryParams.status = undefined;
    this.state.paging.current_page = 1;
    this.getListsResume();
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsResume();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsResume();
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  datePickerChange(event: any) {
    if (!event) {
      this.deliveryPeriod = undefined;
    }
  }

  closeDrawer() {
    this.state.feedbackList = {};
    this.state.preliminaryTest = {};
    this.state.retest = null;
  }

  operate(id: number, status: string) {
    let next;
    switch (status) {
      case "待筛选":
        next = "待录用";
        break;
      case "待录用":
        next = "待入职";
        break;
      case "待入职":
        this.comeOn(id);
        return;
      default:
        next = "已入职";
        break;
    }

    postStatusResumeRequest(id, next).then((result) => {
      ElMessage.success({
        message: result.msg,
        type: "success",
      });
      this.getListsResume();
    });
  }

  failed(id: number, value: string) {
    postStatusResumeRequest(id, value).then((result) => {
      ElMessage.success({
        message: result.msg,
        type: "success",
      });
      this.getListsResume();
    });
  }

  comeOn(id: number) {
    this.isMask = true;
    this.comeOnDrawer = true;
    this.state.comeOnData = {
      id: undefined,
      oa_department: undefined,
      oa_job: undefined,
      oa_user_company: undefined,
      oa_address: undefined,
      did: undefined,
      address: undefined,
      idCard: undefined,
      birthStatus: undefined,
      email: undefined,
      nickname: undefined,
    };

    this.state.comeOnData.id = id;

    postBoardingResumeRequest().then((result) => {
      this.state.boardingResume = result.data;
    });
  }

  interviewFeedback(id: number) {
    this.isMask = true;
    this.isAddFeedback = false;
    this.interviewFeedbackDrawer = true;
    this.state.rid = id;

    postListsFeedbackRequest(id).then((result) => {
      this.state.listsFeedback = result.data;
    });
  }

  comeOnDataOk() {
    const valiList = [];
    for (const key in this.state.comeOnData) {
      if (key === "oa_dep_group") {
        continue;
      }
      valiList.push((this.state.comeOnData as any)[key]);
    }

    if (!existValidation(valiList)) {
      alert("信息填写不完整。");
      return;
    }

    if (
      !isCardNo(this.state.comeOnData.idCard as string) ||
      !isEmail(this.state.comeOnData.email as string)
    ) {
      return;
    }
    postConfirmResumeRequest(this.state.comeOnData, 5).then((result) => {
      if (result.code === 200) {
        ElMessage.success({
          message: result.msg,
          type: "success",
        });
        postStatusResumeRequest(
          this.state.comeOnData.id as number,
          "已入职"
        ).then((result) => {
          ElMessage.success({
            message: result.msg,
            type: "success",
          });
          this.comeOnDrawer = false;
          this.getListsResume();
        });
      } else {
        ElMessage.error(result.msg);
      }
    });
  }

  oaAddressChange(id: number) {
    this.state.comeOnData.oa_user_company = undefined;
    const temp = this.state.boardingResume.address.filter(
      (item) => item.id === id
    );
    this.state.comeOnData.address = temp[0].keyword;
  }

  submitFeedback() {
    if (
      !existValidation([
        this.state.feedback.name,
        this.state.feedback.create_time,
        this.state.feedback.merit,
        this.state.feedback.shortcoming,
      ])
    ) {
      ElMessage.error("必填项不能为空！");
      return;
    }

    if ((this.state.feedback.merit?.trim().length as number) > 10) {
      ElMessage.error("优点描述不能超过 10 个字");
      return;
    }

    if ((this.state.feedback.shortcoming?.trim().length as number) > 10) {
      ElMessage.error("不足描述不能超过 10 个字");
      return;
    }

    this.state.feedback.create_time = dateFormat(
      this.state.feedback.create_time as string
    );
    this.state.feedback.rid = this.state.rid;

    if (this.state.feedback.id) {
      // 编辑
      postEditFeedbackRequest(this.state.feedback).then((result) => {
        if (result.code === 200) {
          ElMessage.success({
            message: result.msg,
            type: "success",
          });
          this.interviewFeedbackDrawer = false;
        } else {
          ElMessage.error(result.msg);
        }
      });
    } else {
      // 新增
      postAddFeedbackRequest(this.state.feedback).then((result) => {
        if (result.code === 200) {
          ElMessage.success({
            message: result.msg,
            type: "success",
          });
          this.interviewFeedbackDrawer = false;
        } else {
          ElMessage.error(result.msg);
        }
      });
    }
  }

  addFeedback() {
    this.isAddFeedback = true;
    this.state.feedback = {};
  }

  listsFeedbackDelete(id: number) {
    postDelFeedbackRequest(id).then((result) => {
      if (result.code === 200) {
        ElMessage.success({
          message: result.msg,
          type: "success",
        });

        postListsFeedbackRequest(this.state.rid as number).then((result) => {
          this.state.listsFeedback = result.data;
        });
      } else {
        ElMessage.error(result.msg);
      }
    });
  }

  editFeedback(feedback: IFeedback) {
    this.isAddFeedback = true;
    this.state.feedback = feedback;
  }

  departmentSelectChange(id: number) {
    this.state.comeOnData.oa_dep_group = undefined;
    this.state.comeOnData.oa_job = undefined;
    this.state.selectedDep = this.state.boardingResume.dep.filter(
      (item) => item.id === id
    )[0];
  }

  confirmInductionDate() {
    if (!this.state.inductionDate) {
      alert("入职时间不能为空。");
      return;
    }

    postInduction(
      this.state.currentInductionRow.id,
      dateFormat(this.state.inductionDate)
    ).then((result) => {
      if (result.code === 200) {
        ElMessage.success({
          message: result.msg,
          type: "success",
        });

        this.inductionDialogTableVisible = false;

        this.getListsResume();
      } else {
        ElMessage.error(result.msg);
      }
    });
  }

  confirmReasonText() {
    postRefuseInduction(
      this.state.currentInductionRow.id,
      this.state.notOnDutyReason
    ).then((result) => {
      if (result.code === 200) {
        ElMessage.success({
          message: result.msg,
          type: "success",
        });
        this.isReasonForNotOnDuty = false;
        this.state.notOnDutyReason = "";
        this.showYesOrNo = "";
        this.getListsResume();
      } else {
        ElMessage.error(result.msg);
      }
    });
  }

  openSchoolWebsite(website) {
    window.open(`https://www.baidu.com/s?wd=${website}`);
  }

  // refuseInduction(row: any) {
  //   postRefuseInduction(row.id).then((result) => {
  //     if (result.code === 200) {
  //       ElMessage.success({
  //         message: result.msg,
  //         type: "success",
  //       });

  //       this.getListsResume();
  //     } else {
  //       ElMessage.error(result.msg);
  //     }
  //   });
  // }
}
</script>

<style scoped>
::v-deep .el-table th:first-of-type,
::v-deep .el-table th:last-of-type,
::v-deep .el-table td:first-of-type,
::v-deep .el-table td:last-of-type {
  text-align: center !important;
}
.btn-add {
  width: 100px;
  min-height: 34px;
}
.jurisdiction-box {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}
.btn-edit {
  width: 100%;
  margin-top: 40px !important;
}
::v-deep() .conditional-query {
  display: flex !important;
  flex-wrap: wrap;
  /* margin-top: 30px; */
}
::v-deep().conditional-item {
  margin-right: 20px !important;
  /* width: 20% !important; */
}
::v-deep().el-select {
  width: 100% !important;
}

.resume-name {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.resume-inf {
  font-size: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 400;
}

.resume-inf > div {
  color: #000;
  display: inline-flex;
  align-items: center;
  width: 26%;
  position: relative;
}

.resume-inf > div:nth-child(-n + 3) {
  margin-bottom: 10px;
}
.inf-item-center {
  padding-left: 4%;
  width: 34% !important;
}

.inf-item-center::after {
  content: "/";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.inf-item-center::before {
  content: "/";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.inf-item-right {
  width: 36% !important;
}

.select-reason {
  font-size: 12px;
  /* color: #888; */
  color: #333;
  margin-bottom: 15px;
}

.select-reason > span {
  color: #333;
}

.emergency-name {
  background: #f7f9fc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 12px;
  /* color: #888; */
  color: #333;
}

.emergency-name > span {
  color: #333;
}

.emergency-name > img {
  width: 16px;
  margin-left: 20px;
}

.resume-title,
.resume-title1 {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  color: rgb(185, 185, 185);
}

.resume-title {
  line-height: 40px;
  margin-top: 25px;
  border-bottom: 1px solid #4f81f9;
}

.resume-title1 {
  border-top: 14px solid #4f81f9;
  padding-top: 25px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #ccc;
}

.resume-title > .resume-text {
  color: #333;
  margin-right: 20px;
  font-weight: 600;
  border-bottom: 2px solid #4f81f9;
}
.resume-title1 > .resume-text {
  color: #333;
  margin-right: 20px;
  font-weight: 600;
}

.resume-subtitle {
  margin-left: 20px;
  color: #333;
}
.job-intention {
  color: #000;
  font-weight: 400;
  margin-left: calc(20 / 20 * 1rem);
}

.job-intention > span {
  font-size: calc(14 / 20 * 1rem);
}

.resume-img {
  height: 26px;
}

.inf-box {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  color: #888;
}

.inf-box .inf-item {
  width: 33.33%;
  margin-bottom: 15px;
  color: #333;
}

.inf-box .inf-item > span {
  color: #333;
}

.hobby-box {
  font-size: 12px;
}

.hobby-name {
  /* color: #888; */
  color: #333;
  margin-bottom: 10px;
}

.hobby-name:last-child {
  margin-bottom: 0;
}

.hobby-name > span {
  color: #333;
}

.education-box {
  color: #888;
}

.education-box > span {
  color: #333;
  margin-right: 20px;
}

.education-list {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  margin-top: 10px;
  font-weight: 600;
}

.education-list-left {
  width: 44%;
}

.education-list-center {
  width: 28%;
}

.education-list-right {
  width: 28%;
  text-align: right;
}

.website-btn {
  color: #4f81f9;
  cursor: pointer;
}

.job-box {
  margin-top: 25px;
}

.job-box:first-child {
  margin-top: 0;
}

/* .job-box:not(:first-child) {
  margin-top: 25px;
} */

.job-company {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.job-company .company-name {
  font-size: 14px;
  color: #333;
  margin-right: 10px;
}

.job-company .job-name {
  color: #4f81f9;
  margin-right: 10px;
}

.job-company .job-time {
  color: #888;
}

.job-inf {
  display: flex;
  align-items: center;
  font-size: 12px;
  /* color: #888; */
  color: #333;
  margin-top: 15px;
}

.job-inf > div {
  width: 33.33%;
}

.job-inf > div:last-of-type {
  margin-right: 0;
}

.job-inf > div > span {
  color: #333;
}

.job-reason {
  display: flex;
  margin-top: 15px;
  font-size: 12px;
}

.job-reason-left {
  /* color: #888; */
  color: #333;
}

.job-reason-right {
  color: #333;
  display: inline-flex;
  flex-wrap: wrap;
  flex: 1;
}

.family-member {
  padding-bottom: 15px;
}

.family-member:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.member-inf {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}

.member-inf > span {
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
}

.member-inf > span:last-of-type {
  margin-right: 0;
}

.member-inf > span > img {
  width: 16px;
  margin-right: 4px;
}

.member-address {
  display: flex;
  margin-top: 10px;
  font-size: 12px;
}

.member-address > img {
  margin-right: 2px;
  width: 14px;
}

.resume-soure {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  /* color: #888; */
  color: #333;
}

.resume-soure > div {
  width: 33.33%;
}

.resume-soure > div > span {
  color: #333;
}

.not-accepting {
  display: flex;
  font-size: 12px;
  color: #888;
  margin-top: 15px;
}

.not-accepting > div {
  color: #333;
  flex: 1;
  flex-wrap: wrap;
}

.resume-name-text {
  position: absolute;
  left: 50%;
  top: 5px;
  transform: translateX(-50%);
}
.resume-name-text2 {
  position: absolute;
  left: 50%;
  top: -18px;
  color: pink;
  transform: translateX(-50%);
}
.title-text {
  color: #4f81f9;
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.resume-box {
  padding: 20px 25px;
}
.label-box {
  display: flex;
}
.blue-label {
  background: #4f81f9;
  border-radius: 0 14px 0 14px;
  font-size: 14px;
  color: #fff;
  padding: 4px 20px;
  position: relative;
  left: -25px;
}
.flex-start {
  display: flex;
  font-size: 12px;
  margin-top: 5px;
}
.flex-start-left {
  color: #888;
  width: 100px;
}
.flex-start-right {
  color: #333;
  word-break: break-all;
  flex: 1;
}
.education-inf {
  margin-top: 5px;
  font-size: 14px;
  color: #888;
}
.right-margin {
  margin-right: 40px;
}
::v-deep().iconfont {
  margin-right: 5px;
}
.expect-box {
  margin-bottom: 16px;
}
.expect-box:last-of-type {
  margin-bottom: 0;
}

.expect-inf {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 12px;
}

.expect-inf > div {
  width: 50%;
}
.expect-title {
  font-size: 12px;
  /* color: #888; */
  color: #333;
}
.expect-content {
  background: #f7f9fc;
  margin-top: 12px;
  color: #333;
  font-size: 12px;
  padding: 10px;
  border-radius: 4px;
}
.promotion-position {
  color: #4f81f9;
  font-weight: 600;
}
.personal-flex {
  display: flex;
  align-items: center;
  width: 50%;
  margin-top: 20px;
  font-size: 14px;
}
.personal-title {
  color: #888;
}
.personal-details {
  color: #333;
  width: 70px;
  flex: 1;
}
.fullFlex {
  width: 100% !important;
}
.emergency-contact {
  display: flex;
  background: #fff;
  padding: 15px;
  margin-top: 16px;
}
.emergency-contact .personal-flex {
  margin-top: 0;
}
.margin-top {
  margin-top: 20px;
  margin-bottom: 20px;
}
.resume-btn {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px;
}
.resume-btn .btn-edit {
  margin-top: 5px !important;
}
.personal-name {
  font-size: 20px;
  color: #333;
  font-weight: 400;
  margin-bottom: 20px;
}
.personal-name > span {
  margin-right: 15px;
}
.resume-source {
  margin-top: 0;
  margin-bottom: 20px;
}
.margin-top0 {
  margin-top: 0;
}
.personal-inf .personal-flex:nth-of-type(1),
.personal-inf .personal-flex:nth-of-type(2) {
  margin-top: 0;
}
::v-deep().btn-printing {
  background: #fff !important;
  color: #4f81f9 !important;
  border: 1px solid #4f81f9 !important;
}
.test-box {
  padding: 20px;
}
.test-number {
  font-size: 14px;
}
.test-title {
  font-size: 14px;
  color: #333;
  text-indent: 2em;
  margin-top: 25px;
}
.test-details {
  background: #f8f8f8;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.test-result {
  margin-top: 20px;
}
.result-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  font-size: 14px;
}
.result-active {
  border: 1px solid #4f81f9;
  color: #4f81f9;
  border-radius: 4px;
}
.result-active-error {
  background: #ff5656;
  color: #fff;
}
.test-analysis-box {
  margin-top: 20px;
  background: #fff;
  padding: 20px;
  font-size: 14px;
}
.test-analysis {
  margin-top: 20px;
}

::v-deep() .el-table .cell {
  display: flex;
  flex-wrap: wrap;
}

.mask {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
}

.no-overflow {
  overflow-y: unset !important;
}

.no-overflow ::v-deep() .el-drawer__body {
  overflow-y: unset !important;
}

::v-deep() .el-range-editor--mini.el-input__inner {
  width: 224px;
  height: 36px;
}

.array-button {
  padding: 6px !important;
}
.el-checkbox {
  margin-bottom: unset;
}

::v-deep() .el-checkbox > .el-checkbox__input {
  display: inline-block !important;
  position: relative;
  white-space: nowrap;
}

.corporate-culture-drawer-item {
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
  border-radius: 4px;
  height: 40px;
}

.item-title {
  font-size: 14px;
  margin-bottom: 10px;
  /* margin-top: 20px; */
}

.selected-item {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}

.feedback-item {
  background-color: #f7f9fc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 6px 20px;
  border-radius: 4px;
}

.feedback-item span {
  flex: 1;
}

.comment-stamp {
  position: absolute;
  top: 15px;
  width: 140px;
  height: 62px;
  z-index: 10;
  transform: rotate(-15deg);
}
.comment-stamp:nth-child(1) {
  right: -10px;
}

.comment-stamp:nth-child(2) {
  right: 135px;
}
.comment-stamp:nth-child(3) {
  right: 280px;
}

.comment-img {
  width: 100%;
  height: 100%;
  opacity: 0.7;
}

.comment-advantage {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 14px;
  right: 0;
  top: 8px;
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.9);
  text-align: center;
  z-index: 10;
}

.comment-insufficient {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 22px;
  right: 0;
  top: 28px;
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.9);
  text-align: center;
  z-index: 10;
}

.reviewer {
  font-size: calc(12 / 14 * 1rem);
  -webkit-transform-origin-x: 0;
  /* -webkit-transform: scale(0.8); */
  color: #fff;
  transform: rotate(-20deg) scale(0.8);
  transform-origin: 50% 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -22px;
  width: 100%;
  height: 100%;
  margin-left: -61px;
  z-index: 9;
}
.el-button {
  margin: 3px 6px;
  min-width: 62px;
}
::v-deep() .el-range-editor--mini .el-range-separator {
  line-height: 28px;
}

.form-title {
  font-size: calc(22 / 20 * 1rem);
  text-align: center;
  font-weight: 600;
  margin: calc(40 / 20 * 1rem) 0 calc(30 / 20 * 1rem);
}
.table tbody tr td {
  border: 1px solid #c0c0c0;
  padding: calc(15 / 20 * 1rem) calc(10 / 20 * 1rem);
  font-size: calc(16 / 20 * 1rem);
  font-weight: 500;
  text-align: center;
}
.interview-box {
  border-bottom: 1px solid #c0c0c0;
  padding: calc(15 / 20 * 1rem) calc(10 / 20 * 1rem);
}
.interview-box:last-child {
  border-bottom: 0;
}
.interview-title {
  font-size: calc(16 / 20 * 1rem);
  color: #000;
}
.interview-details {
  display: flex;
  margin-top: calc(20 / 20 * 1rem);
}
.details-left {
  color: #999;
}
.details-right {
  color: #333;
  flex: 1;
  flex-wrap: wrap;
}

.shorthand-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 10px;
  margin: 10px 0 10px;
}

.shorthand-box .el-image-fill {
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.shorthand-box .el-image-fill .el-image {
  position: absolute;
  height: 100%;
  width: 100%;
}

.continer-box {
  background-color: #f7f9fc;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.interview-shorthand-box {
  background-color: #fff;
  margin: 15px 15px;
  padding: 0 15px;
  border-radius: 10px;
  overflow: hidden;
}

.interview-shorthand {
  color: #4f81f9;
  font-size: calc(27 / 20 * 1rem);
  margin-top: calc(30 / 20 * 1rem);
}

.image-delete {
  position: absolute;
  top: -10px;
  right: -8px;
  width: 18px;
  height: 18px;
  display: flex;
  background-color: red;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.table-text {
  display: inline-block;
  width: 40%;
  text-align: center;
  color: #000;
  font-size: calc(16 / 20 * 1rem);
}
.color999 {
  color: #999;
}

.question-title {
  border-radius: calc(16 / 20 * 1rem);
  padding: calc(18 / 20 * 1rem) calc(36 / 20 * 1rem);
  margin-bottom: calc(10 / 20 * 1rem);
  color: #fff;
  background-color: #4f81f9;
  font-size: 14px;
}

.not-question {
  background-color: rgba(170, 170, 170, 1);
}

.answer-box {
  display: flex;
  font-size: 14px;
}

.item-text {
  padding: 0 calc(36 / 20 * 1rem);
}

.change-left {
  margin-top: 0;
}

.job-understanding {
  width: 100% !important;
  margin-top: 10px;
}

.test-harvest {
  background: unset;
  color: #333;
}
</style>                  