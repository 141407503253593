<template>
  <div class="right-content-box">
    <!-- <el-form
      ref="form"
      :model="state.queryParams"
      label-width="60px"
      :label-position="labelPosition"
      class="conditional-query"
    >
      <el-form-item label="题型" class="conditional-item">
        <el-select
          size="mini"
          v-model="state.queryParams.cat"
          placeholder="请选择"
        >
          <el-option :value="undefined" :label="'全部'"></el-option>
          <el-option :label="'性格题'" :value="'性格题'"></el-option>
          <el-option :label="'逻辑题'" :value="'逻辑题'"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="题目" class="conditional-item">
        <el-input
          size="mini"
          v-model="state.queryParams.question"
          placeholder="请输入关键字"
        ></el-input>
      </el-form-item>
      <el-button type="primary" class="btn-add" @click="search()">
        搜索
      </el-button>
      <el-button type="primary" class="btn-add" @click="reset()">
        重置
      </el-button>
    </el-form> -->

    <div class="add-box">
      <el-button @click="postAdd()" type="primary" class="btn-add">
        添加
      </el-button>
    </div>

    <!-- 表格 -->
    <el-table
      :data="state.paging.data"
      fixed
      style="width: 100%"
      class="manage-box"
    >
      <el-table-column label="序号" type="index" width="150" />
      <el-table-column label="ID" prop="id" width="150" />
      <el-table-column label="题目" prop="title"></el-table-column>
      <el-table-column label="操作" width="200">
        <template #default="scope">
          <el-button
            type="primary"
            size="mini"
            @click="postEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除数据吗？"
            @confirm="postDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="state.paging.total"
      class="paging"
    >
    </el-pagination>

    <!-- 添加、编辑抽屉 -->
    <el-drawer
      :title="title"
      v-model="drawer"
      :modal="false"
      :before-close="handleClose"
    >
      <div class="form-box">
        <el-form
          ref="form"
          :model="state.special"
          :label-position="labelPosition"
        >
          <div class="question-box">
            <div class="question-text">问题</div>
            <el-form-item>
              <el-input
                type="textarea"
                v-model="state.expand.expandQuestion"
                placeholder="输入问题"
                class="input-textarea"
              ></el-input>
            </el-form-item>
          </div>
          <div class="question-box">
            <div class="question-text">答案</div>
            <el-form-item>
              <el-input
                type="textarea"
                v-model="state.expand.expandAnswers"
                placeholder="输入答案"
                class="input-textarea"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
        <el-button
          v-noRepetition
          type="primary"
          class="btn-edit"
          @click="submitForm(btnTitle)"
        >
          {{ btnTitle }}
        </el-button>
      </div>
    </el-drawer>
    <div v-if="isMask" class="mask"></div>
  </div>
</template>

<script lang="ts">
import { titleMixin } from "../../mixins/titleMixin";
import { Options, Vue } from "vue-class-component";
import { reactive } from "@vue/reactivity";
import { IPaging, ISpecial, ISpecialContent, IexpandQuestion } from "@/typing";
import {
  getListsExpandRequest,
  postAddExpandRequest,
  postDeleteExpandRequest,
} from "@/apis/requests/api";
import { ElMessage } from "element-plus";
import { cloneDeep, existValidation } from "@/share/util";
import { environment } from "@/environments/environments";

interface QueryParams {
  cat?: string;
  question?: string;
}

@Options({
  mixins: [titleMixin],
})
export default class ExpandQuestionBank extends Vue {
  isMask = false;
  apiUrl = environment.apiUrl;

  letterSequences = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

  state = reactive<{
    queryParams: QueryParams;
    paging: IPaging<any>;
    special: ISpecial;
    expand: IexpandQuestion;
    questionList: { question: string; id: number }[];
  }>({
    paging: { data: [] },
    special: {
      content: [
        { optionName: "A", optionContent: "", analysis: "", score: undefined },
      ],
      fileList: [],
    },
    expand: {
      expandQuestion: "",
      expandAnswers: "",
    },
    questionList: [],
    queryParams: {},
  });

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  postAdd() {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加题库";
    this.btnTitle = "添加";
    this.state.expand.id = null;
    this.state.expand.expandQuestion = "";
    this.state.expand.expandAnswers = "";
  }

  postEdit(index: number, expand: IexpandQuestion) {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑题库";
    this.btnTitle = "确认修改";

    let cloneItem = cloneDeep(expand);
    this.state.expand.id = cloneItem.id;
    this.state.expand.expandQuestion = cloneItem.title;
    this.state.expand.expandAnswers = cloneItem.reference_answer;
  }

  created() {
    this.getListsExpand();
  }

  getListsExpand() {
    getListsExpandRequest(
      this.state.paging.per_page,
      this.state.paging.current_page
    ).then((result) => {
      this.state.paging = result.data;
      console.log("result", result);
    });
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsExpand();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsExpand();
  }

  // 删除数据
  postDelete(index: number, expand: ISpecial) {
    postDeleteExpandRequest(expand.id as number).then((result) => {
      if (result.code === 403) {
        ElMessage.error(result.msg);
      } else {
        ElMessage.success({
          message: "删除成功！",
          type: "success",
        });
      }
      this.drawer = false;
      this.getListsExpand();
    });
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  submitForm(btnTitle: string) {
    this.isMask = false;

    if (
      !existValidation([
        this.state.expand.expandQuestion,
        this.state.expand.expandAnswers,
      ])
    ) {
      ElMessage.error("字段未填写完整");
      return;
    }

    if (btnTitle === "添加") {
      postAddExpandRequest(this.state.expand).then((_) => {
        ElMessage.success({
          message: "创建成功！",
          type: "success",
        });
        this.drawer = false;
        this.getListsExpand();
      });
    } else {
      postAddExpandRequest(this.state.expand).then((_) => {
        ElMessage.success({
          message: "修改成功！",
          type: "success",
        });
        this.drawer = false;
        this.getListsExpand();
      });
    }
  }

  search() {
    this.state.paging.current_page = 1;
    this.getListsExpand();
  }

  reset() {
    this.state.queryParams.cat = undefined;
    this.state.queryParams.question = undefined;
    this.state.paging.current_page = 1;
    this.getListsExpand();
  }
}
</script>

<style scoped>
::v-deep .el-table th:first-of-type,
::v-deep .el-table th:last-of-type,
::v-deep .el-table td:first-of-type,
::v-deep .el-table td:last-of-type {
  text-align: center !important;
}
.add-box {
  text-align: right;
}
.btn-add {
  width: calc(100 / 14 * 1rem);
  min-height: calc(34 / 14 * 1rem);
}
.btn-edit {
  width: 100%;
  margin-top: 10px;
}
.jurisdiction-title {
  font-size: 14px;
  margin-bottom: calc(20 / 14 * 1rem);
  margin-top: calc(10 / 14 * 1rem);
}
::v-deep().el-select {
  width: 100%;
}
::v-deep().input-textarea textarea {
  /* height: 80px !important; */
  min-height: 200px !important;
}
.question-box {
  border-radius: 6px;
  padding: 0 calc(20 / 14 * 1rem) calc(20 / 14 * 1rem);
  background: #f8f8f8;
  overflow: hidden;
}

::v-deep() .el-upload {
  width: 100%;
}

::v-deep() .el-upload-dragger {
  width: 100%;
}

.mask {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
}

::v-deep() .conditional-query {
  display: flex !important;
  flex-wrap: wrap;
  /* margin-top: 30px; */
}
::v-deep().conditional-item {
  margin-right: 20px !important;
  /* width: 20% !important; */
}
.btn-add {
  width: 100px;
  min-height: 34px;
}

.question-text {
  font-size: calc(17 / 14 * 1rem);
  margin: calc(10 / 14 * 1rem) 0;
}
</style>