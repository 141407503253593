<template>
  <!-- <div v-if="questionAnswerList && questionAnswerList.length > 0">
    <div class="title">
      {{ numberToZh(questionAnswerList[0].record_id) }}面问答记录
    </div>
    <div
      class="question-box"
      v-for="(item, index) in questionAnswerList"
      :key="item.id"
    >
      <div class="Title">
        <div class="Title-left">
          问题 {{ index + 1 }} ({{ item.get_type.department }})
        </div>
      </div>
      <div class="question-title">
        {{ item.question }}
      </div>
      <div class="answer-text">{{ item.answer }}</div>
    </div>
  </div> -->
  <div class="continer-box">
    <div class="question" v-if="questionAnswerList && questionAnswerList.length > 0">
      <div class="title">
        {{ numberToZh(questionAnswerList[0].record_id) }}面问答记录
      </div>
      <div
        class="question-box"
        v-for="(item, index) in questionAnswerList"
        :key="item.id"
      >
        <div class="Title">
          <div class="Title-left">
            问题 {{ index + 1 }} &nbsp;&nbsp;·&nbsp;&nbsp;{{
              item.get_type.department
            }}
          </div>
          <!-- <div class="Title-right">
          <el-button @click="$emit('EditClick', item)">
            <img src="@/assets/img/icon_bianji.png" alt="" class="btn-img" />
            编辑
          </el-button>
          <el-button @click="$emit('DeleteClick', item.id)">
            <img src="@/assets/img/icon_shanchu.png" alt="" class="btn-img" />
            删除
          </el-button>
        </div> -->
        </div>
        <div class="question-answer">
          <div class="question-title">
            <span class="question-title-left"> 问：</span>
            <span class="question-title-right">{{ item.question }} </span>
          </div>
          <div class="question-title">
            <span class="question-title-left"> 答：</span>
            <span class="question-title-right">{{ item.answer }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IQuestionAnswer } from "@/typing";
import { Options, Vue } from "vue-class-component";

const numberToZhMap = {
  "1": "一",
  "2": "二",
  "3": "三",
  "4": "四",
  "5": "五",
  "6": "六",
};

@Options({
  props: {
    questionAnswerList: Object,
  },
  emits: ["DeleteClick", "EditClick"],
  computed: {
    numberToZh() {
      return (num: number) => {
        let zh = numberToZhMap[num.toString()] || "未知";
        return zh;
      };
    },
  },
})
export default class QuestionRecord extends Vue {
  questionAnswerList: IQuestionAnswer[];
}
</script>

<style scoped>
.title {
  color: #4f81f9;
  font-size: calc(27 / 20 * 1rem);
  margin-top: calc(20 / 20 * 1rem);
}

.question-box {
  position: relative;
  margin-top: calc(25 / 20 * 1rem);
  margin-bottom: 20px;
  background: #f7f9fc;
  border-radius: calc(10 / 20 * 1rem);
}

.Title {
  padding: calc(15 / 20 * 1rem) calc(20 / 20 * 1rem);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #e9e9e9;
}

.Title-left {
  font-size: calc(20 / 20 * 1rem);
  color: #4f81f9;
  font-weight: 600;
}

.question-answer {
  padding: calc(20 / 20 * 1rem);
}

.question-title {
  margin-top: calc(15 / 20 * 1rem);
  font-size: calc(20 / 20 * 1rem);
  font-weight: 500;
  display: flex;
}

.question-title-left {
  color: #999;
}

.question-title-right {
  flex: 1;
  flex-wrap: wrap;
}

::v-deep() .el-button {
  background: none !important;
  color: #000 !important;
  border-radius: calc(26 / 20 * 1rem) !important;
  height: calc(24 / 20 * 1rem) !important;
  border: 1px solid #e0e0e0 !important;
  font-size: calc(16 / 20 * 1rem) !important;
  padding: 0 calc(16 / 20 * 1rem);
  min-height: calc(30 / 20 * 1rem) !important;
}

.btn-img {
  width: calc(18 / 20 * 1rem);
  margin-right: calc(4 / 20 * 1rem);
}

::v-deep() .el-button > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.continer-box {
  background-color: #f7f9fc;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.question {
    background-color: #fff;
    margin: 15px 15px;
    padding: 0 15px;
    border-radius: 10px;
    overflow: hidden;
}
/* .title {
  color: #4f81f9;
  font-size: calc(22 / 20 * 1rem);
  margin-top: calc(30 / 20 * 1rem);
}
.question-box {
  position: relative;
  margin-top: calc(15 / 20 * 1rem);
}
.question-box::after {
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
    270deg,
    rgba(79, 129, 249, 0) 0%,
    #4f81f9 49%,
    rgba(79, 129, 249, 0) 100%
  );
}
.Title {
  margin-top: calc(15 / 20 * 1rem);
  padding-top: calc(15 / 20 * 1rem);
  display: flex;
  justify-content: space-between;
}
.Title-left {
  font-size: calc(20 / 20 * 1rem);
  color: #000;
  font-weight: 600;
}
.question-title {
  margin-top: calc(15 / 20 * 1rem);
  font-size: calc(20 / 20 * 1rem);
  font-weight: 500;
}
.answer-text {
  background: #fff;
  border-radius: calc(8 / 20 * 1rem);
  padding: calc(15 / 20 * 1rem);
  margin-top: calc(15 / 20 * 1rem);
}
::v-deep() .el-button {
  background: none !important;
  color: #000 !important;
  border-radius: calc(26 / 20 * 1rem) !important;
  height: calc(20 / 20 * 1rem) !important;
  border: 1px solid #e0e0e0 !important;
  font-size: calc(14 / 20 * 1rem) !important;
  padding: 0 calc(12 / 20 * 1rem);
}
.btn-img {
  width: calc(14 / 20 * 1rem);
  margin-right: calc(4 / 20 * 1rem);
}
::v-deep() .el-button > span {
  display: flex;
  align-items: center;
  justify-content: center;
} */
</style>