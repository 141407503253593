<template>
  <div class="right-content-box">
    <div class="code-box">
      每日验证码(每日凌晨6点更新）<span class="code-number" id="code">{{
        state.homeIndex?.codes
      }}</span>
      <textarea class="temp-input" id="temp-input"></textarea>
      <div class="btn-copy" @click="copyText()">复制</div>
    </div>
    <div class="data-box">
      <div class="data-item resume">
        <div class="data-num">
          {{ state.homeIndex?.resumes }}/{{ state.homeIndex?.resumes_today }}
        </div>
        <div class="data-title">应聘简历(总/新)</div>
      </div>

      <div class="data-item post">
        <div class="data-num">
          {{ state.homeIndex?.jobs }}
        </div>
        <div class="data-title">岗位数量</div>
      </div>

      <div class="data-item question-bank">
        <div class="data-num">
          {{ state.homeIndex?.exams }}
        </div>
        <div class="data-title">题库数量</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { postIndexRequest } from "@/apis/requests/api";
import { IHomeIndex } from "@/typing";
import { reactive } from "@vue/reactivity";
import { ElMessage } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";

@Options({
  mixins: [titleMixin],
})
export default class Home extends Vue {
  state = reactive<{ homeIndex?: IHomeIndex }>({});

  created() {
    postIndexRequest().then((result) => {
      this.state.homeIndex = result.data;
    });
  }

  datas = [
    {
      num: "1599/66",
      title: "应聘简历(总/新)",
      classStyle: "",
    },
    {
      num: 6,
      title: "",
      classStyle: "",
    },
    {
      num: 600,
      title: "",
      classStyle: "",
    },
  ];

  copyText() {
    const code = (document.getElementById("code") as HTMLElement).innerText;
    const tempInput = document.getElementById("temp-input") as any;
    tempInput.value = code;
    tempInput.select();
    document.execCommand("copy");
    ElMessage.success({
      message: "复制成功",
      type: "success",
    });
  }
}
</script>

<style scoped>
.code-box {
  background: #fff;
  border-radius: calc(8 / 14 * 1rem);
  padding: calc(20 / 14 * 1rem);
  display: flex;
  align-items: center;
  font-size: calc(16 / 14 * 1rem);
  margin-top: calc(10 / 14 * 1rem);
}
.code-number {
  font-size: calc(26 / 14 * 1rem);
  color: #4f81f9;
  margin: 0 calc(20 / 14 * 1rem);
}
.btn-copy {
  border: 1px solid #4f81f9;
  font-size: calc(16 / 14 * 1rem);
  padding: calc(8 / 14 * 1rem) calc(30 / 14 * 1rem);
  border-radius: calc(6 / 14 * 1rem);
  color: #4f81f9;
  cursor: pointer;
}
.data-box {
  display: flex;
  justify-content: space-between;
  margin-top: calc(30 / 14 * 1rem);
}
.data-item {
  width: 32%;
  height: calc(165 / 14 * 1rem);
  border-radius: calc(8 / 14 * 1rem);
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.data-num {
  font-size: calc(24 / 14 * 1rem);
}
.data-title {
  font-size: calc(12 / 14 * 1rem);
  margin-top: calc(25 / 14 * 1rem);
  opacity: 0.9;
}
.resume {
  background: linear-gradient(311deg, #736dff 0%, #a97fff 100%);
}
.resume::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(-10 / 14 * 1rem);
  width: 80%;
  height: calc(20 / 14 * 1rem);
  background: linear-gradient(311deg, #5664ff 0%, #a97fff 100%);
  border-radius: 8px;
  filter: blur(calc(20 / 14 * 1rem));
}
.post {
  background: linear-gradient(311deg, #f7c598 0%, #ff8886 100%);
  box-shadow: 0px 2px 5px 0px rgba(83, 97, 255, 0.05);
}
.post::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(-10 / 14 * 1rem);
  width: 80%;
  height: calc(20 / 14 * 1rem);
  background: linear-gradient(311deg, #f7c598 0%, #ff8886 100%);
  border-radius: 8px;
  filter: blur(calc(20 / 14 * 1rem));
}
.question-bank {
  background: linear-gradient(134deg, #44dec5 0%, #4ebcfa 100%);
  box-shadow: 0px 2px 5px 0px rgba(83, 97, 255, 0.05);
}
.question-bank::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(-10 / 14 * 1rem);
  width: 80%;
  height: calc(20 / 14 * 1rem);
  background: linear-gradient(134deg, #44dec5 0%, #4ebcfa 100%);
  border-radius: 8px;
  filter: blur(calc(20 / 14 * 1rem));
}

.temp-input {
  position: fixed;
  left: 200vw;
}
</style>