
import {
  postAddExamRequest,
  postDelExamRequest,
  postEditExamRequest,
  postListsExamRequest,
} from "@/apis/requests/api";
import { cloneDeep, existValidation } from "@/share/util";
import { ICat, IExam, IPaging } from "@/typing";
import { reactive } from "@vue/reactivity";
import { ElMessage } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";
import { environment } from "../../environments/environments";

interface QueryParams {
  cid?: number;
  question?: string;
}

interface CurrentCat {
  id: number;
  name: string;
  sort: number;
}

@Options({
  mixins: [titleMixin],
})
export default class QuestionBankList extends Vue {
  isMask = false;

  apiUrl = environment.apiUrl;

  state = reactive<{
    queryParams: QueryParams;
    paging: IPaging<IExam>;
    exam: IExam;
    listCat: ICat[];
    questionList: { question: string; id: number }[];
    queryCat: CurrentCat[];
  }>({
    paging: { data: [] },
    exam: { fileList: [] },
    listCat: [],
    questionList: [],
    queryParams: {},
    queryCat: [],
  });

  created() {
    this.getListsExam();
  }

  getListsExam() {
    postListsExamRequest(
      this.state.paging.per_page,
      this.state.paging.current_page,
      this.state.queryParams.cid,
      this.state.queryParams.question
    ).then((result) => {
      this.state.paging = result.data;
      this.state.listCat = result.cat;
      this.state.paging.data.forEach((item) => {
        item.fileList = JSON.parse(item.fileList as any);
      });
      this.state.queryCat = result.cat;
    });

    postListsExamRequest(999, 1).then((result) => {
      this.state.questionList = result.data.data.map((item) => {
        return { question: item.question, id: item.id };
      }) as { question: string; id: number }[];
    });
  }

  handleRemove(file: any) {
    this.state.exam.fileList = this.state.exam.fileList?.filter(
      (item) => item.url !== file.url
    );
  }

  handleSuccess(
    thumbnail: any,
    file: any,
    fileList: { name: string; response: string; url: string }[]
  ) {
    setTimeout(() => {
      this.state.exam.fileList = fileList.map((item) => {
        return {
          name: item.name,
          url: item.response || item.url,
        };
      });
    }, 2000);
  }

  // 表头
  manage = [
    {
      prop: "id",
      label: "序号",
      width: "140",
    },
    {
      prop: "get_cat.name",
      label: "题型",
      width: "140",
    },
    {
      prop: "question",
      label: "题目",
    },
    {
      prop: "fileList",
      label: "缩略图",
    },
    {
      prop: "answer",
      label: "答案",
      width: "140",
    },
    {
      prop: "analysis",
      label: "解析",
    },
  ];

  // 表格
  tableData = [
    {
      serialNumber: "1",
      id: "00001",
      questionType: "人文",
      subject: "三只小松鼠一只没耳朵一只没尾巴",
      answer: "C",
      analysis: "其中两只可以先过河，过河后",
    },
  ];

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  // 新增题库
  questionBankAdd() {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加题库";
    this.btnTitle = "添加";

    this.state.exam = { fileList: [] };
  }

  // 编辑题库
  questionBankEdit(index: number, exam: IExam) {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑题库";
    this.btnTitle = "确认修改";

    this.state.exam = cloneDeep(exam);
    if (!this.state.exam.fileList) {
      this.state.exam.fileList = [];
    }
  }

  // 删除行数据
  questionBankDelete(index: number, row: any) {
    postDelExamRequest(row.id).then((result) => {
      if (result.code === 403) {
        ElMessage.error(result.msg);
      } else {
        ElMessage.success({
          message: "删除成功！",
          type: "success",
        });
      }
      this.drawer = false;
      this.getListsExam();
    });
  }

  submitForm(btnTitle: string) {
    this.isMask = false;
    console.log(this.state.exam);

    if (
      !existValidation([
        this.state.exam.cid,
        this.state.exam.question,
        this.state.exam.answer,
        this.state.exam.option1,
        this.state.exam.option2,
        this.state.exam.option3,
        this.state.exam.option4,
        this.state.exam.analysis,
      ])
    ) {
      ElMessage.error("字段未填写完整");
      return;
    }

    for (const item of this.state.questionList) {
      if (
        item.id != this.state.exam.id &&
        item.question === this.state.exam.question
      ) {
        ElMessage.error("题目已存在");
        return;
      }
    }

    if (btnTitle === "添加") {
      postAddExamRequest(this.state.exam).then((_) => {
        ElMessage.success({
          message: "创建成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.exam = { fileList: [] };
        this.getListsExam();
      });
    } else {
      postEditExamRequest(this.state.exam).then((_) => {
        ElMessage.success({
          message: "修改成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.exam = { fileList: [] };
        this.getListsExam();
      });
    }
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsExam();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsExam();
  }

  beforeAvatarUpload(file: any) {
    if (file.type != "image/jpeg" && file.type != "image/png") {
      ElMessage.error("只能上传 jpg/png 文件");
      return false;
    }
    if (file.size / 1024 / 1024 > 2) {
      ElMessage.error("上传文件大小不超过2MB");
      return false;
    }
    return true;
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  search() {
    this.state.paging.current_page = 1;
    this.getListsExam();
  }

  reset() {
    this.state.queryParams.cid = undefined;
    this.state.queryParams.question = undefined;
    this.state.paging.current_page = 1;
    this.getListsExam();
  }
}
