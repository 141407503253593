<template>
  <div class="right-content-box">
    <el-form
      ref="form"
      :model="state.queryParams"
      label-width="60px"
      :label-position="labelPosition"
      class="conditional-query"
    >
      <el-form-item label="题型" class="conditional-item">
        <el-select
          size="mini"
          v-model="state.queryParams.cat"
          placeholder="请选择"
        >
          <el-option :value="undefined" :label="'全部'"></el-option>
          <el-option :label="'性格题'" :value="'性格题'"></el-option>
          <el-option :label="'逻辑题'" :value="'逻辑题'"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="题目" class="conditional-item">
        <el-input
          size="mini"
          v-model="state.queryParams.question"
          placeholder="请输入关键字"
        ></el-input>
      </el-form-item>
      <el-button type="primary" class="btn-add" @click="search()">
        搜索
      </el-button>
      <el-button type="primary" class="btn-add" @click="reset()">
        重置
      </el-button>
    </el-form>

    <div class="add-box">
      <el-button @click="postAdd()" type="primary" class="btn-add">
        添加
      </el-button>
    </div>

    <!-- 表格 -->
    <el-table
      :data="state.paging.data"
      fixed
      style="width: 100%"
      class="manage-box"
    >
      <template v-for="item in manage" :key="item.manage">
        <el-table-column
          v-if="item.prop != 'fileList'"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        ></el-table-column>
        <el-table-column
          v-else
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
          <template #default="scope">
            <!-- <template v-if="scope.row[item.prop]"> -->
            <img
              v-for="pic in scope.row[item.prop]"
              :key="pic.url"
              :src="apiUrl + '/' + pic.url"
              width="40"
              height="40"
              class="head_pic"
            />
            <!-- </template> -->
          </template>
        </el-table-column>
      </template>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            type="primary"
            size="mini"
            @click="postEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除数据吗？"
            @confirm="postDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="state.paging.total"
      class="paging"
    >
    </el-pagination>

    <!-- 添加、编辑抽屉 -->
    <el-drawer
      :title="title"
      v-model="drawer"
      :modal="false"
      :before-close="handleClose"
    >
      <div class="form-box">
        <el-form
          ref="form"
          :model="state.special"
          :label-position="labelPosition"
        >
          <div class="question-box">
            <el-form-item label="题型">
              <el-select v-model="state.special.cat" placeholder="请选择题型">
                <el-option label="性格题" value="性格题"></el-option>
                <el-option label="逻辑题" value="逻辑题"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input
                type="textarea"
                v-model="state.special.question"
                placeholder="输入问题"
                class="input-textarea"
              ></el-input>
            </el-form-item>
            <el-upload
              drag
              multiple
              :accept="'.jpg, .png'"
              action="/api/uploads-propaganda"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :file-list="state.special.fileList"
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <template #tip>
                <div class="el-upload__tip">
                  只能上传 jpg/png 文件，且不超过 2MB
                </div>
              </template>
            </el-upload>

            <el-form-item
              v-for="content in state.special.content"
              :key="content.optionName"
              :label="'选项 ' + content.optionName"
            >
              <el-popconfirm
                v-if="state.special.content.length > 1"
                style="float: right"
                title="确定删除吗？"
                @confirm="optionDelete(content)"
              >
                <template #reference>
                  <el-button size="mini" type="danger">删除</el-button>
                </template>
              </el-popconfirm>
              <el-input
                type="textarea"
                autosize
                v-model="content.optionContent"
                placeholder="输入选项"
              ></el-input>
              <el-input
                type="number"
                v-model="content.score"
                placeholder="输入分值"
              ></el-input>
              <el-input
                type="textarea"
                autosize
                v-model="content.analysis"
                placeholder="输入分析结果"
              ></el-input>
            </el-form-item>
            <el-button type="primary" class="btn-edit" @click="addOption()">
              添加选项
            </el-button>
          </div>
        </el-form>
        <el-button
          type="primary"
          class="btn-edit"
          @click="submitForm(btnTitle)"
        >
          {{ btnTitle }}
        </el-button>
      </div>
    </el-drawer>
    <div v-if="isMask" class="mask"></div>
  </div>
</template>

<script lang="ts">
import { titleMixin } from "../../mixins/titleMixin";
import { Options, Vue } from "vue-class-component";
import { reactive } from "@vue/reactivity";
import { IPaging, ISpecial, ISpecialContent } from "@/typing";
import {
  postAddSpecialRequest,
  postDelSpecialRequest,
  postEditSpecialRequest,
  postListsSpecialRequest,
} from "@/apis/requests/api";
import { ElMessage } from "element-plus";
import { cloneDeep, existValidation } from "@/share/util";
import { environment } from "@/environments/environments";

interface QueryParams {
  cat?: string;
  question?: string;
}

@Options({
  mixins: [titleMixin],
})
export default class SpecialQuestionBank extends Vue {
  isMask = false;
  apiUrl = environment.apiUrl;

  letterSequences = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

  state = reactive<{
    queryParams: QueryParams;
    paging: IPaging<ISpecial>;
    special: ISpecial;
    questionList: { question: string; id: number }[];
  }>({
    paging: { data: [] },
    special: {
      content: [
        { optionName: "A", optionContent: "", analysis: "", score: undefined },
      ],
      fileList: [],
    },
    questionList: [],
    queryParams: {},
  });

  // 表头
  manage = [
    {
      prop: "id",
      label: "序号",
      width: "200",
    },
    {
      prop: "cat",
      label: "题型",
    },
    {
      prop: "question",
      label: "题目",
    },
    {
      prop: "fileList",
      label: "缩略图",
    },
  ];

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  handleRemove(file: any) {
    this.state.special.fileList = this.state.special.fileList?.filter(
      (item) => item.url !== file.url
    );
  }

  handleSuccess(
    thumbnail: any,
    file: any,
    fileList: { name: string; response: string; url: string }[]
  ) {
    setTimeout(() => {
      this.state.special.fileList = fileList.map((item) => {
        return {
          name: item.name,
          url: item.response || item.url,
        };
      });
    }, 2000);
  }

  beforeAvatarUpload(file: any) {
    if (file.type != "image/jpeg" && file.type != "image/png") {
      ElMessage.error("只能上传 jpg/png 文件");
      return false;
    }
    if (file.size / 1024 / 1024 > 2) {
      ElMessage.error("上传文件大小不超过2MB");
      return false;
    }
    return true;
  }

  postAdd() {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加题库";
    this.btnTitle = "添加";

    this.state.special = {
      content: [
        { optionName: "A", optionContent: "", analysis: "", score: undefined },
      ],
      fileList: [],
    };
  }

  postEdit(index: number, special: ISpecial) {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑题库";
    this.btnTitle = "确认修改";

    this.state.special = cloneDeep(special);
    if (!this.state.special.fileList) {
      this.state.special.fileList = [];
    }
  }

  created() {
    this.getListsSpecial();
  }

  getListsSpecial() {
    postListsSpecialRequest(
      this.state.paging.per_page,
      this.state.paging.current_page,
      this.state.queryParams.cat,
      this.state.queryParams.question
    ).then((result) => {
      this.state.paging = result.data;
      this.state.paging.data.forEach((item) => {
        item.fileList = JSON.parse(item.fileList as any);
        item.content = JSON.parse(item.content as any);
      });
    });

    postListsSpecialRequest(999, 1).then((result) => {
      this.state.questionList = result.data.data.map((item) => {
        return { question: item.question, id: item.id };
      }) as { question: string; id: number }[];
    });
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsSpecial();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsSpecial();
  }

  // 删除数据
  postDelete(index: number, special: ISpecial) {
    postDelSpecialRequest(special.id as number).then((result) => {
      if (result.code === 403) {
        ElMessage.error(result.msg);
      } else {
        ElMessage.success({
          message: "删除成功！",
          type: "success",
        });
      }
      this.drawer = false;
      this.getListsSpecial();
    });
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  addOption() {
    this.state.special.content?.push({
      optionName: this.letterSequences[
        this.state.special.content?.length as number
      ],
      optionContent: "",
      analysis: "",
      score: undefined,
    });
  }

  optionDelete(content: ISpecialContent) {
    this.state.special.content = this.state.special.content?.filter(
      (item) => item.optionName !== content.optionName
    );
    this.state.special.content?.map((item, index) => {
      item.optionName = this.letterSequences[index];
      return item;
    });
  }

  submitForm(btnTitle: string) {
    this.isMask = false;

    if (
      !existValidation([this.state.special.cat, this.state.special.question])
    ) {
      ElMessage.error("字段未填写完整");
      return;
    }

    for (let item of this.state.special.content as ISpecialContent[]) {
      if (
        !existValidation([
          item.optionName,
          item.optionContent,
          item.analysis,
          item.score,
        ])
      ) {
        ElMessage.error("字段未填写完整");
        return;
      }
      if ((item.score as number) < 0 || (item.score as number) > 10) {
        ElMessage.error("分值介于 0 - 10 的整数");
        return;
      }

      if ((item.score as any).includes(".")) {
        ElMessage.error("分值介于 0 - 10 的整数");
        return;
      }
    }

    for (const item of this.state.questionList) {
      if (
        item.id != this.state.special.id &&
        item.question === this.state.special.question
      ) {
        ElMessage.error("题目已存在");
        return;
      }
    }

    if (btnTitle === "添加") {
      postAddSpecialRequest(this.state.special).then((_) => {
        ElMessage.success({
          message: "创建成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.special = {
          content: [
            {
              optionName: "A",
              optionContent: "",
              analysis: "",
              score: undefined,
            },
          ],
          fileList: [],
        };
        this.getListsSpecial();
      });
    } else {
      postEditSpecialRequest(this.state.special).then((_) => {
        ElMessage.success({
          message: "修改成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.special = {
          content: [
            {
              optionName: "A",
              optionContent: "",
              analysis: "",
              score: undefined,
            },
          ],
          fileList: [],
        };
        this.getListsSpecial();
      });
    }
  }

  search() {
    this.state.paging.current_page = 1;
    this.getListsSpecial();
  }

  reset() {
    this.state.queryParams.cat = undefined;
    this.state.queryParams.question = undefined;
    this.state.paging.current_page = 1;
    this.getListsSpecial();
  }
}
</script>

<style scoped>
::v-deep .el-table th:first-of-type,
::v-deep .el-table th:last-of-type,
::v-deep .el-table td:first-of-type,
::v-deep .el-table td:last-of-type {
  text-align: center !important;
}
.add-box {
  text-align: right;
}
.btn-add {
  width: calc(100 / 14 * 1rem);
  min-height: calc(34 / 14 * 1rem);
}
.btn-edit {
  width: 100%;
  margin-top: 10px;
}
.jurisdiction-title {
  font-size: 14px;
  margin-bottom: calc(20 / 14 * 1rem);
  margin-top: calc(10 / 14 * 1rem);
}
::v-deep().el-select {
  width: 100%;
}
::v-deep().input-textarea textarea {
  height: 80px !important;
}
.question-box {
  border-radius: 6px;
  padding: 0 calc(20 / 14 * 1rem) calc(20 / 14 * 1rem);
  background: #f8f8f8;
}

::v-deep() .el-upload {
  width: 100%;
}

::v-deep() .el-upload-dragger {
  width: 100%;
}

.mask {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
}

::v-deep() .conditional-query {
  display: flex !important;
  flex-wrap: wrap;
  /* margin-top: 30px; */
}
::v-deep().conditional-item {
  margin-right: 20px !important;
  /* width: 20% !important; */
}
.btn-add {
  width: 100px;
  min-height: 34px;
}
</style>