
import {
  postAddAdminRequest,
  postDelAdminRequest,
  postEditAdminRequest,
  postListsAdminRequest,
} from "../../apis/requests/api";
import { IPaging, IUser } from "../../typing";
import { reactive } from "@vue/reactivity";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";
import { ElMessage } from "element-plus";
import { checkPhoneNumber, cloneDeep, existValidation } from "@/share/util";

@Options({
  mixins: [titleMixin],
})
export default class Manage extends Vue {
  isMask = false;

  password = "";
  state = reactive<{
    paging: IPaging<IUser>;
    user: IUser;
    userNameList: { name: string; id: number }[];
    roleOptions: {
      value: number;
      label: string;
    }[];
    role: number;
    depNameOfRole?: string;
  }>({
    paging: { data: [] },
    user: {},
    userNameList: [],
    role: 0,
    roleOptions: [
      {
        value: 0,
        label: "非面试人员",
      },
      {
        value: 1,
        label: "人力资源面试官",
      },
      {
        value: 2,
        label: "部门面试官",
      },
      // {
      //   value: 3,
      //   label: "总经理面试官",
      // },
    ],
  });

  created() {
    this.getListsAdmin();
  }

  changeRole(data: any) {
    console.log("data-", data);
  }

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  rules = {
    username: [
      { required: true, message: "登录账户名", trigger: "blur" },
      { min: 3, max: 10, message: "长度在 3 到 5 个字符", trigger: "blur" },
    ],
  };

  // 账号权限多选
  checkboxGroup: string[] = [];
  jurisdiction = [
    {
      jurSelect: "首页",
      label: "首页",
    },
    {
      jurSelect: "管理员管理",
      label: "管理员管理",
    },
    {
      jurSelect: "简历管理",
      label: "简历管理",
    },
    {
      jurSelect: "宣传管理",
      label: "宣传管理",
    },
    {
      jurSelect: "岗位管理",
      label: "岗位管理",
    },
    {
      jurSelect: "题库管理",
      label: "题库管理",
    },
    {
      jurSelect: "简历筛选",
      label: "简历筛选",
    },
    {
      jurSelect: "面试录用",
      label: "面试录用",
    },
    {
      jurSelect: "入职到岗",
      label: "入职到岗",
    },
    {
      jurSelect: "面试评价",
      label: "面试评价",
    },
  ];

  // 新增管理员
  manageAdd() {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加管理员";
    this.btnTitle = "添加";
    this.checkboxGroup = [
      "首页",
      "管理员管理",
      "简历管理",
      "宣传管理",
      "岗位管理",
      "题库管理",
      "简历筛选",
      "面试录用",
      "入职到岗",
      "面试评价",
    ];
    this.state.user = {};
    this.state.user.reard_all_resume= true,
    this.state.role = 0;
    this.state.depNameOfRole = undefined;
    console.log('user',this.state.user);
    
  }

  // 编辑管理员
  manageEdit(index: number, user: IUser) {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑管理员";
    this.btnTitle = "确认修改";
    this.checkboxGroup = (user.authority?.split("/") as string[]) || [];
    this.state.user = cloneDeep(user);
    this.state.role = this.state.user.type || 0;
    if (this.state.role == 2) {
      this.state.depNameOfRole = this.state.user.department || undefined;
    } else {
      this.state.depNameOfRole = undefined;
    }
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  // 删除数据
  manageDelete(index: number, user: IUser) {
    postDelAdminRequest(user.id as number).then((_) => {
      ElMessage.success({
        message: "删除成功！",
        type: "success",
      });
      this.drawer = false;
      this.getListsAdmin();
    });
  }

  submitForm(btnTitle: string) {
    this.isMask = false;
    if (
      !existValidation([
        this.state.user.name,
        this.state.user.password,
        this.state.user.realname,
        this.state.user.mobile,
      ])
    ) {
      ElMessage.error("字段未填写完整");
      return;
    }

    if (!checkPhoneNumber(this.state.user.mobile)) {
      ElMessage.error("电话号码格式错误");
      return;
    }

    if (!this.checkboxGroup || this.checkboxGroup.length === 0) {
      ElMessage.error("请选择账号权限");
      return;
    }

    for (const item of this.state.userNameList) {
      if (item.id != this.state.user.id && item.name === this.state.user.name) {
        ElMessage.error("该用户名已存在");
        return;
      }
    }

    this.state.user.type = this.state.role;

    if (this.state.role == 2) {
      this.state.user.department = this.state.depNameOfRole;
      if (!existValidation([this.state.user.department])) {
        ElMessage.error("字段未填写完整");
        return;
      }
    } else {
      this.state.user.department = this.state.roleOptions.filter(
        (item) => item.value === this.state.role
      )[0].label;
    }

    if (btnTitle === "添加") {
      this.state.user.authority = this.checkboxGroup.join("/");
      postAddAdminRequest(this.state.user).then((_) => {
        ElMessage.success({
          message: "创建成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.user = {};
        this.state.role = 0;
        this.getListsAdmin();
      });
    } else {
      this.state.user.authority = this.checkboxGroup.join("/");
      if (this.password.length > 0) {
        this.state.user.password = this.password;
      }
      this.password = "";
      postEditAdminRequest(this.state.user).then((_) => {
        ElMessage.success({
          message: "修改成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.user = {};
        this.state.role = 0;
        this.getListsAdmin();
      });
    }
  }

  getListsAdmin() {
    postListsAdminRequest(
      this.state.paging.per_page,
      this.state.paging.current_page
    ).then((result) => {
      this.state.paging = result.data;
    });

    postListsAdminRequest(999, 1).then((result) => {
      this.state.userNameList = result.data.data.map((item) => {
        return { name: item.name, id: item.id };
      }) as { name: string; id: number }[];
    });
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsAdmin();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsAdmin();
  }
}
