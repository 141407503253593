
import {
  postListsJobRequest,
  postDelJobRequest,
  postEditJobRequest,
  postAddJobRequest,
  postListsCategoryRequest,
} from "../../apis/requests/api";
import { IPaging, IJob, ICategory } from "../../typing";
import { reactive } from "@vue/reactivity";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";
import { ElMessage } from "element-plus";
import { cloneDeep, existValidation } from "../../share/util";

interface QueryParams {
  classification?: number;
  job?: number;
}

interface CurrentCat {
  cat: string;
  get_obj: {
    cid: number;
    content: null;
    id: number;
    job_name: string;
    number: number;
    sort: number;
  }[];
  id: number;
  sort: number;
}

@Options({
  mixins: [titleMixin],
  computed: {
    jobOptions() {
      const selectedCat: CurrentCat = this.state.queryCat.filter(
        (item: any) => item.id === this.state.queryParams.classification
      )[0];
      return selectedCat?.get_obj || [];
    },
  },
})
export default class PostManagement extends Vue {
  isMask = false;

  state = reactive<{
    queryParams: QueryParams;
    paging: IPaging<IJob>;
    queryCat: CurrentCat[];
    jobs: IJob;
    jobNameList: { jobName: string; id: number }[];
    catList: ICategory[];
  }>({
    paging: { data: [] },
    jobs: {},
    jobNameList: [],
    catList: [],
    queryParams: {},
    queryCat: [],
  });

  created() {
    this.getListsPost();

    postListsCategoryRequest(999, 1).then((result) => {
      this.state.catList = result.data.data;
    });
  }

  // 表头
  manage = [
    {
      prop: "id",
      label: "序号",
      width: "200",
    },
    {
      prop: "get_category.cat",
      label: "分类",
    },
    {
      prop: "job_name",
      label: "岗位",
    },
    {
      prop: "number",
      label: "数量",
    },
    {
      prop: "sort",
      label: "排序",
    },
  ];

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  // 新增岗位
  postAdd() {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加岗位";
    this.btnTitle = "添加";
    this.state.jobs = {};
  }

  // 编辑岗位
  postEdit(index: number, jobs: IJob) {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑岗位";
    this.btnTitle = "确认修改";
    this.state.jobs = cloneDeep(jobs);
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  // 删除数据
  postDelete(index: number, jobs: IJob) {
    postDelJobRequest(jobs.id as number).then((result) => {
      if (result.code === 403) {
        ElMessage.error(result.msg);
      } else {
        ElMessage.success({
          message: "删除成功！",
          type: "success",
        });
      }
      this.drawer = false;
      this.getListsPost();
    });
  }

  submitForm(btnTitle: string) {
    this.isMask = false;
    if (
      (this.state.jobs.job_name?.trim().length as number) > 12 ||
      (this.state.jobs.job_name?.trim().length as number) === 0
    ) {
      ElMessage.error("岗位字段在 12 个字以内");
      return;
    }

    if (
      !existValidation([
        this.state.jobs.job_name,
        this.state.jobs.sort,
        this.state.jobs.cid,
      ])
    ) {
      ElMessage.error("字段未填写完整");
      return;
    }

    if (
      +(this.state.jobs.sort as string) <= 0 ||
      this.state.jobs.number === undefined ||
      (this.state.jobs.number as number) <= 0
    ) {
      ElMessage.error("请输入大于0的正整数");
      return;
    }

    for (const item of this.state.jobNameList) {
      if (
        item.id != this.state.jobs.id &&
        item.jobName === this.state.jobs.job_name
      ) {
        ElMessage.error("岗位已存在");
        return;
      }
    }

    if (btnTitle === "添加") {
      postAddJobRequest(this.state.jobs).then((_) => {
        ElMessage.success({
          message: "创建成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.jobs = {};
        this.getListsPost();
      });
    } else {
      postEditJobRequest(this.state.jobs).then((_) => {
        ElMessage.success({
          message: "修改成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.jobs = {};
        this.getListsPost();
      });
    }
  }

  getListsPost() {
    postListsJobRequest(
      this.state.paging.per_page,
      this.state.paging.current_page,
      this.state.queryParams.classification,
      this.state.queryParams.job
    ).then((result) => {
      this.state.paging = result.data;
      this.state.queryCat = result.cat;
    });

    postListsJobRequest(999, 1).then((result) => {
      this.state.jobNameList = result.data.data.map((item) => {
        return { jobName: item.job_name, id: item.id };
      }) as { jobName: string; id: number }[];
    });
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsPost();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsPost();
  }

  search() {
    this.state.paging.current_page = 1;
    this.getListsPost();
  }

  reset() {
    this.state.queryParams.job = undefined;
    this.state.queryParams.classification = undefined;
    this.state.paging.current_page = 1;
    this.getListsPost();
  }
}
