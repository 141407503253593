<template>
  <div class="right-content-box">
    <el-form
      ref="form"
      :model="state.queryParams"
      label-width="60px"
      :label-position="labelPosition"
      class="conditional-query"
    >
      <el-form-item label="分类" class="conditional-item">
        <el-select
          size="mini"
          v-model="state.queryParams.classification"
          placeholder="请选择"
        >
          <el-option :value="undefined" :label="'全部'"></el-option>
          <el-option
            v-for="catItem in state.queryCat"
            :key="catItem.id"
            :label="catItem.cat"
            :value="catItem.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="岗位" class="conditional-item">
        <el-select
          size="mini"
          v-model="state.queryParams.job"
          placeholder="请选择"
          :disabled="!state.queryParams.classification"
        >
          <el-option :value="undefined" :label="'全部'"></el-option>
          <el-option
            v-for="jobItem in jobOptions"
            :key="jobItem.id"
            :label="jobItem.job_name"
            :value="jobItem.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-button type="primary" class="btn-add" @click="search()">
        搜索
      </el-button>
      <el-button type="primary" class="btn-add" @click="reset()">
        重置
      </el-button>
    </el-form>

    <!-- 添加岗位 -->
    <div class="add-box">
      <el-button @click="postAdd()" type="primary" class="btn-add">
        添加
      </el-button>
    </div>

    <!-- 表格 -->
    <el-table
      :data="state.paging.data"
      fixed
      style="width: 100%"
      class="manage-box"
    >
      <el-table-column
        v-for="item in manage"
        :key="item.manage"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
      ></el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            type="primary"
            size="mini"
            @click="postEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除数据吗？"
            @confirm="postDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="state.paging.total"
      class="paging"
    >
    </el-pagination>

    <!-- 添加、编辑抽屉 -->
    <el-drawer
      :title="title"
      v-model="drawer"
      :modal="false"
      :before-close="handleClose"
    >
      <div class="form-box">
        <el-form
          ref="form"
          :model="state.user"
          label-width="80px"
          :label-position="labelPosition"
        >
          <el-form-item label="岗位分类">
            <el-select
              v-model="state.jobs.cid"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="catItem in state.catList"
                :key="catItem.id"
                :label="catItem.cat"
                :value="catItem.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="岗位">
            <el-input
              v-model="state.jobs.job_name"
              placeholder="请输入岗位名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input
              type="number"
              v-model="state.jobs.sort"
              placeholder="数值越大越靠后"
            ></el-input>
          </el-form-item>
          <el-form-item label="数量">
            <el-input
              type="number"
              v-model="state.jobs.number"
              placeholder="招聘数量"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- 账号权限 -->
        <el-button
          type="primary"
          class="btn-edit"
          @click="submitForm(btnTitle)"
        >
          {{ btnTitle }}
        </el-button>
      </div>
    </el-drawer>
    <div v-if="isMask" class="mask"></div>
  </div>
</template>

<script lang="ts">
import {
  postListsJobRequest,
  postDelJobRequest,
  postEditJobRequest,
  postAddJobRequest,
  postListsCategoryRequest,
} from "../../apis/requests/api";
import { IPaging, IJob, ICategory } from "../../typing";
import { reactive } from "@vue/reactivity";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";
import { ElMessage } from "element-plus";
import { cloneDeep, existValidation } from "../../share/util";

interface QueryParams {
  classification?: number;
  job?: number;
}

interface CurrentCat {
  cat: string;
  get_obj: {
    cid: number;
    content: null;
    id: number;
    job_name: string;
    number: number;
    sort: number;
  }[];
  id: number;
  sort: number;
}

@Options({
  mixins: [titleMixin],
  computed: {
    jobOptions() {
      const selectedCat: CurrentCat = this.state.queryCat.filter(
        (item: any) => item.id === this.state.queryParams.classification
      )[0];
      return selectedCat?.get_obj || [];
    },
  },
})
export default class PostManagement extends Vue {
  isMask = false;

  state = reactive<{
    queryParams: QueryParams;
    paging: IPaging<IJob>;
    queryCat: CurrentCat[];
    jobs: IJob;
    jobNameList: { jobName: string; id: number }[];
    catList: ICategory[];
  }>({
    paging: { data: [] },
    jobs: {},
    jobNameList: [],
    catList: [],
    queryParams: {},
    queryCat: [],
  });

  created() {
    this.getListsPost();

    postListsCategoryRequest(999, 1).then((result) => {
      this.state.catList = result.data.data;
    });
  }

  // 表头
  manage = [
    {
      prop: "id",
      label: "序号",
      width: "200",
    },
    {
      prop: "get_category.cat",
      label: "分类",
    },
    {
      prop: "job_name",
      label: "岗位",
    },
    {
      prop: "number",
      label: "数量",
    },
    {
      prop: "sort",
      label: "排序",
    },
  ];

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  // 新增岗位
  postAdd() {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加岗位";
    this.btnTitle = "添加";
    this.state.jobs = {};
  }

  // 编辑岗位
  postEdit(index: number, jobs: IJob) {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑岗位";
    this.btnTitle = "确认修改";
    this.state.jobs = cloneDeep(jobs);
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  // 删除数据
  postDelete(index: number, jobs: IJob) {
    postDelJobRequest(jobs.id as number).then((result) => {
      if (result.code === 403) {
        ElMessage.error(result.msg);
      } else {
        ElMessage.success({
          message: "删除成功！",
          type: "success",
        });
      }
      this.drawer = false;
      this.getListsPost();
    });
  }

  submitForm(btnTitle: string) {
    this.isMask = false;
    if (
      (this.state.jobs.job_name?.trim().length as number) > 12 ||
      (this.state.jobs.job_name?.trim().length as number) === 0
    ) {
      ElMessage.error("岗位字段在 12 个字以内");
      return;
    }

    if (
      !existValidation([
        this.state.jobs.job_name,
        this.state.jobs.sort,
        this.state.jobs.cid,
      ])
    ) {
      ElMessage.error("字段未填写完整");
      return;
    }

    if (
      +(this.state.jobs.sort as string) <= 0 ||
      this.state.jobs.number === undefined ||
      (this.state.jobs.number as number) <= 0
    ) {
      ElMessage.error("请输入大于0的正整数");
      return;
    }

    for (const item of this.state.jobNameList) {
      if (
        item.id != this.state.jobs.id &&
        item.jobName === this.state.jobs.job_name
      ) {
        ElMessage.error("岗位已存在");
        return;
      }
    }

    if (btnTitle === "添加") {
      postAddJobRequest(this.state.jobs).then((_) => {
        ElMessage.success({
          message: "创建成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.jobs = {};
        this.getListsPost();
      });
    } else {
      postEditJobRequest(this.state.jobs).then((_) => {
        ElMessage.success({
          message: "修改成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.jobs = {};
        this.getListsPost();
      });
    }
  }

  getListsPost() {
    postListsJobRequest(
      this.state.paging.per_page,
      this.state.paging.current_page,
      this.state.queryParams.classification,
      this.state.queryParams.job
    ).then((result) => {
      this.state.paging = result.data;
      this.state.queryCat = result.cat;
    });

    postListsJobRequest(999, 1).then((result) => {
      this.state.jobNameList = result.data.data.map((item) => {
        return { jobName: item.job_name, id: item.id };
      }) as { jobName: string; id: number }[];
    });
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsPost();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsPost();
  }

  search() {
    this.state.paging.current_page = 1;
    this.getListsPost();
  }

  reset() {
    this.state.queryParams.job = undefined;
    this.state.queryParams.classification = undefined;
    this.state.paging.current_page = 1;
    this.getListsPost();
  }
}
</script>

<style scoped>
::v-deep .el-table th:first-of-type,
::v-deep .el-table th:last-of-type,
::v-deep .el-table td:first-of-type,
::v-deep .el-table td:last-of-type {
  text-align: center !important;
}
.add-box {
  text-align: right;
}
.btn-add {
  width: calc(100 / 14 * 1rem);
  min-height: calc(34 / 14 * 1rem);
}
.jurisdiction-title {
  font-size: 14px;
  width: 80px;
}
.jurisdiction-box {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}
.btn-edit {
  width: 100%;
  margin-top: calc(40 / 14 * 1rem) !important;
}
::v-deep() input[type="number"] {
  -moz-appearance: textfield;
}
::v-deep() input[type="number"]::-webkit-inner-spin-button,
::v-deep()input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep() .conditional-query {
  display: flex !important;
  flex-wrap: wrap;
  /* margin-top: 30px; */
}
::v-deep().conditional-item {
  margin-right: 20px !important;
  /* width: 20% !important; */
}
.btn-add {
  width: 100px;
  min-height: 34px;
}
</style>