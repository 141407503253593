
import {
  getListsEvaluationRequest
} from "../../apis/requests/api";
import { IPaging } from "../../typing";
import { reactive } from "@vue/reactivity";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";

@Options({
  mixins: [titleMixin],
})
export default class InterviewEvaluation extends Vue {

  state = reactive<{
    paging: IPaging<any>;
    user: any;
  }>({
    paging: { data: [] },
    user: {},
  });

  created() {
    this.getListseEvaluation();
  }

  rules = {
    username: [
      { required: true, message: "登录账户名", trigger: "blur" },
      { min: 3, max: 10, message: "长度在 3 到 5 个字符", trigger: "blur" },
    ],
  };

  getListseEvaluation(): void {
    getListsEvaluationRequest(
      this.state.paging.per_page,
      this.state.paging.current_page
    ).then((result) => {
      this.state.paging = result.data;
    });
  }

  handleSizeChange(val: number): void {
    this.state.paging.per_page = val;
    this.getListseEvaluation();
  }

  handleCurrentChange(val: number): void {
    this.state.paging.current_page = val;
    this.getListseEvaluation();
  }
}
