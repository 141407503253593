
import { titleMixin } from "../../mixins/titleMixin";
import { Options, Vue } from "vue-class-component";
import { reactive } from "@vue/reactivity";
import { IPaging, ISpecial, ISpecialContent } from "@/typing";
import {
  postAddSpecialRequest,
  postDelSpecialRequest,
  postEditSpecialRequest,
  postListsSpecialRequest,
} from "@/apis/requests/api";
import { ElMessage } from "element-plus";
import { cloneDeep, existValidation } from "@/share/util";
import { environment } from "@/environments/environments";

interface QueryParams {
  cat?: string;
  question?: string;
}

@Options({
  mixins: [titleMixin],
})
export default class SpecialQuestionBank extends Vue {
  isMask = false;
  apiUrl = environment.apiUrl;

  letterSequences = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

  state = reactive<{
    queryParams: QueryParams;
    paging: IPaging<ISpecial>;
    special: ISpecial;
    questionList: { question: string; id: number }[];
  }>({
    paging: { data: [] },
    special: {
      content: [
        { optionName: "A", optionContent: "", analysis: "", score: undefined },
      ],
      fileList: [],
    },
    questionList: [],
    queryParams: {},
  });

  // 表头
  manage = [
    {
      prop: "id",
      label: "序号",
      width: "200",
    },
    {
      prop: "cat",
      label: "题型",
    },
    {
      prop: "question",
      label: "题目",
    },
    {
      prop: "fileList",
      label: "缩略图",
    },
  ];

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  handleRemove(file: any) {
    this.state.special.fileList = this.state.special.fileList?.filter(
      (item) => item.url !== file.url
    );
  }

  handleSuccess(
    thumbnail: any,
    file: any,
    fileList: { name: string; response: string; url: string }[]
  ) {
    setTimeout(() => {
      this.state.special.fileList = fileList.map((item) => {
        return {
          name: item.name,
          url: item.response || item.url,
        };
      });
    }, 2000);
  }

  beforeAvatarUpload(file: any) {
    if (file.type != "image/jpeg" && file.type != "image/png") {
      ElMessage.error("只能上传 jpg/png 文件");
      return false;
    }
    if (file.size / 1024 / 1024 > 2) {
      ElMessage.error("上传文件大小不超过2MB");
      return false;
    }
    return true;
  }

  postAdd() {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加题库";
    this.btnTitle = "添加";

    this.state.special = {
      content: [
        { optionName: "A", optionContent: "", analysis: "", score: undefined },
      ],
      fileList: [],
    };
  }

  postEdit(index: number, special: ISpecial) {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑题库";
    this.btnTitle = "确认修改";

    this.state.special = cloneDeep(special);
    if (!this.state.special.fileList) {
      this.state.special.fileList = [];
    }
  }

  created() {
    this.getListsSpecial();
  }

  getListsSpecial() {
    postListsSpecialRequest(
      this.state.paging.per_page,
      this.state.paging.current_page,
      this.state.queryParams.cat,
      this.state.queryParams.question
    ).then((result) => {
      this.state.paging = result.data;
      this.state.paging.data.forEach((item) => {
        item.fileList = JSON.parse(item.fileList as any);
        item.content = JSON.parse(item.content as any);
      });
    });

    postListsSpecialRequest(999, 1).then((result) => {
      this.state.questionList = result.data.data.map((item) => {
        return { question: item.question, id: item.id };
      }) as { question: string; id: number }[];
    });
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsSpecial();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsSpecial();
  }

  // 删除数据
  postDelete(index: number, special: ISpecial) {
    postDelSpecialRequest(special.id as number).then((result) => {
      if (result.code === 403) {
        ElMessage.error(result.msg);
      } else {
        ElMessage.success({
          message: "删除成功！",
          type: "success",
        });
      }
      this.drawer = false;
      this.getListsSpecial();
    });
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  addOption() {
    this.state.special.content?.push({
      optionName: this.letterSequences[
        this.state.special.content?.length as number
      ],
      optionContent: "",
      analysis: "",
      score: undefined,
    });
  }

  optionDelete(content: ISpecialContent) {
    this.state.special.content = this.state.special.content?.filter(
      (item) => item.optionName !== content.optionName
    );
    this.state.special.content?.map((item, index) => {
      item.optionName = this.letterSequences[index];
      return item;
    });
  }

  submitForm(btnTitle: string) {
    this.isMask = false;

    if (
      !existValidation([this.state.special.cat, this.state.special.question])
    ) {
      ElMessage.error("字段未填写完整");
      return;
    }

    for (let item of this.state.special.content as ISpecialContent[]) {
      if (
        !existValidation([
          item.optionName,
          item.optionContent,
          item.analysis,
          item.score,
        ])
      ) {
        ElMessage.error("字段未填写完整");
        return;
      }
      if ((item.score as number) < 0 || (item.score as number) > 10) {
        ElMessage.error("分值介于 0 - 10 的整数");
        return;
      }

      if ((item.score as any).includes(".")) {
        ElMessage.error("分值介于 0 - 10 的整数");
        return;
      }
    }

    for (const item of this.state.questionList) {
      if (
        item.id != this.state.special.id &&
        item.question === this.state.special.question
      ) {
        ElMessage.error("题目已存在");
        return;
      }
    }

    if (btnTitle === "添加") {
      postAddSpecialRequest(this.state.special).then((_) => {
        ElMessage.success({
          message: "创建成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.special = {
          content: [
            {
              optionName: "A",
              optionContent: "",
              analysis: "",
              score: undefined,
            },
          ],
          fileList: [],
        };
        this.getListsSpecial();
      });
    } else {
      postEditSpecialRequest(this.state.special).then((_) => {
        ElMessage.success({
          message: "修改成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.special = {
          content: [
            {
              optionName: "A",
              optionContent: "",
              analysis: "",
              score: undefined,
            },
          ],
          fileList: [],
        };
        this.getListsSpecial();
      });
    }
  }

  search() {
    this.state.paging.current_page = 1;
    this.getListsSpecial();
  }

  reset() {
    this.state.queryParams.cat = undefined;
    this.state.queryParams.question = undefined;
    this.state.paging.current_page = 1;
    this.getListsSpecial();
  }
}
