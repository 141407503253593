<template>
  <div class="right-content-box">
    <!-- 添加宣传主题 -->
    <div class="add-box">
      <el-button @click="propagandaAdd()" type="primary" class="btn-add">
        添加
      </el-button>
    </div>

    <!-- 表格 -->
    <el-table
      :data="state.paging.data"
      fixed
      style="width: 100%"
      class="manage-box"
    >
      <template v-for="item in manage" :key="item.id">
        <el-table-column
          v-if="item.prop != 'thumbnail'"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        ></el-table-column>
        <el-table-column
          v-else
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
          <template #default="scope">
            <img
              v-if="
                scope.row[item.prop] &&
                scope.row[item.prop].substring(
                  scope.row[item.prop].length - 3,
                  scope.row[item.prop].length
                ) != 'mp4'
              "
              :src="apiUrl + '/' + scope.row[item.prop]"
              width="40"
              height="40"
              class="head_pic"
            />
            <video
              v-else
              :src="apiUrl + '/' + scope.row[item.prop]"
              width="40"
              height="40"
              class="head_pic"
            ></video>
          </template>
        </el-table-column>
      </template>

      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            type="primary"
            size="mini"
            @click="propagandaEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除数据吗？"
            @confirm="propagandaDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="state.paging.total"
      class="paging"
    >
    </el-pagination>

    <!-- 添加、编辑宣传主题 -->
    <el-drawer
      :title="title"
      v-model="drawer"
      :modal="false"
      :before-close="handleClose"
    >
      <div class="form-box">
        <el-form
          ref="form"
          :model="state.propaganda"
          label-width="80px"
          :label-position="labelPosition"
        >
          <el-form-item label="主题">
            <el-input
              v-model="state.propaganda.theme"
              placeholder="输入宣传主题"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input
              v-model="state.propaganda.sort"
              type="number"
              placeholder="数值越大越靠后"
            ></el-input>
          </el-form-item>
        </el-form>

        <el-upload
          drag
          :accept="'image/*, video/*'"
          action="/api/uploads-propaganda"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
          :file-list="state.propaganda.fileList"
          :before-upload="beforeAvatarUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <template #tip>
            <div class="el-upload__tip">支持图片及视频(只能上传一个）</div>
          </template>
        </el-upload>

        <!-- 账号权限 -->
        <el-button
          type="primary"
          class="btn-edit"
          @click="submitForm(btnTitle)"
        >
          {{ btnTitle }}
        </el-button>
      </div>
    </el-drawer>
    <div v-if="isMask" class="mask"></div>
  </div>
</template>

<script lang="ts">
import {
  postAddPropagandaRequest,
  postDelPropagandaRequest,
  postEditPropagandaRequest,
  postListsPropagandaRequest,
} from "@/apis/requests/api";
import { cloneDeep, existValidation } from "@/share/util";
import { reactive } from "@vue/reactivity";
import { ElMessage } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";
import { IPaging, IPropaganda } from "../../typing";
import { environment } from "../../environments/environments";

@Options({
  mixins: [titleMixin],
})
export default class PublicityList extends Vue {
  isMask = false;

  apiUrl = environment.apiUrl;

  state = reactive<{
    paging: IPaging<IPropaganda>;
    propaganda: IPropaganda;
  }>({
    paging: { data: [] },
    propaganda: { fileList: [] },
  });

  created() {
    this.getListsPropaganda();
  }

  handleRemove(file: any) {
    this.state.propaganda.thumbnail = undefined;
    this.state.propaganda.fileList = [];
  }

  handleSuccess(thumbnail: any, file: any) {
    console.log(thumbnail);

    this.state.propaganda.thumbnail = thumbnail;
    this.state.propaganda.fileList = [{ name: file.name, url: file.response }];
  }

  getListsPropaganda() {
    postListsPropagandaRequest(
      this.state.paging.per_page,
      this.state.paging.current_page
    ).then((result) => {
      this.state.paging = result.data;
      this.state.paging.data.forEach((item) => {
        item.fileList = JSON.parse(item.fileList as any);
      });
    });
  }

  // 表头
  manage = [
    {
      prop: "id",
      label: "序号",
      width: "200",
    },
    {
      prop: "theme",
      label: "主题",
    },
    {
      prop: "sort",
      label: "排序",
    },
    {
      prop: "thumbnail",
      label: "缩略图",
    },
  ];

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  // 新增管理员
  propagandaAdd() {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加宣传列表";
    this.btnTitle = "添加";

    this.state.propaganda = { fileList: [] };
  }

  // 编辑管理员
  propagandaEdit(index: number, propaganda: IPropaganda) {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑宣传列表";
    this.btnTitle = "确认修改";

    this.state.propaganda = cloneDeep(propaganda);
    if (!this.state.propaganda.fileList) {
      this.state.propaganda.fileList = [];
    }
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  propagandaDelete(index: number, propaganda: IPropaganda) {
    postDelPropagandaRequest(propaganda.id as number).then((_) => {
      ElMessage.success({
        message: "删除成功！",
        type: "success",
      });
      this.drawer = false;
      this.getListsPropaganda();
    });
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsPropaganda();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsPropaganda();
  }

  submitForm(btnTitle: string) {
    this.isMask = false;
    if (
      !existValidation([
        this.state.propaganda.theme,
        this.state.propaganda.sort,
      ])
    ) {
      ElMessage.error("字段未填写完整");
      return;
    }

    if ((this.state.propaganda.sort as number) <= 0) {
      ElMessage.error("请输入大于0的正整数");
      return;
    }

    if (btnTitle === "添加") {
      postAddPropagandaRequest(this.state.propaganda).then((_) => {
        ElMessage.success({
          message: "创建成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.propaganda = { fileList: [] };
        this.getListsPropaganda();
      });
    } else {
      postEditPropagandaRequest(this.state.propaganda).then((_) => {
        ElMessage.success({
          message: "修改成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.propaganda = { fileList: [] };
        this.getListsPropaganda();
      });
    }
  }

  beforeAvatarUpload(file: any) {
    if (
      file.type.substring(0, 5) != "video" &&
      file.type.substring(0, 5) != "image"
    ) {
      ElMessage.error("只支持图片及视频文件");
      return false;
    }
    return true;
  }
}
</script>

<style scoped>
::v-deep .el-table th:first-of-type,
::v-deep .el-table th:last-of-type,
::v-deep .el-table td:first-of-type,
::v-deep .el-table td:last-of-type {
  text-align: center !important;
}
.add-box {
  text-align: right;
}
.btn-add {
  width: calc(100 / 14 * 1rem);
  min-height: calc(34 / 14 * 1rem);
}
.btn-edit {
  width: 100%;
  margin-top: calc(40 / 14 * 1rem) !important;
}

::v-deep() .el-upload {
  width: 100%;
}

::v-deep() .el-upload-dragger {
  width: 100%;
}
</style>