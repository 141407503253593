const dateFormat = (dat: string | Date) => {
    const date = new Date(dat);
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    let fmt = "YYYY-mm-dd";
    for (const k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? ((opt as any)[k]) : ((opt as any)[k].padStart(ret[1].length, "0")))
        }
    }
    return fmt;
}

const existValidation = (args: any[]) => {
    for (const arg of args) {
        if (arg == null || arg == undefined || (typeof arg === 'string' && arg.trim().length === 0)) {
            return false;
        }
    }
    return true;
}

const cloneDeep = (obj: any) => {
    const isObject = Object.prototype.toString.call(obj) === "[object Object]";
    const isArray = Object.prototype.toString.call(obj) === "[object Array]";
    if (isObject) {
        const newObj: any = {};
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                newObj[key] = cloneDeep(obj[key]);
            }
        }
        return newObj;
    } else if (isArray) {
        const newArr: any = [];
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                newArr[key] = cloneDeep(obj[key]);
            }
        }
        return newArr;
    } else {
        return obj;
    }
}

const isCardNo = (card: string) => {
    // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X 
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (reg.test(card) === false) {
        alert("身份证输入不合法");
        return false;
    }
    return true;
}

const isEmail = (email: string) => {
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (reg.test(email) === false) {
        alert("邮箱输入不合法");
        return false;
    }
    return true;
}

const checkPhoneNumber = (phone: string) => {
    // 1[345678]\d{9} 号码为数字1开头+一位3-8的数字+后面任意9位数字
    if (!(/^1[345678]\d{9}$/.test(phone))) {
        return false;
    }
    return true;
}

export {
    dateFormat,
    existValidation,
    cloneDeep,
    isCardNo,
    isEmail,
    checkPhoneNumber
}