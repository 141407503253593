
import {
  postAddCatRequest,
  postDelCatRequest,
  postEditCatRequest,
  postListCatRequest,
} from "../../apis/requests/api";
import { cloneDeep, existValidation } from "../../share/util";
import { ICat, IPaging } from "../../typing";
import { reactive } from "@vue/reactivity";
import { ElMessage } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";

@Options({
  mixins: [titleMixin],
})
export default class QuestionList extends Vue {
  isMask = false;

  state = reactive<{
    paging: IPaging<ICat>;
    cat: ICat;
    nameList: { name: string; id: number }[];
  }>({
    paging: { data: [] },
    cat: {},
    nameList: [],
  });

  created() {
    this.getListCat();
  }

  getListCat() {
    postListCatRequest(
      this.state.paging.per_page,
      this.state.paging.current_page
    ).then((result) => {
      this.state.paging = result.data;
    });

    postListCatRequest(999, 1).then((result) => {
      this.state.nameList = result.data.data.map((item: any) => {
        return { name: item.name, id: item.id };
      }) as { name: string; id: number }[];
    });
  }

  // 表头
  manage = [
    {
      prop: "id",
      label: "序号",
      width: "200",
    },
    {
      prop: "name",
      label: "题型名称",
    },
    {
      prop: "sort",
      label: "排序",
    },
  ];

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  listAdd() {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加题型";
    this.btnTitle = "添加";

    this.state.cat = {};
  }

  listEdit(index: number, cat: ICat) {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑题型";
    this.btnTitle = "确认修改";

    this.state.cat = cloneDeep(cat);
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  // 删除航数据
  listDelete(index: number, cat: ICat) {
    postDelCatRequest(cat.id as number).then((result) => {
      if (result.code === 403) {
        ElMessage.error(result.msg);
      } else {
        ElMessage.success({
          message: "删除成功！",
          type: "success",
        });
      }
      this.drawer = false;
      this.getListCat();
    });
  }

  submitForm(btnTitle: string) {
    this.isMask = false;
    if (!existValidation([this.state.cat.name, this.state.cat.sort])) {
      ElMessage.error("字段未填写完整");
      return;
    }

    if ((this.state.cat.sort as number) <= 0) {
      ElMessage.error("请输入大于0的正整数");
      return;
    }

    for (const item of this.state.nameList) {
      if (item.id != this.state.cat.id && item.name === this.state.cat.name) {
        ElMessage.error("题型名称已存在");
        return;
      }
    }

    if (btnTitle === "添加") {
      postAddCatRequest(this.state.cat).then((_) => {
        ElMessage.success({
          message: "创建成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.cat = {};
        this.getListCat();
      });
    } else {
      postEditCatRequest(this.state.cat).then((_) => {
        ElMessage.success({
          message: "修改成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.cat = {};
        this.getListCat();
      });
    }
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListCat();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListCat();
  }
}
