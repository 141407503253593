
import {
  postAddPropagandaRequest,
  postDelPropagandaRequest,
  postEditPropagandaRequest,
  postListsPropagandaRequest,
} from "@/apis/requests/api";
import { cloneDeep, existValidation } from "@/share/util";
import { reactive } from "@vue/reactivity";
import { ElMessage } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";
import { IPaging, IPropaganda } from "../../typing";
import { environment } from "../../environments/environments";

@Options({
  mixins: [titleMixin],
})
export default class PublicityList extends Vue {
  isMask = false;

  apiUrl = environment.apiUrl;

  state = reactive<{
    paging: IPaging<IPropaganda>;
    propaganda: IPropaganda;
  }>({
    paging: { data: [] },
    propaganda: { fileList: [] },
  });

  created() {
    this.getListsPropaganda();
  }

  handleRemove(file: any) {
    this.state.propaganda.thumbnail = undefined;
    this.state.propaganda.fileList = [];
  }

  handleSuccess(thumbnail: any, file: any) {
    console.log(thumbnail);

    this.state.propaganda.thumbnail = thumbnail;
    this.state.propaganda.fileList = [{ name: file.name, url: file.response }];
  }

  getListsPropaganda() {
    postListsPropagandaRequest(
      this.state.paging.per_page,
      this.state.paging.current_page
    ).then((result) => {
      this.state.paging = result.data;
      this.state.paging.data.forEach((item) => {
        item.fileList = JSON.parse(item.fileList as any);
      });
    });
  }

  // 表头
  manage = [
    {
      prop: "id",
      label: "序号",
      width: "200",
    },
    {
      prop: "theme",
      label: "主题",
    },
    {
      prop: "sort",
      label: "排序",
    },
    {
      prop: "thumbnail",
      label: "缩略图",
    },
  ];

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  // 新增管理员
  propagandaAdd() {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加宣传列表";
    this.btnTitle = "添加";

    this.state.propaganda = { fileList: [] };
  }

  // 编辑管理员
  propagandaEdit(index: number, propaganda: IPropaganda) {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑宣传列表";
    this.btnTitle = "确认修改";

    this.state.propaganda = cloneDeep(propaganda);
    if (!this.state.propaganda.fileList) {
      this.state.propaganda.fileList = [];
    }
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  propagandaDelete(index: number, propaganda: IPropaganda) {
    postDelPropagandaRequest(propaganda.id as number).then((_) => {
      ElMessage.success({
        message: "删除成功！",
        type: "success",
      });
      this.drawer = false;
      this.getListsPropaganda();
    });
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsPropaganda();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsPropaganda();
  }

  submitForm(btnTitle: string) {
    this.isMask = false;
    if (
      !existValidation([
        this.state.propaganda.theme,
        this.state.propaganda.sort,
      ])
    ) {
      ElMessage.error("字段未填写完整");
      return;
    }

    if ((this.state.propaganda.sort as number) <= 0) {
      ElMessage.error("请输入大于0的正整数");
      return;
    }

    if (btnTitle === "添加") {
      postAddPropagandaRequest(this.state.propaganda).then((_) => {
        ElMessage.success({
          message: "创建成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.propaganda = { fileList: [] };
        this.getListsPropaganda();
      });
    } else {
      postEditPropagandaRequest(this.state.propaganda).then((_) => {
        ElMessage.success({
          message: "修改成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.propaganda = { fileList: [] };
        this.getListsPropaganda();
      });
    }
  }

  beforeAvatarUpload(file: any) {
    if (
      file.type.substring(0, 5) != "video" &&
      file.type.substring(0, 5) != "image"
    ) {
      ElMessage.error("只支持图片及视频文件");
      return false;
    }
    return true;
  }
}
