
import {
  getListsStaffRequest,
  postAddEditStaffRequest,
  postDelStaffRequest,
} from "../../apis/requests/api";
import { IPaging, IStaffUser } from "../../typing";
import { reactive } from "@vue/reactivity";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";
import { ElMessage } from "element-plus";
import { checkPhoneNumber, cloneDeep, existValidation } from "@/share/util";

@Options({
  mixins: [titleMixin],
})
export default class Staff extends Vue {
  isMask = false;

  state = reactive<{
    paging: IPaging<IStaffUser>;
    user: IStaffUser;
    userNameList: { name: string; id: number }[];
  }>({
    paging: { data: [] },
    user: {},
    userNameList: [],
  });

  created() {
    this.getListsStaff();
  }

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  rules = {
    username: [
      { required: true, message: "登录账户名", trigger: "blur" },
      { min: 3, max: 10, message: "长度在 3 到 5 个字符", trigger: "blur" },
    ],
  };

  // 新增行政人员
  manageAdd(): void {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加行政人员";
    this.btnTitle = "添加";
    this.state.user = {};
  }

  // 编辑行政人员
  manageEdit(index: number, user: IStaffUser): void {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑行政人员";
    this.btnTitle = "确认修改";
    this.state.user = cloneDeep(user);
  }

  handleClose(done: any): void {
    this.isMask = false;
    done();
  }

  // 删除数据
  manageDelete(index: number, user: IStaffUser): void {
    postDelStaffRequest(user.id as number).then(() => {
      ElMessage.success({
        message: "删除成功！",
        type: "success",
      });
      this.drawer = false;
      this.getListsStaff();
    });
  }

  submitForm(btnTitle: string): void {
    this.isMask = false;
    if (!existValidation([this.state.user.name, this.state.user.phone])) {
      ElMessage.error("字段未填写完整");
      return;
    }

    if (!checkPhoneNumber(this.state.user.phone)) {
      ElMessage.error("电话号码格式错误");
      return;
    }

    if (btnTitle === "添加") {
      postAddEditStaffRequest(this.state.user).then((res) => {
        if (res.code === 200) {
          ElMessage.success({
            message: "创建成功！",
            type: "success",
          });
          this.drawer = false;
          this.state.user = {};
          this.state.paging.current_page = 1
          this.getListsStaff();
        } else {
          ElMessage.error({
            message: res.msg,
            type: "error",
          });
        }
      });
    } else {
      postAddEditStaffRequest(this.state.user).then((res) => {
        if (res.code === 200) {
          ElMessage.success({
            message: "修改成功！",
            type: "success",
          });
          this.drawer = false;
          this.state.user = {};
          this.getListsStaff();
        } else {
          ElMessage.error({
            message: res.msg,
            type: "error",
          });
        }
      });
    }
  }

  getListsStaff(): void {
    getListsStaffRequest(
      this.state.paging.per_page,
      this.state.paging.current_page
    ).then((result) => {
      this.state.paging = result.data;
    });

    // getListsStaffRequest(999, 1).then((result) => {
    //   this.state.userNameList = result.data.data.map((item) => {
    //     return { name: item.name, id: item.id };
    //   }) as { name: string; id: number }[];
    // });
  }

  handleSizeChange(val: number): void {
    this.state.paging.per_page = val;
    this.getListsStaff();
  }

  handleCurrentChange(val: number): void {
    this.state.paging.current_page = val;
    this.getListsStaff();
  }
}
