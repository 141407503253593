
import { postIndexRequest } from "@/apis/requests/api";
import { IHomeIndex } from "@/typing";
import { reactive } from "@vue/reactivity";
import { ElMessage } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";

@Options({
  mixins: [titleMixin],
})
export default class Home extends Vue {
  state = reactive<{ homeIndex?: IHomeIndex }>({});

  created() {
    postIndexRequest().then((result) => {
      this.state.homeIndex = result.data;
    });
  }

  datas = [
    {
      num: "1599/66",
      title: "应聘简历(总/新)",
      classStyle: "",
    },
    {
      num: 6,
      title: "",
      classStyle: "",
    },
    {
      num: 600,
      title: "",
      classStyle: "",
    },
  ];

  copyText() {
    const code = (document.getElementById("code") as HTMLElement).innerText;
    const tempInput = document.getElementById("temp-input") as any;
    tempInput.value = code;
    tempInput.select();
    document.execCommand("copy");
    ElMessage.success({
      message: "复制成功",
      type: "success",
    });
  }
}
