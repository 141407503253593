<template>
  <div class="right-content-box">
    <!-- 添加管理员 -->
    <div class="add-box">
      <el-button @click="manageAdd()" type="primary" class="btn-add"
        >添加</el-button
      >
    </div>
    <!-- 表格 -->
    <el-table
      :data="state.paging.data"
      fixed
      style="width: 100%"
      class="manage-box"
      destroy-on-close
    >
      <el-table-column label="序号" prop="id"> </el-table-column>
      <el-table-column label="账号" prop="name"> </el-table-column>
      <el-table-column label="真实姓名" prop="realname"> </el-table-column>
      <el-table-column label="电话号码" prop="mobile"> </el-table-column>
      <el-table-column label="权限" prop="authority"> </el-table-column>
      <el-table-column label="面试官" prop="department"> </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            type="primary"
            size="mini"
            @click="manageEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除数据吗？"
            @confirm="manageDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="state.paging.total"
      class="paging"
    >
    </el-pagination>

    <!-- 添加用户、编辑抽屉 -->
    <el-drawer
      :title="title"
      v-model="drawer"
      :modal="false"
      :before-close="handleClose"
    >
      <div class="form-box">
        <el-form
          :model="state.user"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
          :label-position="labelPosition"
        >
          <el-form-item label="用户名" prop="name">
            <el-input
              v-model="state.user.name"
              placeholder="登录账户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话号码">
            <el-input
              v-model="state.user.mobile"
              placeholder="电话号码"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input
              v-model="state.user.password"
              placeholder="登录账户密码"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="真实姓名">
            <el-input
              v-model="state.user.realname"
              placeholder="请输入真实姓名"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- 账号权限 -->
        <div style="display: flex">
          <div class="jurisdiction-title">账号权限</div>
          <el-checkbox-group v-model="checkboxGroup" size="medium">
            <el-checkbox
              v-for="item in jurisdiction"
              :v-model="item.jurSelect"
              :label="item.label"
              :key="item.jurisdiction"
              border
            ></el-checkbox>
          </el-checkbox-group>
        </div>
        <div style="display: flex">
          <div class="jurisdiction-title">账号角色</div>
          <div style="display: flex; flex-direction: column">
            <el-select
              v-model="state.role"
              @change="changeRole"
              placeholder="请选择角色"
            >
              <el-option
                v-for="item in state.roleOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <el-input
              v-if="state.role == 2"
              style="margin-top: 10px"
              v-model="state.depNameOfRole"
              placeholder="请输入部门名称"
              type="text"
            ></el-input>
          </div>
        </div>
        <div style="display: flex;margin-top:22px;">
          <div class="jurisdiction-title">全部简历</div>
             <el-checkbox class="show-resume" v-model="state.user.reard_all_resume" label="可看全部简历" size="large" />
        </div>

        <el-button
          type="primary"
          class="btn-edit"
          @click="submitForm(btnTitle)"
        >
          {{ btnTitle }}
        </el-button>
      </div>
    </el-drawer>
    <div v-if="isMask" class="mask"></div>
  </div>
</template>

<script lang="ts">
import {
  postAddAdminRequest,
  postDelAdminRequest,
  postEditAdminRequest,
  postListsAdminRequest,
} from "../../apis/requests/api";
import { IPaging, IUser } from "../../typing";
import { reactive } from "@vue/reactivity";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";
import { ElMessage } from "element-plus";
import { checkPhoneNumber, cloneDeep, existValidation } from "@/share/util";

@Options({
  mixins: [titleMixin],
})
export default class Manage extends Vue {
  isMask = false;

  password = "";
  state = reactive<{
    paging: IPaging<IUser>;
    user: IUser;
    userNameList: { name: string; id: number }[];
    roleOptions: {
      value: number;
      label: string;
    }[];
    role: number;
    depNameOfRole?: string;
  }>({
    paging: { data: [] },
    user: {},
    userNameList: [],
    role: 0,
    roleOptions: [
      {
        value: 0,
        label: "非面试人员",
      },
      {
        value: 1,
        label: "人力资源面试官",
      },
      {
        value: 2,
        label: "部门面试官",
      },
      // {
      //   value: 3,
      //   label: "总经理面试官",
      // },
    ],
  });

  created() {
    this.getListsAdmin();
  }

  changeRole(data: any) {
    console.log("data-", data);
  }

  // 抽屉
  drawer = false;
  labelPosition = "left";
  title = "";
  btnTitle = "";

  rules = {
    username: [
      { required: true, message: "登录账户名", trigger: "blur" },
      { min: 3, max: 10, message: "长度在 3 到 5 个字符", trigger: "blur" },
    ],
  };

  // 账号权限多选
  checkboxGroup: string[] = [];
  jurisdiction = [
    {
      jurSelect: "首页",
      label: "首页",
    },
    {
      jurSelect: "管理员管理",
      label: "管理员管理",
    },
    {
      jurSelect: "简历管理",
      label: "简历管理",
    },
    {
      jurSelect: "宣传管理",
      label: "宣传管理",
    },
    {
      jurSelect: "岗位管理",
      label: "岗位管理",
    },
    {
      jurSelect: "题库管理",
      label: "题库管理",
    },
    {
      jurSelect: "简历筛选",
      label: "简历筛选",
    },
    {
      jurSelect: "面试录用",
      label: "面试录用",
    },
    {
      jurSelect: "入职到岗",
      label: "入职到岗",
    },
    {
      jurSelect: "面试评价",
      label: "面试评价",
    },
  ];

  // 新增管理员
  manageAdd() {
    this.isMask = true;
    this.drawer = true;
    this.title = "添加管理员";
    this.btnTitle = "添加";
    this.checkboxGroup = [
      "首页",
      "管理员管理",
      "简历管理",
      "宣传管理",
      "岗位管理",
      "题库管理",
      "简历筛选",
      "面试录用",
      "入职到岗",
      "面试评价",
    ];
    this.state.user = {};
    this.state.user.reard_all_resume= true,
    this.state.role = 0;
    this.state.depNameOfRole = undefined;
    console.log('user',this.state.user);
    
  }

  // 编辑管理员
  manageEdit(index: number, user: IUser) {
    this.isMask = true;
    this.drawer = true;
    this.title = "编辑管理员";
    this.btnTitle = "确认修改";
    this.checkboxGroup = (user.authority?.split("/") as string[]) || [];
    this.state.user = cloneDeep(user);
    this.state.role = this.state.user.type || 0;
    if (this.state.role == 2) {
      this.state.depNameOfRole = this.state.user.department || undefined;
    } else {
      this.state.depNameOfRole = undefined;
    }
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  // 删除数据
  manageDelete(index: number, user: IUser) {
    postDelAdminRequest(user.id as number).then((_) => {
      ElMessage.success({
        message: "删除成功！",
        type: "success",
      });
      this.drawer = false;
      this.getListsAdmin();
    });
  }

  submitForm(btnTitle: string) {
    this.isMask = false;
    if (
      !existValidation([
        this.state.user.name,
        this.state.user.password,
        this.state.user.realname,
        this.state.user.mobile,
      ])
    ) {
      ElMessage.error("字段未填写完整");
      return;
    }

    if (!checkPhoneNumber(this.state.user.mobile)) {
      ElMessage.error("电话号码格式错误");
      return;
    }

    if (!this.checkboxGroup || this.checkboxGroup.length === 0) {
      ElMessage.error("请选择账号权限");
      return;
    }

    for (const item of this.state.userNameList) {
      if (item.id != this.state.user.id && item.name === this.state.user.name) {
        ElMessage.error("该用户名已存在");
        return;
      }
    }

    this.state.user.type = this.state.role;

    if (this.state.role == 2) {
      this.state.user.department = this.state.depNameOfRole;
      if (!existValidation([this.state.user.department])) {
        ElMessage.error("字段未填写完整");
        return;
      }
    } else {
      this.state.user.department = this.state.roleOptions.filter(
        (item) => item.value === this.state.role
      )[0].label;
    }

    if (btnTitle === "添加") {
      this.state.user.authority = this.checkboxGroup.join("/");
      postAddAdminRequest(this.state.user).then((_) => {
        ElMessage.success({
          message: "创建成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.user = {};
        this.state.role = 0;
        this.getListsAdmin();
      });
    } else {
      this.state.user.authority = this.checkboxGroup.join("/");
      if (this.password.length > 0) {
        this.state.user.password = this.password;
      }
      this.password = "";
      postEditAdminRequest(this.state.user).then((_) => {
        ElMessage.success({
          message: "修改成功！",
          type: "success",
        });
        this.drawer = false;
        this.state.user = {};
        this.state.role = 0;
        this.getListsAdmin();
      });
    }
  }

  getListsAdmin() {
    postListsAdminRequest(
      this.state.paging.per_page,
      this.state.paging.current_page
    ).then((result) => {
      this.state.paging = result.data;
    });

    postListsAdminRequest(999, 1).then((result) => {
      this.state.userNameList = result.data.data.map((item) => {
        return { name: item.name, id: item.id };
      }) as { name: string; id: number }[];
    });
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsAdmin();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsAdmin();
  }
}
</script>

<style scoped>
::v-deep .el-table th:first-of-type,
::v-deep .el-table th:last-of-type,
::v-deep .el-table td:first-of-type,
::v-deep .el-table td:last-of-type {
  text-align: center !important;
}
.add-box {
  text-align: right;
}
.btn-add {
  width: calc(100 / 14 * 1rem);
  min-height: calc(28 / 14 * 1rem);
  height: calc(36 / 14 * 1rem);
}
.jurisdiction-title {
  font-size: 14px;
  width: 80px;
}
.btn-edit {
  width: 100%;
  margin-top: calc(40 / 14 * 1rem) !important;
}

::v-deep .el-checkbox {
  width: 32% !important;
}

::v-deep .el-checkbox__label {
  font-size: 12px !important;
}

.show-resume {
  text-align: unset!important;;
}

::v-deep .show-resume .el-checkbox__input {
  display: inline-flex!important;
  margin-right: 5px;
}

</style>