
import {
  getStatusResume,
  postAddFeedbackRequest,
  postBoardingResumeRequest,
  postConfirmResumeRequest,
  postDelFeedbackRequest,
  postDelResumeRequest,
  postEditFeedbackRequest,
  postInduction,
  postListsFeedbackRequest,
  postListsResumeRequest,
  postNoInduction,
  postRefuseInduction,
  postShowResumeRequest,
  postStatusResumeRequest,
  postTestResumeRequest,
  getDepartShowPhoto,
} from "../../apis/requests/api";
import {
  IBoardingResume,
  IComeOnData,
  ICompany,
  IDep,
  IFeedback,
  IJob,
  IPaging,
  IQuestionAnswer,
  IResume,
  ITestDetails,
} from "../..//typing";
import { reactive } from "@vue/reactivity";
import { ElMessage } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../../mixins/titleMixin";
import { html2pdf } from "../../share/html2pdf";
import {
  dateFormat,
  existValidation,
  isCardNo,
  isEmail,
} from "../../share/util";
import { getAuthority } from "../../apis/ajax";
import QuestionRecord from "../../components/question-record.component.vue";
import { environment } from "@/environments/environments";

interface QueryParams {
  name?: string;
  jid?: number;
  source?: string;
  status?: string;
  createTime?: Date[];
}

interface IFeedbackList {
  [key: string]: IFeedback[];
}

enum BtnStatus {
  details = "简历详情",
  record = "面试记录",
}

const numberToZhMap = {
  "1": "一",
  "2": "二",
  "3": "三",
  "4": "四",
  "5": "五",
  "6": "六",
};

@Options({
  components: {
    QuestionRecord,
  },
  mixins: [titleMixin],
  computed: {
    buttonScreen() {
      return (status: string) => {
        return this.buttonScreenMap[status];
      };
    },
    durationMin() {
      return (sec: number) => {
        let min: any = Math.floor(sec / 60);
        if (min < 10) {
          min = "0" + min;
        }
        let temp: any = sec % 60;
        if (temp < 10) {
          temp = "0" + temp;
        }
        return `${min}:${temp}`;
      };
    },
    allowSelectCompanyList() {
      return (this.state.boardingResume.company as ICompany[]).filter(
        (item) => item.location === this.state.comeOnData.address
      );
    },
    logicScoreLevel() {
      return (logicScore: number) => {
        let text;
        if (logicScore >= 0 && logicScore <= 4) {
          text = "逻辑不清晰";
        } else if (logicScore >= 5 && logicScore <= 15) {
          text = "逻辑正常";
        } else {
          text = "逻辑优秀";
        }
        return text;
      };
    },
    feedbackListName() {
      const result = [];
      for (const name in this.state.feedbackList) {
        result.push(name);
      }
      return result;
    },
    numberToZh() {
      return (num: number) => {
        let zh = numberToZhMap[num.toString()] || "未知";
        return zh;
      };
    },
  },
})
export default class ResumeList extends Vue {
  BtnStatus = BtnStatus;
  apiUrl = environment.apiUrl;
  isAnswerQuestion = {};
  buttonScreenMap = {
    待筛选: "通过筛选",
    待录用: "通过录用",
    待入职: "确认入职",
  };

  isMask = false;
  isShowPdfButton = true;

  deliveryPeriod!: string | undefined;

  isAddFeedback = false;

  inductionDialogTableVisible = false;
  isReasonForNotOnDuty = false;
  // showYesOrNo!: string;
  showYesOrNo = "";
  state = reactive<{
    queryParams: QueryParams;
    paging: IPaging<IResume>;
    jobs: IJob[];
    details: IResume;
    testDetailsList: ITestDetails[];
    authorityList: string[];
    testDetailsListOrdinary: ITestDetails[];
    testDetailsListLogic: ITestDetails[];
    testDetailsListCharacter: ITestDetails[];
    questionType?: string;
    score?: number;
    total_score?: number;
    boardingResume: IBoardingResume;
    comeOnData: IComeOnData;
    feedback: IFeedback;
    rid?: number; // 面试反馈使用
    listsFeedback: IFeedback[];
    selectedDep?: IDep;
    logicScore?: number;
    resumeStatusList: string[];
    resume?: IResume;
    feedbackList: IFeedbackList;
    preliminaryTest?: IFeedback;
    retest?: IFeedback;
    probationSalary?: number;
    probationDuration?: number;
    normalSalary?: number;
    yearlySalary?: number;
    answersTwoDimensionList: IQuestionAnswer[][];
    inductionDate?: Date;
    currentInductionRow?: any;
    notOnDutyReason?: string;
    questionCount?: number;
    shorthand: { [key: number]: string[] };
    coreReason?: string;
    stateType?: string;
    lastExamine?: string;
    currentDetails?: IResume;
  }>({
    queryParams: {},
    paging: { data: [] },
    jobs: [],
    details: {},
    testDetailsList: [],
    authorityList: [],
    testDetailsListOrdinary: [],
    testDetailsListLogic: [],
    testDetailsListCharacter: [],
    boardingResume: {
      address: [],
      company: [],
      dep: [],
    },
    comeOnData: {
      id: undefined,
      oa_department: undefined,
      oa_job: undefined,
      oa_user_company: undefined,
      oa_address: undefined,
      did: undefined,
      address: undefined,
      idCard: undefined,
      birthStatus: undefined,
      email: undefined,
      nickname: undefined,
    },
    feedback: {},
    listsFeedback: [],
    resumeStatusList: [],
    feedbackList: {},
    answersTwoDimensionList: [],
    shorthand: {},
  });

  created() {
    this.getListsResume();
    this.getStatusResume();
    this.state.authorityList = (getAuthority() as string).split("/");
  }

  initFkb(resume: any) {
    this.state.resume = resume;
    this.state.probationSalary = resume["probation_salary"];
    this.state.probationDuration = resume["probation_period"];
    this.state.normalSalary = resume["official_salary"];
    this.state.yearlySalary = resume["yearly_salary"];
    this.state.coreReason = resume["core_reason"];
    this.state.stateType = resume["state_type"];
    this.state.lastExamine = resume["last_examine"];
    // 部门类别（包含 hr 总经理）
    const depTypes: string[] = Array.from(
      new Set(
        this.state.resume["get_evaluate"].map((item) => item.manager_type)
      )
    );

    for (const name of depTypes) {
      this.state.feedbackList[name] = [];
      this.loadFeedbackClassify(
        name,
        this.state.feedbackList[name],
        this.state.resume["get_evaluate"]
      );
    }

    let zjl = this.state.feedbackList["总经理"];
    for (const key in this.state.feedbackList) {
      if (!["人力资源", "总经理"].includes(key)) {
        const temp = this.state.feedbackList[key].sort(
          (a, b) => a["gradation_status"] - b["gradation_status"]
        );

        this.state.preliminaryTest = temp[0][0]; // 不知为何 proxy 包裹两层
        if (temp.length >= 2) {
          this.state.retest = temp[1][0]; // 不知为何 proxy 包裹两层
        }

        break;
      }
    }

    if (!this.state.retest) {
      if (zjl && zjl.length > 0) {
        this.state.retest = zjl[0][0]; // 不知为何 proxy 包裹两层
      }
    }
  }

  loadFeedbackClassify(
    name: string,
    feedbackList: any[],
    list: any[],
    howManyTimesInterview?: number
  ) {
    howManyTimesInterview = howManyTimesInterview || 1;

    const tempList = list.filter(
      (item) =>
        item.gradation_status === howManyTimesInterview &&
        item.manager_type === name
    );
    if (tempList.length > 0) {
      feedbackList.push(tempList);
    }

    const leftover = list.filter(
      (item) =>
        item.gradation_status !== howManyTimesInterview &&
        item.manager_type === name
    );
    if (leftover.length > 0) {
      this.loadFeedbackClassify(
        name,
        feedbackList,
        leftover,
        ++howManyTimesInterview
      );
    }
  }

  getStatusResume() {
    getStatusResume().then((result) => {
      this.state.resumeStatusList = Object.keys(result.data).reverse();
    });
  }

  getListsResume() {
    postListsResumeRequest(
      this.state.paging.per_page,
      this.state.paging.current_page,
      this.state.queryParams.name,
      this.state.queryParams.jid,
      this.state.queryParams.source,
      this.state.queryParams.status,
      this.deliveryPeriod
    ).then((result) => {
      this.state.jobs = result.jobs;
      this.state.paging = result.data;
      this.state.paging.data.forEach((item) => {
        item.background_check = JSON.parse(item.background_check as string);
        item.job_experience = JSON.parse(item.job_experience as string);
      });

      this.state.paging.data.forEach((item) => {
        if ("亲友介绍" === item.offline) {
          item.source = "亲友介绍";
        }
      });
    });
  }

  manage = [
    {
      prop: "id",
      label: "序号",
    },
    {
      prop: "name",
      label: "姓名",
    },
    {
      prop: "get_job.job_name",
      label: "岗位",
    },
    {
      prop: "create_time",
      label: "时间",
    },
    // {
    //   prop: "reason",
    //   label: "投递原因",
    // },
    // {
    //   prop: "background_check.whether",
    //   label: "背调",
    // },
    {
      prop: "source",
      label: "来源",
    },
    {
      prop: "job_experience[0].time",
      label: "工作经历",
    },
    {
      prop: "phone",
      label: "联系方式",
    },
    {
      prop: "birthday",
      label: "出生日期",
    },
    {
      prop: "get_status.manage_end",
      label: "状态",
    },
    {
      prop: "induction_time",
      label: "入职时间",
    },
  ];

  tableData = [
    {
      serialNumber: "1",
      name: "张三",
      post: "销售",
      time: "2021-03-25",
      deliveryReason: "职位匹配",
      backTone: "接受",
      source: "BOSS",
      workExperience: "有工作经历",
      contactInf: "18798765436",
      birthday: "1998-12-31",
      testScore: "65",
    },
  ];

  labelPosition = "left";

  resumeDrawer = false;
  detailDrawer = false;
  testDrawer = false;

  corporateCultureDrawer = false;

  comeOnDrawer = false;

  interviewFeedbackDrawer = false;

  resumeDetails(type: string, id: number) {
    if (type === "简历详情") {
      this.resumeDrawer = true;
    } else {
      this.detailDrawer = true;
      getDepartShowPhoto(id).then((result) => {
        this.state.shorthand = result.data.reduce((pre, cur) => {
          if (!pre[cur.record_id]) {
            pre[cur.record_id] = [];
          }
          pre[cur.record_id].push(this.apiUrl + "/" + cur.photo);
          return pre;
        }, {});
      });
    }
    this.isMask = true;
    postShowResumeRequest(id).then((result) => {
      this.state.details = result.data.data;
      this.state.details.background_check = JSON.parse(
        this.state.details.background_check as string
      );
      this.state.details.job_experience = JSON.parse(
        this.state.details.job_experience as string
      );
      this.state.details.edu_experience = JSON.parse(
        this.state.details.edu_experience as string
      );
      this.state.details.family_member = JSON.parse(
        this.state.details.family_member as string
      );
      this.state.details.create_time = dateFormat(
        this.state.details.create_time as string
      );
      this.state.details.propaganda = JSON.parse(
        this.state.details.propaganda as any
      );

      this.initFkb(this.state.details);

      this.state.answersTwoDimensionList = [];
      this.loadAnswersTwoDimensionList(result.data.answers_list);
    });

    postListsFeedbackRequest(id).then((result) => {
      this.state.listsFeedback = result.data;
    });

    this.getTestResumeRequest(id);
  }

  /**
   * 装载问答记录二位数组（以第几次面试区分）
   */
  loadAnswersTwoDimensionList(list: any[], howManyTimesInterview?: number) {
    howManyTimesInterview = howManyTimesInterview || 1;

    const tempList = list.filter(
      (item) => item.record_id === howManyTimesInterview
    );
    if (tempList.length > 0) {
      this.state.answersTwoDimensionList.push(tempList);
    }

    const leftover = list.filter(
      (item) => item.record_id !== howManyTimesInterview
    );
    if (leftover.length > 0) {
      this.loadAnswersTwoDimensionList(leftover, ++howManyTimesInterview);
    }
  }

  testDetailsName = "";
  testDetails(id: number, name: string, item: any) {
    this.state.currentDetails = item;
    this.isAnswerQuestion = item.expansion.find((item) => {
      if (item.pivot.answer) return item;
    });

    this.testDrawer = true;
    this.testDetailsName = name;

    this.getTestResumeRequest(id);
  }

  getTestResumeRequest(id: number) {
    postTestResumeRequest(id).then((result) => {
      this.state.questionCount = result.data.data.filter(
        (item) => item.cid
      ).length;
      this.state.questionType = result.data.question_type;
      this.state.score = result.data.score;
      this.state.total_score = result.data.total_score;
      this.state.testDetailsList = result.data.data;

      const tempTestDetailsListOrdinary = [];
      const tempTestDetailsListLogic = [];
      const tempTestDetailsListCharacter = [];
      for (const item of this.state.testDetailsList) {
        if (item.eid) {
          tempTestDetailsListOrdinary.push(item);
        } else if (item.sid && item.get_special) {
          if (item.get_special.cat === "性格题") {
            tempTestDetailsListCharacter.push(item);
          } else if (item.get_special.cat === "逻辑题") {
            tempTestDetailsListLogic.push(item);
          }
        }
      }
      this.state.testDetailsListOrdinary = tempTestDetailsListOrdinary;
      this.state.testDetailsListLogic = tempTestDetailsListLogic;
      this.state.testDetailsListLogic.forEach((item) => {
        if (item.get_special) {
          item.get_special.content = JSON.parse(
            item.get_special.content as any
          );
        }
      });
      this.state.testDetailsListCharacter = tempTestDetailsListCharacter;
      this.state.testDetailsListCharacter.forEach((item) => {
        if (item.get_special) {
          item.get_special.content = JSON.parse(
            item.get_special.content as any
          );
        }
      });

      let logicScore = 0;
      this.state.testDetailsListLogic.forEach((item) => {
        logicScore += +(item.get_special?.content?.filter(
          (i) => i.optionName === item.answer
        )[0].score as number);
      });
      this.state.logicScore = logicScore;
    });
  }

  corporateCulture(id: number) {
    this.isMask = true;
    this.corporateCultureDrawer = true;
    postShowResumeRequest(id).then((result) => {
      this.state.details = result.data.data;
      this.state.details.background_check = JSON.parse(
        this.state.details.background_check as string
      );
      this.state.details.job_experience = JSON.parse(
        this.state.details.job_experience as string
      );
      this.state.details.edu_experience = JSON.parse(
        this.state.details.edu_experience as string
      );
      this.state.details.family_member = JSON.parse(
        this.state.details.family_member as string
      );
      this.state.details.create_time = dateFormat(
        this.state.details.create_time as string
      );
      this.state.details.propaganda = JSON.parse(
        this.state.details.propaganda as any
      );
    });
  }

  savePdf() {
    this.isShowPdfButton = false;
    html2pdf("jlxq", this.state.details.name + "-简历").then((_) => {
      this.isShowPdfButton = true;
    });
  }

  savePdfTest() {
    this.isShowPdfButton = false;
    html2pdf("csxq", this.testDetailsName + "-测试详情").then((_) => {
      this.isShowPdfButton = true;
    });
  }

  resumeDelete(index: number, row: any) {
    postDelResumeRequest(row.id).then((_) => {
      ElMessage.success({
        message: "删除成功！",
        type: "success",
      });
      this.getListsResume();
    });
  }

  induction(index: number, row: any) {
    this.comeOn(row.id);
  }

  noInduction() {
    postNoInduction(
      this.state.currentInductionRow.id,
      this.state.notOnDutyReason
    ).then((result) => {
      if (result.code === 200) {
        ElMessage.success({
          message: result.msg,
          type: "success",
        });
        this.isReasonForNotOnDuty = false;
        this.state.notOnDutyReason = "";
        this.showYesOrNo = "";
        this.getListsResume();
      } else {
        ElMessage.error(result.msg);
      }
    });
  }

  // noInduction(index: number, row: any) {
  //   postNoInduction(row.id).then((result) => {
  //     if (result.code == 200) {
  //       ElMessage.success({
  //         message: "操作成功！",
  //         type: "success",
  //       });
  //       this.getListsResume();
  //     }
  //   });
  // }

  formatter(row: IResume, column: any) {
    switch (column.property) {
      case "create_time":
        return dateFormat(row.create_time as string);

      case "background_check.whether":
        return (row.background_check as any).whether == 1 ? "接受" : "不接受";

      case "job_experience[0].time":
        return row.job_experience
          ? (row.job_experience[0] as any).time.length > 0
            ? "有工作经历"
            : "实习生"
          : "实习生";

      default:
        return (row as any)[column.property];
    }
  }

  search() {
    if (
      this.state.queryParams.createTime &&
      (this.state.queryParams.createTime as any).length > 0
    ) {
      this.deliveryPeriod =
        dateFormat((this.state.queryParams.createTime as any)[0]) +
        " " +
        dateFormat((this.state.queryParams.createTime as any)[1]);
    }

    this.state.paging.current_page = 1;
    this.getListsResume();
  }

  reset() {
    this.state.queryParams.name = undefined;
    this.state.queryParams.jid = undefined;
    this.state.queryParams.source = undefined;
    this.state.queryParams.createTime = undefined;
    this.deliveryPeriod = undefined;
    this.state.queryParams.status = undefined;
    this.state.paging.current_page = 1;
    this.getListsResume();
  }

  handleSizeChange(val: number) {
    this.state.paging.per_page = val;
    this.getListsResume();
  }

  handleCurrentChange(val: number) {
    this.state.paging.current_page = val;
    this.getListsResume();
  }

  handleClose(done: any) {
    this.isMask = false;
    done();
  }

  datePickerChange(event: any) {
    if (!event) {
      this.deliveryPeriod = undefined;
    }
  }

  closeDrawer() {
    this.state.feedbackList = {};
    this.state.preliminaryTest = {};
    this.state.retest = null;
  }

  operate(id: number, status: string) {
    let next;
    switch (status) {
      case "待筛选":
        next = "待录用";
        break;
      case "待录用":
        next = "待入职";
        break;
      case "待入职":
        this.comeOn(id);
        return;
      default:
        next = "已入职";
        break;
    }

    postStatusResumeRequest(id, next).then((result) => {
      ElMessage.success({
        message: result.msg,
        type: "success",
      });
      this.getListsResume();
    });
  }

  failed(id: number, value: string) {
    postStatusResumeRequest(id, value).then((result) => {
      ElMessage.success({
        message: result.msg,
        type: "success",
      });
      this.getListsResume();
    });
  }

  comeOn(id: number) {
    this.isMask = true;
    this.comeOnDrawer = true;
    this.state.comeOnData = {
      id: undefined,
      oa_department: undefined,
      oa_job: undefined,
      oa_user_company: undefined,
      oa_address: undefined,
      did: undefined,
      address: undefined,
      idCard: undefined,
      birthStatus: undefined,
      email: undefined,
      nickname: undefined,
    };

    this.state.comeOnData.id = id;

    postBoardingResumeRequest().then((result) => {
      this.state.boardingResume = result.data;
    });
  }

  interviewFeedback(id: number) {
    this.isMask = true;
    this.isAddFeedback = false;
    this.interviewFeedbackDrawer = true;
    this.state.rid = id;

    postListsFeedbackRequest(id).then((result) => {
      this.state.listsFeedback = result.data;
    });
  }

  comeOnDataOk() {
    const valiList = [];
    for (const key in this.state.comeOnData) {
      if (key === "oa_dep_group") {
        continue;
      }
      valiList.push((this.state.comeOnData as any)[key]);
    }

    if (!existValidation(valiList)) {
      alert("信息填写不完整。");
      return;
    }

    if (
      !isCardNo(this.state.comeOnData.idCard as string) ||
      !isEmail(this.state.comeOnData.email as string)
    ) {
      return;
    }
    postConfirmResumeRequest(this.state.comeOnData, 5).then((result) => {
      if (result.code === 200) {
        ElMessage.success({
          message: result.msg,
          type: "success",
        });
        postStatusResumeRequest(
          this.state.comeOnData.id as number,
          "已入职"
        ).then((result) => {
          ElMessage.success({
            message: result.msg,
            type: "success",
          });
          this.comeOnDrawer = false;
          this.getListsResume();
        });
      } else {
        ElMessage.error(result.msg);
      }
    });
  }

  oaAddressChange(id: number) {
    this.state.comeOnData.oa_user_company = undefined;
    const temp = this.state.boardingResume.address.filter(
      (item) => item.id === id
    );
    this.state.comeOnData.address = temp[0].keyword;
  }

  submitFeedback() {
    if (
      !existValidation([
        this.state.feedback.name,
        this.state.feedback.create_time,
        this.state.feedback.merit,
        this.state.feedback.shortcoming,
      ])
    ) {
      ElMessage.error("必填项不能为空！");
      return;
    }

    if ((this.state.feedback.merit?.trim().length as number) > 10) {
      ElMessage.error("优点描述不能超过 10 个字");
      return;
    }

    if ((this.state.feedback.shortcoming?.trim().length as number) > 10) {
      ElMessage.error("不足描述不能超过 10 个字");
      return;
    }

    this.state.feedback.create_time = dateFormat(
      this.state.feedback.create_time as string
    );
    this.state.feedback.rid = this.state.rid;

    if (this.state.feedback.id) {
      // 编辑
      postEditFeedbackRequest(this.state.feedback).then((result) => {
        if (result.code === 200) {
          ElMessage.success({
            message: result.msg,
            type: "success",
          });
          this.interviewFeedbackDrawer = false;
        } else {
          ElMessage.error(result.msg);
        }
      });
    } else {
      // 新增
      postAddFeedbackRequest(this.state.feedback).then((result) => {
        if (result.code === 200) {
          ElMessage.success({
            message: result.msg,
            type: "success",
          });
          this.interviewFeedbackDrawer = false;
        } else {
          ElMessage.error(result.msg);
        }
      });
    }
  }

  addFeedback() {
    this.isAddFeedback = true;
    this.state.feedback = {};
  }

  listsFeedbackDelete(id: number) {
    postDelFeedbackRequest(id).then((result) => {
      if (result.code === 200) {
        ElMessage.success({
          message: result.msg,
          type: "success",
        });

        postListsFeedbackRequest(this.state.rid as number).then((result) => {
          this.state.listsFeedback = result.data;
        });
      } else {
        ElMessage.error(result.msg);
      }
    });
  }

  editFeedback(feedback: IFeedback) {
    this.isAddFeedback = true;
    this.state.feedback = feedback;
  }

  departmentSelectChange(id: number) {
    this.state.comeOnData.oa_dep_group = undefined;
    this.state.comeOnData.oa_job = undefined;
    this.state.selectedDep = this.state.boardingResume.dep.filter(
      (item) => item.id === id
    )[0];
  }

  confirmInductionDate() {
    if (!this.state.inductionDate) {
      alert("入职时间不能为空。");
      return;
    }

    postInduction(
      this.state.currentInductionRow.id,
      dateFormat(this.state.inductionDate)
    ).then((result) => {
      if (result.code === 200) {
        ElMessage.success({
          message: result.msg,
          type: "success",
        });

        this.inductionDialogTableVisible = false;

        this.getListsResume();
      } else {
        ElMessage.error(result.msg);
      }
    });
  }

  confirmReasonText() {
    postRefuseInduction(
      this.state.currentInductionRow.id,
      this.state.notOnDutyReason
    ).then((result) => {
      if (result.code === 200) {
        ElMessage.success({
          message: result.msg,
          type: "success",
        });
        this.isReasonForNotOnDuty = false;
        this.state.notOnDutyReason = "";
        this.showYesOrNo = "";
        this.getListsResume();
      } else {
        ElMessage.error(result.msg);
      }
    });
  }

  openSchoolWebsite(website) {
    window.open(`https://www.baidu.com/s?wd=${website}`);
  }

  // refuseInduction(row: any) {
  //   postRefuseInduction(row.id).then((result) => {
  //     if (result.code === 200) {
  //       ElMessage.success({
  //         message: result.msg,
  //         type: "success",
  //       });

  //       this.getListsResume();
  //     } else {
  //       ElMessage.error(result.msg);
  //     }
  //   });
  // }
}
